import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-ott',
  templateUrl: './ott.component.html',
  styleUrls: ['./ott.component.scss']
})
export class OttComponent implements OnInit, OnDestroy {

  @Input() ottData: any;
  @Input() ottBenchmarking: any;
  @Input() overallData: any;
  @Input() dateRange: any;
  @Input() purchaseLog: any;

  charts: any = ['impressions', 'clicks', 'ctr'];

  hidePageBucket: any;
  hideBubbleBucket: any;
  hideRowBucket: any;

  //showOTTPersonalizationSection: boolean = true;
  ottPersonalizationBucket: any = [];

  personalizationImpressionsPreEdit: number = 0;
  personalizationClicksPreEdit: number = 0;
  lastIndex: number;
  overAllPerformanceVerbiage: any = {verbiage: ''};

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) {
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
    this.hideRowBucket = this.reportPreviewService.hideRowBucket;

    this._subscriptions.add(editItemService.editOttLeadsImpressionsEventHook$.subscribe(
      (info) => {
        this.handleEditOttLeadsImpressions(info);
      })
    );
  }

  ngOnInit() {
    if (this.ottData.total_impressions == 0) this.getIdsAndHidePages();

    this.overAllPerformanceVerbiage.verbiage = "OVERALL PERFORMANCE" + (this.ottData.topics.length > 1 ? (" IN " + this.ottData.topics.length + " CAMPAIGNS") : "");
  }

  round( number ) {
    return Math.round( number );
  }

  /**
   * Increments total click and total impression counts. 
   * Hides or unhides pages as a result of impression count change
   * @param any info
   * @return void
   */
  handleEditOttLeadsImpressions(info) {

    const personalizationImpressionsDiff = info.personalization_impressions - this.personalizationImpressionsPreEdit;

    const personalizationClicksDiff = info.personalization_clicks - this.personalizationClicksPreEdit;

    this.ottData.total_impressions += personalizationImpressionsDiff;
    this.ottData.total_clicks += personalizationClicksDiff;

    this.overallData.total_impressions += personalizationImpressionsDiff;
    this.overallData.total_clicks += personalizationClicksDiff;

    this.purchaseLog.own_the_topic.total_impressions += personalizationImpressionsDiff; 
    this.purchaseLog.own_the_topic.total_clicks += personalizationClicksDiff; 

    // After user changes OTT impressions, if total impressions is now positive, unhide everything and let the html decide what needs to be hidden at that point
    if (this.ottData.total_impressions > 0) {
      this.reportPreviewService.unHidePagesAndBubbles("ott-");
    } else {
      this.getIdsAndHidePages();
    }

    // Unhide report summary overall page if there are now impressions
    if (this.overallData.total_impressions > 0) {
      delete this.reportPreviewService.hidePageBucket['summary-overall'];
    // Else hide it
    } else {
      this.reportPreviewService.hidePageBucket['summary-overall'] = true;
    }

  }

  /**
   * Hides all ott pages
   * @return void
   */
  getIdsAndHidePages() {
    this.reportPreviewService.getIdsAndHidePages('ott-');
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  changeOTTPersonalizationSectionViewStatus(index: number ) {
    if ( this.ottPersonalizationBucket[index] == undefined ) {
      this.ottPersonalizationBucket[index] = true;
    }

    if ( this.ottPersonalizationBucket[index] ) {
      this.ottPersonalizationBucket[index] = false;
    } else if ( ! this.ottPersonalizationBucket[index] ) {
      this.ottPersonalizationBucket[index] = true;
    }
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  changeRowViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeRowViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @param index Number
   * @return void
   */
  editItem(item, type, key, index?) {

    if (key === 'ottClicksImpressions') {
      // Keep track of personalization impressions and clicks pre-editing
      this.personalizationImpressionsPreEdit = item.editable_object.custom_fields.personalization_impressions || 0;

      this.personalizationClicksPreEdit = item.editable_object.custom_fields.personalization_clicks || 0;
      // Keep track of which topic was edited
      this.lastIndex = index;

    }

    this.editItemService.editItem(item, type, key);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

  /**
   * Unsubscribes to subscriptions
   */
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
