import { Component, Input, OnInit } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  @Input() newsletterAdData: any;
  @Input() newsletterAdBenchmarking: any;
  @Input() monthLabels: any;
  @Input() dateRange: any;
  @Input() makeImage: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;
  hideRowBucket: any;

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
    this.hideRowBucket = this.reportPreviewService.hideRowBucket;
  }

  ngOnInit() {
    this.checkHideAllPages();
  }

  round( number ) {
    return Math.round( number );
  }

  /**
   * Hide all pages in this section if the below metrics are zero
   * @return void
   */
  checkHideAllPages() {
    if (this.newsletterAdData.total_sent == 0 || 
      this.newsletterAdData.total_impressions == 0) {
        this.reportPreviewService.getIdsAndHidePages('newsletter-');
    }
  }

  /**
   * Hide all pages in this section if the below metrics are zero
   * @param stype string
   * @param id string
   * @param field Number
   * @return boolean (always returns true)
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  changeRowViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeRowViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    this.editItemService.editItem(item, type, key);
  }

  /**
   * Adds two decimal spots to the number, but removes them if ends
   * in .00
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

}
