// src/app/services/cfe/cfe.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class CFEService {
  constructor(private api: ApiService) {}

  

  // public createSalesRep(payload): Observable<any> {
  //   return this.api.post<Response>("/api/salesreps", payload);
  // }

  // public deleteAdmin(id): Observable<any> {
  //   return this.api.delete<Response>("/api/users/" + id);
  // }
  
  // public deleteSalesRep(id): Observable<any> {
  //   return this.api.delete<Response>("/api/salesreps/" + id);
  // }
  
  // public updateAdmin(payload): Observable<any> {
  //   return this.api.put<Response>("/api/users/" + payload.id, payload);
  // }


  public getProducts(): Observable<any> {
    return this.api.get<Response>("/api/products");
  }

  public getBrands(): Observable<any> {
    return this.api.get<Response>("/api/brands");
  }

  public createBrand(payload): Observable<any> {
    return this.api.post<Response>("/api/brands", payload, true);
  }

  public updateBrand(id, payload, logoChanged): Observable<any> {
    return this.api.post<Response>("/api/brands/" + id, payload, logoChanged);
  }

  public deleteBrand(id): Observable<any> {
    return this.api.delete<Response>("/api/brands/" + id);
  }

}