import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  @Input() activeLi: string;
  @Input() purchaseLog: any;

  constructor() {}

  /**
   * Scrolls to given html element
   */
  /* istanbul ignore next */
  goTo(id: string) {
    const element = document.getElementById(id);
    if (!element) return;
    const y = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

}
