/**
 * Model for Advertiser
 */
export class Advertiser {

  constructor(
      id: number,
      name: string,
      logo: string,
      deleted: boolean,
      advertiserDivisions: [],
      form?: any
  ) {
      this.id = id;
      this.name = name;
      this.logo = logo;
      this.deleted = deleted;
      this.advertiserDivisions = advertiserDivisions;
      if (form) this.form = form;
  }

  id: number = null;
  name: string = '';
  logo: string = '';
  deleted: boolean;
  advertiserDivisions: [];
  form: any = '';
}