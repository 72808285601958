// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "http://roi.test/",
  baseAuth: "NewRoi"
};

// export const environment = {
//   production: false,
//   baseUrl: "http://localhost:8080",
//   csxBaseUrl: "http://localhost",
//   cloadfrontBaseUrl: "//d3mm496e6885mw.cloudfront.net/",
//   baseAuth: "Basic YXV0aGFwcDpteVNlY3JldE9BdXRoU2VjcmV0",
//   noImageUploaded: "//d3mm496e6885mw.cloudfront.net/NP4E_2019/Images/icons/no-image.svg",
//   captchaKey: "6LfkjDUUAAAAABelmCp7OqtDm7TpYToMkfzYG_3v",
//   reviveId: "5d3cba222eaaeee40162c5d04d22e1b8",
//   // AD NAMES FOR TEMPLATES
//   ads: {
//     leaderboard: "728x90",
//     boombox: "300x250",
//     banner: "300x50"
//   },
//   googleAnalyticsAccount: "UA-XXXX-Y"
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
