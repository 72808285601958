import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BannerService } from 'src/app/services/banner/banner.service';
import { Banner } from 'src/app/models/banner.model';
import { CorrectionLogComponent } from 'src/app/pages/banner-update/components/correction-log/correction-log.component';

@Component({
  selector: 'app-banner-update-main',
  templateUrl: './banner-update-main.component.html',
  styleUrls: ['./banner-update-main.component.scss']
})
export class BannerUpdateMainComponent implements OnInit {

  private _subscriptions = new Subscription();
  error: string = '';
  loading: boolean = false;
  inputError: boolean = false;
  userId: any;
  banner: Banner;

  constructor( private router: Router, private builder: FormBuilder, private authService: AuthService, private bannerService: BannerService ) { 
    this.authService.getUserId().subscribe(response => {
      this.userId = response.data;
    });
  }

  ngOnInit() {
    this.createNew();
  }
  /**
   * Unsubscribes to subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
  /**
   * Creates new empty banner
   * @return void
   */
  createNew() {
    const newBanner = new Banner( '' );
    newBanner.form = this.buildBannerForm(newBanner);

    this.banner = newBanner;
  }
  /**
   * Creates banner based on user input
   * @param Banner 
   * @return void
   */
  save(banner: Banner) {
    this.loading = true;
    this.resetErrors();

    const bannerId = banner.form.get("bannerId").value;
    const type = banner.form.get("type").value;

    let payload = 'email' === type ? {
      trackId: bannerId,
      userId: '' === this.authService.username ? null : this.authService.username
    } : {
      bannerId: bannerId,
      type: type,
      userId: '' === this.authService.username ? null : this.authService.username
    };

    this._subscriptions.add(
      'email' === type ? this.bannerService.updateEmailInfo(payload).subscribe(response => {
        this.loading = false;
        this.bannerService.success.emit();
      }) : this.bannerService.updateBannerInfo(payload).subscribe(response => {
        this.loading = false;
        this.bannerService.success.emit();
      })
    );
  }
  /**
   * If should show Save button
   * @return boolean
   */
  showSaveButton() {
    console.log( this.banner.form.get("type").value );
    if (this.banner.form.status === 'INVALID') return false;
    console.log( this.banner.form.get("type").value );
    if (! this.banner.form.get("type").value) return false;

    if (! this.banner.form.get("bannerId").value) return false;

    return true;
  }
  /**
   * Resets errors
   * @return void
   */
  resetErrors() {
    this.inputError = false;
    this.error = '';
  }
  /**
   * Navigates home
   * @return void
   */
  goHome() {
    this.router.navigate(["/home"]);
  }
  /**
   * Creates banner form
   * @param Banner
   * @return FormBuilder
   */
  buildBannerForm(banner: Banner) {
    const form = {
      type: [
        '',
        [
          Validators.required
        ]
      ],
      bannerId: [
        banner.bannerId,
          [
            Validators.required
          ]
        ]

    };

    return this.builder.group(form);

  }

}
