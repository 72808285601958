import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {

  @Input() overallData: any;
  @Input() purchaseLog: any;
  @Input() dateRange: any;
  @Input() general: any;

  hidePageBucket: any;
  hideBubbleBucket: any;
  hideMarketingBucket: any;
  ctr: number;
  leadsView: string;

  private _subscriptions = new Subscription();

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
    this.hideMarketingBucket = this.reportPreviewService.hideMarketingBucket;
    this.leadsView = this.reportPreviewService.leadsView;

    // Listens to report preview service if should update leadsView
    this._subscriptions.add(this.reportPreviewService.leadsViewHook$.subscribe(
      view => this.leadsView = view
    ));
  }

  /**
   * Calls setCtr
   * @return void
   */
  ngOnInit() {
    this.setCtr();
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  /**
   * 1st click: turn Leads bubble into CTR bubble.
   * 2nd click: gray-out CTR bubble.
   * 3rd click: back to Leads bubble and remove gray-out
   * @param MouseEvent event
   * @return void
   */
  changeLeadsView(event: MouseEvent) {
    this.reportPreviewService.changeLeadsView(event.srcElement);
  }

  /**
   * Calculates and sets CTR (click thru rate)
   */
  setCtr() {

    // Don't want to divide a number by zero, so exit out if this is the case
    if (this.overallData.total_impressions == 0) {
      this.ctr = 0;
      return;
    }

    this.ctr = this.formatPercent((this.overallData.total_clicks / this.overallData.total_impressions) * 100);
  }

  isSectionPurchased( section ) {
    switch ( section ) {
      case 'prod-ebooks': {
        return this.purchaseLog.ebooks.purchased;
      }
      case 'prod-newsletter_ads': {
        return this.purchaseLog.newsletter_ads.purchased;
      }
      case 'prod-webcasts': {
        return this.purchaseLog.webcasts.purchased;
      }
      case 'prod-print_ads': {
        return this.purchaseLog.print_ads.purchased;
      }
      case 'prod-web_ads': {
        return this.purchaseLog.web_ads.purchased;
      }
      case 'prod-custom_eblasts': {
        return this.purchaseLog.custom_eblasts.purchased;
      }
      case 'prod-own_the_topic':
      default: {
        return this.purchaseLog.own_the_topic.purchased;
      }
    }
  }

  /**
   * Flips marketing bucket to alternate "We haven't connected?" message
   * @param string section
   * @return void
   */
  updateHideMarketingBucket(section) {
    this.reportPreviewService.updateHideMarketingBucket(section, this.isSectionPurchased( section ) );
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    this.editItemService.editItem(item, type, key);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

  /**
   * Remove subscriptions
   */
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
