// src/app/services/crm-company/crm-company.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class CrmCompanyService {
  constructor(private api: ApiService) {}

  public getAvailableCrmCompanies(): Observable<any> {
    return this.api.get<Response>("/api/crm-company/all-available");
  }
  
}