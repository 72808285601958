import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public gotoUsers() {
  	this.router.navigate(["/users"]);
  }

  public gotoCFE() {
  	this.router.navigate(["/cfe"]);
  }

  public gotoAdvertisers() {
    this.router.navigate(["/advertisers"]);
  }

  public gotoAdvertiserDivisions() {
  	this.router.navigate(["/advertiser-divisions"]);
  }

  public gotoReports() {
  	this.router.navigate(["/reports"]);
  }


}
