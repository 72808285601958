import { Component, Input, OnInit } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {

  @Input() webAdGeneral: any;
  @Input() webAdBenchmarking: any;
  @Input() webAdData: any;
  @Input() monthLabels: any;
  @Input() dateRange: any;
  @Input() makeImage: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) {
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
  }

  ngOnInit() {
    this.checkHideAllPages();
  }

  round( number ) {
    return Math.round( number );
  }

  /**
   * If should gray-out all website pages on page load
   * @return void
   */
  checkHideAllPages() {
    if (this.webAdGeneral.total_impressions == 0) {
      this.reportPreviewService.getIdsAndHidePages('website-');
    }
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    this.editItemService.editItem(item, type, key);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

}
