import { Component, OnInit, Input } from '@angular/core';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;

  constructor(private reportPreviewService: ReportPreviewService) { 
  	this.hidePageBucket = this.reportPreviewService.hidePageBucket;
  	this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
  }

  ngOnInit() {
  }

}
