/**
 * Model for Banner
 */
export class Banner {

  constructor(
      bannerId: string,
      form?: any
  ) {
      this.bannerId = bannerId;
      if (form) this.form = form;
  }

  bannerId: string = '';
  form: any = '';
}