import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-ebook',
  templateUrl: './ebook.component.html',
  styleUrls: ['./ebook.component.scss']
})
export class EbookComponent implements OnInit, OnDestroy {

  @Input() ebookData: any;
  @Input() ebookBenchmarking: any;
  @Input() dateRange: any;
  @Input() overallData: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;
  hideRowBucket: any;

  overAllPerformanceVerbiage: any = {verbiage: ''};

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
    this.hideRowBucket = this.reportPreviewService.hideRowBucket;

    this._subscriptions.add(editItemService.ebookPromoItemEventHook$.subscribe(
      (promoInfo) => {
        this.handlePromoItemImpressionsDiff(promoInfo);
      })
    );
  }

  ngOnInit() {
    if (this.ebookData.total_impressions == 0 && 
      this.ebookData.total_leads == 0) {
      this.reportPreviewService.getIdsAndHidePages('ebook-');
    }

    this.overAllPerformanceVerbiage.verbiage = "OVERALL PERFORMANCE" + (this.ebookData.ebooks.length > 1 ? (" IN " + this.ebookData.ebooks.length + " EBOOKS") : "");
  }

  round( number ) {
    return Math.round( number );
  }

  /**
   * Handles impression diff incrementation and calls handleHideUnhide()
   * @param promoInfo
   * @return void
   */
  handlePromoItemImpressionsDiff(promoInfo) {

    const impressionsDiff = promoInfo.afterEditCircNumbers - promoInfo.preEditCircNumbers;

    this.overallData.total_impressions += impressionsDiff;
    this.purchaseLog.ebooks.total_impressions += impressionsDiff;
    this.ebookData.total_print_impressions += impressionsDiff;
    this.ebookData.total_impressions += impressionsDiff;
    this.ebookData.ebooks[promoInfo.index].print_impressions += impressionsDiff;
    this.ebookData.ebooks[promoInfo.index].impressions += impressionsDiff;

    this.reportPreviewService.handleHideUnhide("ebook-", this.overallData.total_impressions, this.ebookData.total_impressions);
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  changeRowViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeRowViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    this.editItemService.editItem(item, type, key);
  }

  /**
   * Creates new promo item object and calls Edit Item service editItem()
   * @param any[] itemArray
   * @param string type
   * @param number objectId
   * @param number index
   * @return void
   */
  addPromoItem(itemArray: any[], type: string, objectId: number, index: number) {
    let promoItem = this.reportPreviewService.createNewPromoObject(type, objectId);

    this.editItemService.editItem(promoItem, type, 'promo', itemArray, false, index);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
