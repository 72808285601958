import { Component, Input, OnChanges, SimpleChanges, AfterContentInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, Chart } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {

  @Input() chart: any;
  @Input() key: string;
  @Input() monthLabels: any;
  @Input() id: string;
  @Input() makeImage: boolean;
  @Input() doubleChart?: boolean;

  chartPlugins: any = [pluginDataLabels];
  image: any;
  barPerMonthLimit: number = 6;
  hideLabels: boolean = false;

  constructor() {}

  /**
   * Calls setUpChart()
   * We need ngAfterContentInit and the setTimeout to 
   * ensure that the fonts load properly
   */
  ngAfterContentInit() {
    const that = this;
    setTimeout(function(){
      that.setUpChart();
    }, 0); 
  }

  /**
   * Master chart creation function
   * @return void
   */
  setUpChart() {

    const that = this;
    let chartType;
    let lineToBarChart = false;
    let outOfRange = false;
    let barPerMonthTotals = {};

    // "Bar" chart candidates
    if (this.key.startsWith('impressions_') || this.key === 'printImpressions' || this.key === 'websiteImpressions' || this.key === 'newsletterOpens' || this.key === 'newsletterSent' || this.key === 'newsletterClicks') {

      chartType = 'bar';
    }

    // "Horizontal Bar" chart candidates
    if (this.key.startsWith('clicks_') || this.key === 'newsletterImpressions' || this.key === 'eblastImpressions') {

      chartType = 'horizontalBar';
    }

    // "Line" chart candidates
    if (this.key.startsWith('ctr_') || this.key === 'newsletterOpenRate' || this.key === 'newsletterCtr' || this.key === 'websiteClicks') { 

      chartType = 'line';
    }

    // Create a key in barPerMonthTotals for every month
    // (plus one, to account for any spillOver), 
    // with a starting value of 0
    if (that.key !== 'printImpressions') {
      for (let i = 0, len = this.monthLabels.length + 1; i < len; i++) {
        barPerMonthTotals[i] = 0;
      }
    }

    this.chart.data.forEach(function (element) {

      if (that.key !== 'printImpressions') {
        element.data.forEach(function (point, index) {
          // If the data point is not empty, 
          // increment the barPerMonthTotals for that month
          // so we know whether or not to show labels (we
          // do not show labels if there are more bars in a
          // month than our barPerMonthLimit)
          if (point !== 0 && point.constructor !== Array) {
            barPerMonthTotals[index]++;
          }
        });
      }

      // If the data is longer than the number of months,
      // we have Out of Range (spillOver) data
      if (!outOfRange && (element.data.length > that.monthLabels.length)) {
        outOfRange = true;
      }

      if (chartType === 'line') {
        element.borderColor = element.backgroundColor;
      }

      if (that.key === 'printImpressions') {
        element.minBarLength = 35;
      }
    });

    // Do not show labels if any month's total bars are more
    // than the barPerMonthLimit
    if (that.key !== 'printImpressions') {
      for (let key in barPerMonthTotals) {
        if (barPerMonthTotals.hasOwnProperty(key)) {
          if (barPerMonthTotals[key] > this.barPerMonthLimit) {
            this.hideLabels = true;
          }
        }
      }
    }

    // Clone monthLabels (otherwise any change to this 
    // monthLables would carry through to all monthLabels)
    let newMonthLabels = JSON.parse(JSON.stringify(this.monthLabels));

    if (outOfRange) newMonthLabels.push('OUT OF RANGE');

    let chartLabels = newMonthLabels;
    let singleMonth = chartLabels.length === 1;

    // Single month "line" charts look weird (doesn't end up
    // looking like a line at all, but rather a point).
    // In this case, make these charts "bar" charts instead
    if (singleMonth && (this.key.startsWith('ctr_') || this.key === 'newsletterOpenRate' || this.key === 'newsletterCtr' || this.key === 'websiteClicks')) {

      chartType = 'bar';
      lineToBarChart = true;
    }

    let chartData = this.chart.data;

    // Various chart options; have left some things commented out so
    // we can remember how to tweak once we enter the 
    // fine-tuning phase of things
    let chartOptions: any = {
      elements: {
        line: {tension: 0, fill: false}
      },
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
          mode: 'nearest',
          callbacks: {
              label: function(tooltipItem, data) {

                  var label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (chartType === 'line') {
                    return label;
                  }

                  if (label) {
                      label += ': ';
                  }

                  label += isNaN(chartType === 'bar' ? tooltipItem.yLabel : tooltipItem.xLabel) ? '0' : (chartType === 'bar' ? tooltipItem.yLabel : tooltipItem.xLabel);

                  return label;
              }
          }
      },
      scales: { 
        xAxes: [{
          ticks: {
            fontSize: 14,
            fontFamily: "Oswald",
            fontWeight: 600,
            color: "#58595b",
            beginAtZero: true
          }
        }], 
        yAxes: [{
          ticks: {
            fontSize: 12,
            fontFamily: "Oswald",
            fontWeight: 600,
            color: "#231f20",
            beginAtZero: true
          }
        }] },
      layout: {
          padding: {
            top: 50,
            right: 30
          }
        },
      plugins: {}
    };

    if (this.key === 'printImpressions') {
      chartOptions.scales.xAxes[0].stacked = true;
      chartOptions.scales.yAxes[0].stacked = true;
    }

    if (!lineToBarChart && (this.key.startsWith('ctr') || this.key === 'websiteClicks' || this.key === 'newsletterOpenRate' || this.key === 'newsletterCtr')) {
      chartOptions.scales.xAxes[0].offset = true;
    }

    if (this.key !== 'websiteImpressions' && this.key !== 'websiteClicks') chartOptions.aspectRatio = 1;

    // IF LINE CHART
    if (chartType === 'line') { 
      chartOptions.scales.yAxes[0].ticks.callback = function(value, index, values) {
          return (that.key === 'websiteClicks') ? value : value + '%';
      };

      chartOptions.plugins.datalabels = {
        // display: false,
        display: function(context) {
          const nonZero = context.dataset.data[context.dataIndex].constructor !== Array && context.dataset.data[context.dataIndex] != 0;

          return nonZero ? 'auto' : false; // no overlap if exists, else don't display 0
        },
        align: function(context) {
          return 'end';
        },
        backgroundColor: function(context) {
          return context.dataset.borderColor;
        },
        borderColor: function(context) {
          return null;
        },
        borderWidth: function(context) {
          return 0;
        },
        color: function(context) {
          return 'white';
        },
        font: {
          family: "Oswald",
          weight: 600,
          size: 15
        },
        formatter: function(value, context) {
          if (that.key === 'websiteClicks') {
            return ((value && value.constructor !== Array) ? value.toLocaleString('en') : '0');
          } else {
            return ((value && value.constructor !== Array) ? +(value.toFixed(2)) : '0') + '%';
          }
        }
      };

      if (that.key === 'websiteClicks') {
        chartOptions.plugins.datalabels['borderRadius'] = function(context) {
          return context.active ? 0 : 32;
        };
        chartOptions.plugins.datalabels['offset'] = 8;
        chartOptions.plugins.datalabels['padding'] = 10;

        chartOptions.scales.yAxes[0].ticks.callback = function(value, index, values) {
          return that.addCommas(value);
        }
      }

    // IF NOT LINE CHART
    } else {

      chartOptions.plugins.datalabels = {
        display: function(context) {
          const nonZero = context.dataset.data[context.dataIndex].constructor !== Array && context.dataset.data[context.dataIndex] != 0;
          // show if label exists and we shouldn't hide them, else hide
          return (nonZero && !that.hideLabels) ? true : false; 
        },
        font: {
          family: "Oswald",
          weight: 600,
          size: 15
        },
        rotation: (chartType === 'bar' && that.key !== 'printImpressions') ? 300 : 0,
        color: function(context) {

          if (that.key === 'eblastImpressions') {
            return '#58595b';
          }

          return ((chartType === 'bar' || chartType === 'horizontalBar') && that.key !== 'printImpressions') ? context.dataset.backgroundColor : '#FFFFFF';
        },
        formatter: function(value, context) {
          // NEWISH
          if (lineToBarChart) {
            if (that.key === 'websiteClicks') {
              return ((value && value.constructor !== Array) ? value.toLocaleString('en') : '0');
            } else {
              return ((value && value.constructor !== Array) ? +(value.toFixed(2)) : '0') + '%';
            }
          } else {
            return value.toLocaleString('en');
          }
        },
 };

      if (that.key !== 'printImpressions' && that.key !== 'eblastImpressions') {
        chartOptions.plugins.datalabels['anchor'] = 'end';
        chartOptions.plugins.datalabels['align'] = (chartType === 'bar' || chartType === 'horizontalBar') ? 'end' : 'left';
      }

      // The "reverse" chart
      if (that.key === 'eblastImpressions') {
        chartOptions.plugins.datalabels['anchor'] = 'start';
        chartOptions.plugins.datalabels['align'] = 'start';
        chartOptions.scales.xAxes[0].ticks['reverse'] = true;
        chartOptions.scales.yAxes[0]['position'] = 'right';
      }

      if (chartType === 'horizontalBar') {
        // Add commas to numbers on x-axis
        chartOptions.scales.xAxes[0].ticks.callback = function(value, index, values) {
            return that.addCommas(value);
        };

        // If "reverse" chart
        if (that.key === 'eblastImpressions') {
         chartOptions.layout.padding.left = 100;
         //chartOptions.scales.layout.padding.right = 0;
         //chartOptions.scales.layout.padding.top = 0;
        } else {
          chartOptions.layout.padding.right = 100;
          //chartOptions.scales.layout.padding.left = 0;
          //chartOptions.scales.layout.padding.top = 0;
        }
      } else {
        // Add commas to numbers on y-axis
        chartOptions.scales.yAxes[0].ticks.callback = function(value, index, values) {

            // NEWISH
            if (lineToBarChart) {
              return (that.key === 'websiteClicks') ? that.addCommas(value) : that.addCommas(value) + '%';
            } else {
              return that.addCommas(value);
            }
        };
      }

    }

    setTimeout(function(){
      const canvas = <HTMLCanvasElement> document.getElementById(that.id);
      const ctx = canvas.getContext('2d');

      const myChart = new Chart(ctx, {
          type: chartType,
          plugins: that.chartPlugins,
          data: {
            datasets: chartData,
            labels: chartLabels
          },
          options: chartOptions
      });
    }, 2000); 

  }

  /**
   * Returns comma separated number
   * @param any value
   * @return string
   */
  addCommas(value) {
    value = value.toString();
    value = value.split(/(?=(?:...)*$)/);
    value = value.join(',');
    return value;
  }
  
  /**
   * Listens for any changes; if makeImage flag updates to true,
   * calls createCanvasImage(), otherwise nulls out image
   * @param any changes
   * @return void
   */
  ngOnChanges(changes: any){
     if (changes.makeImage) {
       if (changes.makeImage.currentValue == true) {
         this.createCanvasImage();
       } else {
         this.image = null;
       }
     }
  }

  /**
   * Creates the image version of the chart.js canvas
   * @return void
   */
  createCanvasImage(): void {
    this.image = (document.getElementById(this.id) as HTMLCanvasElement).toDataURL();
  }

}
