import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class TestService {
  constructor(private api: ApiService) {}

  public getTest(): Observable<any> {
    return this.api.get<Response>("/api/test");
  }

}