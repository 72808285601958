import { Component, Input, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { Subscription } from "rxjs";
import { ReportService } from 'src/app/services/report/report.service';
import { DeletePopupComponent } from 'src/app/pages/report/pdf/delete-popup/delete-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WindowProviderService } from 'src/app/utility/window-provider.service';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnDestroy {

  @Input() itemArray: any;
  @Input() editable: boolean;
  @Input() type: any;
  @Input() canDelete?: boolean;
  @Input() key?: string;
  @Input() index?: number;

  private _subscriptions = new Subscription();

  /**
   * Reference to browser window
   */
  private _window: Window;

  constructor(private editItemService: EditItemService, private reportService: ReportService, private dialog: MatDialog, windowProvider: WindowProviderService) { 

    this._window = windowProvider.nativeWindow();
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @return void
   */
  editItem(item) {
    this.editItemService.editItem(item, this.type, this.key, this.itemArray, true, this.index);
  }

  /**
   * Opens new window, adding protocol to url if doesn't exist
   * @param any event
   * @param string url
   * @return void
   */
  goToUrl(event, url) {
    if (event.srcElement.className !== 'print-data-text') {
      return;
    }

    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = 'https://' + url;
    }

    this._window.open(url, '_blank');
  }

  /**
   * Gets print ad circulation numbers for given printAdId
   * Calls the handlePromoItem() function (to deal with impression number changes)
   * Calls deleteItem()
   * @param printAdId
   * @return void
   */
  getPrintAdCircNumbersAndDeleteItem(printAdId) {
    this._subscriptions.add(
      this.reportService.getPrintAdCircNumbers(printAdId).subscribe(response => {

        const impressions = (response.data && response.data["impressions"]) || 0;

        let promoInfo = {
          preEditCircNumbers: impressions,
          afterEditCircNumbers: 0,
          index: this.index
        };

        if (this.type === 'ebook') this.editItemService.handleEbookPromoImpressionNumbers(promoInfo);
        if (this.type === 'webcast') this.editItemService.handleWebcastPromoImpressionNumbers(promoInfo);
        if (this.type === 'course') this.editItemService.handleCoursePromoImpressionNumbers(promoInfo);
        if (this.type === 'vtw') this.editItemService.handleVtwPromoImpressionNumbers(promoInfo);

        this.deleteItem(printAdId);
      })
    );
  }

  /**
   * Deletes promo item, removes it from the legend list
   * @param number id
   * @return void
   */
  deleteItem(id: number) {
    this._subscriptions.add(
      this.reportService.deletePromo(id).subscribe(response => {
        if (response.success) {
          const indexToDelete = this.itemArray.findIndex(x => x.object_id === id);
          this.itemArray.splice(indexToDelete, 1);
        }
      })
    );
  }

  /**
   * Opens delete confirmation popup, passing in the report_label.
   * After popup is closed, calls getPrintAdCircNumbersAndDeleteItem() if shouldDelete is true.
   * @param any item
   * @return void
   */
  openDeletePopup(item: any) {
    this.dialog.closeAll();

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      itemName: item.report_label
    };

    const dialogRef = this.dialog.open(DeletePopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.shouldDelete) {
        this.getPrintAdCircNumbersAndDeleteItem(item.object_id);
      }
    });
  }

  /**
   * Prepends protocol if url does not have one
   * @param string url
   * @return string url
   */
  addProtocol(url) {
    if (!url) return;

    if (!url.startsWith('http')) {
      return 'https://' + url;
    }

    return url;
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
