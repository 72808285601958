import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import {MatInputModule} from '@angular/material/input';
import { ReportService } from 'src/app/services/report/report.service';
import { CustomAngularEditorConfig } from 'src/app/utility/custom-angular-editor-config';
import { Subscription } from "rxjs";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute } from '@angular/router';
import * as _ from "lodash";

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit, OnDestroy {

  editorConfig = CustomAngularEditorConfig;
  item: any;
  type: any;
  key: any;
  id: any;
  clonedItem: any;
  brands: any = [];
  printAdTypes: any = [];
  relevantBrands: any = [42, 43, 45, 44]; // Four main brand ids
  startDate: any;
  preEditCircNumbers: number;
  placeholder = "Issue Date*";
  validDate: boolean = true;

  private _subscriptions = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) data: any, public dialogRef: MatDialogRef<EditItemComponent>, private reportService: ReportService, private activatedRoute: ActivatedRoute) { 

    dialogRef.disableClose = true;
    this.item = data.item;
    this.type = data.type;
    if (data.key) this.key = data.key;
    if (data.type === 'custom_eblast' || 
        data.key === 'ottClicksImpressions' || 
        data.key === 'vtwClicksImpressions') {
      this.id = data.item.editable_object.object_id;
    } else {
      this.id = data.item.object_id ? data.item.object_id : data.object_id;
    }
  }

  /**
   * Clones the item immediately if not persistable (cloning 
   * because we don't want the UI immediately reflecting the change
   * before the user has decided to Save the change)
   * If this is a persistable item that already exists in the backend,
   * we first get that object from the backend.
   * @return void
   */
  ngOnInit() {
    if ((this.type === 'mediaMention') || this.key === 'promo') this.getBrands();

    if (this.key === 'promo') this.getPrintAdTypes();

    // Set the clonedItem if not persistable or is new
    if (this.type === 'dateRange' || this.type === 'advertiserName' || this.type === 'overAllPerformanceVerbiage' ||
      (this.type === 'customProduct' && this.id === -1) || (this.type === 'mediaMention' && this.id === -1) || (this.key === 'promo' && this.id === -1)) {
      this.clonedItem = JSON.parse(JSON.stringify(this.item));
    // Otherwise first get the existing item from the backend
    } else {
      this.getObject(this.id);
    }
  }

  /**
   * Gets and sets print ad types
   * @return void
   */
  getPrintAdTypes() {
    this._subscriptions.add(
      this.reportService.getPrintAdTypes().subscribe(response => {
        this.printAdTypes = response.data;
      })
    );
  }

  /**
   * Closes the popup if this is a non-persistable item, and passes the 
   * edited item back to the Edit Item service. Also closes popup for
   * for any item if user discarded changes.
   * If user created a new Custom Product, call createCustomProduct().
   * If user is updating an existing persistable item, call updateObject().
   * @param boolean keepChanges
   * @return void
   */
  closeDialog(keepChanges: boolean) {
    // Non-persistable frontend-only objects
    if (this.type === 'customMarketing' || this.type === 'dateRange' || this.type === 'advertiserName' || this.type === 'overAllPerformanceVerbiage') {
      this.dialogRef.close({keepChanges: keepChanges, newItem: (keepChanges ? this.clonedItem : '')});
    // Persistable items
    } else {
      if (keepChanges) {
        // If new Custom Product user added from the frontend
        if (this.type === 'customProduct' && this.id === -1) {
          this.createCustomProduct();
        // If new Media Mention
        } else if (this.type === 'mediaMention' && this.id === -1) {
          this.createMediaMention();
        // If new Promo item
        } else if (this.key === 'promo' && this.id === -1) {
          this.createPromoItem();
        // Otherwise update the item
        } else {
          this.updateObject();
        }
      // Discard changes
      } else {
        this.dialogRef.close({keepChanges: false, newItem: ''});
      }
    }
  }

  /**
   * Clones the frontend item to be edited.
   * Gets the actual item from the backend.
   * Overwrites the cloned item with the actual item's data.
   * Sets the cloned item to be accessed by the view
   * @param id number
   * @return void
   */
  getObject(id: any) {
    let clonedItem = JSON.parse(JSON.stringify(this.item));

    this._subscriptions.add(
      this.reportService.getCorrectObject(this.type, id, this.key).subscribe(response => {

        if (this.key === 'promo') {
          this.startDate = new Date(response.data.issueDate);
          clonedItem.brand_id = response.data.brandId;
          clonedItem.print_ad_type_id = parseInt(response.data.printAdTypeId);
          clonedItem.custom_fields.url_of_ad = response.data.urlOfAd;
          clonedItem.report_label = response.data.reportLabel;
          this.preEditCircNumbers = parseInt(response.data.impressions);
        }

        if (this.type === 'mediaMention') {
          clonedItem.report_label = response.data.title;
          clonedItem.custom_fields.author = response.data.author;
          clonedItem.custom_fields.brand = response.data.brand.id;
          clonedItem.custom_fields.date = response.data.date;
          clonedItem.custom_fields.impressions = response.data.impressions;
          clonedItem.custom_fields.url = response.data.url;

          // If reponse date is UTC, subtract appropriate offset
          // to get Chicago time (either 5 or 6 hours less depending
          // on daylight savings time)
          if (response.data.date.split('T')[1] == '00:00:00+00:00') {
            let initialDate = new Date(response.data.date);
            let suffix = this.isDST(initialDate) ? "T00:00:00-05:00" : "T00:00:00-06:00";
            response.data.date = response.data.date.split('T')[0] + suffix;
          }

          clonedItem.custom_fields.date = response.data.date;

        } else if (this.type === 'customProduct') {
          clonedItem.report_label = response.data.reportLabel;
          clonedItem.leads = (response.data.leads || response.data.leads === 0) ? response.data.leads : '';
          clonedItem.impressions = (response.data.impressions || response.data.impressions === 0) ? response.data.impressions : '';
          clonedItem.custom_fields.description = response.data.description;
          clonedItem.custom_fields.crm_order_detail_id = response.data.crmOrderDetailId || '';

          // If reponse date is UTC, subtract appropriate offset
          // to get Chicago time (either 5 or 6 hours less depending
          // on daylight savings time)
          if (response.data.date.split('T')[1] == '00:00:00+00:00') {
            let initialDate = new Date(response.data.date);
            let suffix = this.isDST(initialDate) ? "T00:00:00-05:00" : "T00:00:00-06:00";
            response.data.date = response.data.date.split('T')[0] + suffix;
          }

          clonedItem.custom_fields.date = response.data.date;
          clonedItem.custom_fields.advertiser_division_id = response.data.advertiserDivision.id;
        } else {

          if (response.data.report_label || response.data.reportLabel) {
            clonedItem.report_label = response.data.report_label || response.data.reportLabel;
          }

          if (response.data.url_of_ad) {
            clonedItem.custom_fields.url_of_ad = response.data.url_of_ad;
          }

          if (this.key === 'ottClicksImpressions') {
            clonedItem.editable_object.custom_fields.personalization_clicks = response.data.personalizationClicks;
            clonedItem.editable_object.custom_fields.personalization_impressions = response.data.personalizationImpressions;
          }
          if (this.key === 'vtwClicksImpressions') {

            clonedItem.editable_object.custom_fields.personalization_clicks = response.data.personalizationClicks;
            clonedItem.editable_object.custom_fields.personalization_impressions = response.data.personalizationImpressions;
            clonedItem.editable_object.custom_fields.alert_clicks = response.data.alertClicks;
            clonedItem.editable_object.custom_fields.alert_impressions = response.data.alertImpressions;
          }
        }

        this.clonedItem = clonedItem;
      })
    );
  }

  /**
   * Creates the payload.
   * Calls report service createCustomProduct().
   * Once the actual Custom Product is created in the backend,
   * set its id on the clonedItem.
   * Closes the popup, passing the new clonedItem back to the Edit Item service
   * @return void
   */
  createCustomProduct() {

    if (this.clonedItem.leads) this.clonedItem.leads = Math.abs(this.clonedItem.leads);
    if (this.clonedItem.impressions) this.clonedItem.impressions = Math.abs(this.clonedItem.impressions);

    let payload = {
      advertiser_division_id: this.clonedItem.custom_fields.advertiser_division_id,
      date: this.clonedItem.custom_fields.date,
      report_label: this.clonedItem.report_label,
      leads: (this.clonedItem.leads || this.clonedItem.leads === 0) ? this.clonedItem.leads : null,
      impressions: (this.clonedItem.impressions || this.clonedItem.impressions === 0) ? this.clonedItem.impressions : null,
      description: this.clonedItem.custom_fields.description,
      crm_order_detail_id: this.clonedItem.custom_fields.crm_order_detail_id || null
    };

    this._subscriptions.add(
      this.reportService.createCustomProduct(payload).subscribe(response => {
        this.clonedItem.object_id = response.data.id;
        this.dialogRef.close({keepChanges: true, newItem: this.clonedItem});
      })
    );
  }

  /**
   * Creates the payload.
   * Calls report service createMediaMention().
   * Once the actual Media Mention is created in the backend,
   * set its id on the clonedItem.
   * Closes the popup, passing the new clonedItem back to the Edit Item service
   * @return void
   */
  createMediaMention() {

    let payload = {
      advertiser_division_id: this.clonedItem.custom_fields.advertiser_division_id,
      date: this.clonedItem.custom_fields.date,
      report_label: this.clonedItem.report_label,
      url: this.clonedItem.custom_fields.url,
      author: this.clonedItem.custom_fields.author,
      impressions: this.clonedItem.custom_fields.impressions,
      brand: this.clonedItem.custom_fields.brand
    };

    this._subscriptions.add(
      this.reportService.createMediaMention(payload).subscribe(response => {
        this.clonedItem.object_id = response.data.id;
        this.dialogRef.close({keepChanges: true, newItem: this.clonedItem});
      })
    );
  }

  /**
   * Creates promo item and then calls getPrintAdCircNumbersAndClosePopup()
   * @return void
   */
  createPromoItem() {
    this.clonedItem["issue_date"] = this.startDate;

      this._subscriptions.add(
        this.reportService.createNewPromo(this.clonedItem).subscribe(response => {
          this.clonedItem.object_id = response.data.id;
          this.getPrintAdCircNumbersAndClosePopup(this.clonedItem.object_id);
        })
      );
  }

  /**
   * Gets print ad circulation numbers, sets those on the clonedItem, then closes popup
   * @return void
   */
  getPrintAdCircNumbersAndClosePopup(printAdId) {
    this._subscriptions.add(
      this.reportService.getPrintAdCircNumbers(printAdId).subscribe(response => {
        this.clonedItem["preEditCircNumbers"] = this.preEditCircNumbers;
        this.clonedItem["afterEditCircNumbers"] = parseInt(response.data["impressions"]);

        this.dialogRef.close({keepChanges: true, newItem: this.clonedItem});
      })
    );
  }

  /**
   * Creates the payload.
   * Calls report service updateCorrectObject().
   * set its id on the clonedItem.
   * Once updated, closes the popup, passing the new clonedItem back to the Edit Item service
   * @return void
   */
  updateObject() {

    let payload = {id: this.id, report_label: this.clonedItem.report_label};

    if (this.key === "promo") {
      this.clonedItem["issue_date"] = this.startDate;
      payload = this.clonedItem;
    }

    if (this.type === 'customProduct') {

      if (this.clonedItem.leads) this.clonedItem.leads = Math.abs(this.clonedItem.leads);
      if (this.clonedItem.impressions) this.clonedItem.impressions = Math.abs(this.clonedItem.impressions);

      payload["description"] = this.clonedItem.custom_fields.description || null;
      payload["crm_order_detail_id"] = this.clonedItem.custom_fields.crm_order_detail_id || null;
      payload["date"] = this.clonedItem.custom_fields.date;
      payload["leads"] = (this.clonedItem.leads || this.clonedItem.leads === 0) ? this.clonedItem.leads : null;
      payload["impressions"] = (this.clonedItem.impressions || this.clonedItem.impressions === 0) ? this.clonedItem.impressions : null;
      payload["advertiser_division_id"] = this.clonedItem.custom_fields.advertiser_division_id;
    }

    if (this.clonedItem.custom_fields && this.clonedItem.custom_fields.url_of_ad) {
      payload["url_of_ad"] = this.clonedItem.custom_fields.url_of_ad;
    }

    if (this.type === 'custom_eblast') {
      if (this.clonedItem.leads) this.clonedItem.leads = Math.abs(this.clonedItem.leads);

      payload["leads"] = this.clonedItem.leads;
    }

    if (this.key === 'ottClicksImpressions') {
      // Setting value to positive in case it's negative
      if (this.clonedItem.editable_object.custom_fields.personalization_clicks) {
        this.clonedItem.editable_object.custom_fields.personalization_clicks = Math.abs(this.clonedItem.editable_object.custom_fields.personalization_clicks);
      }
      // Setting value to positive in case it's negative
      if (this.clonedItem.editable_object.custom_fields.personalization_impressions) {
        this.clonedItem.editable_object.custom_fields.personalization_impressions = Math.abs(this.clonedItem.editable_object.custom_fields.personalization_impressions);
      }

      payload["personalizationClicks"] = (this.clonedItem.editable_object.custom_fields.personalization_clicks || this.clonedItem.editable_object.custom_fields.personalization_clicks === 0) ? this.clonedItem.editable_object.custom_fields.personalization_clicks : null;

      payload["personalizationImpressions"] = (this.clonedItem.editable_object.custom_fields.personalization_impressions || this.clonedItem.editable_object.custom_fields.personalization_impressions === 0) ? this.clonedItem.editable_object.custom_fields.personalization_impressions : null;
    }

    if (this.type === 'mediaMention') {
      // Setting values to positive in case their negative
      if (this.clonedItem.custom_fields.impressions) this.clonedItem.custom_fields.impressions = Math.abs(this.clonedItem.custom_fields.impressions);

      payload["advertiser_division_id"] = this.clonedItem.custom_fields.advertiser_division_id;
      payload["date"] = this.clonedItem.custom_fields.date;
      payload["report_label"] = this.clonedItem.report_label;
      payload["url"] = this.clonedItem.custom_fields.url;
      payload["author"] = this.clonedItem.custom_fields.author;
      payload["impressions"] = this.clonedItem.custom_fields.impressions;
      payload["brand"] = this.clonedItem.custom_fields.brand;
    }

    if (this.key === 'vtwClicksImpressions') {
      // Setting values to positive in case their negative
      if (this.clonedItem.editable_object.custom_fields.personalization_clicks) this.clonedItem.editable_object.custom_fields.personalization_clicks = Math.abs(this.clonedItem.editable_object.custom_fields.personalization_clicks);

      if (this.clonedItem.editable_object.custom_fields.personalization_impressions) this.clonedItem.editable_object.custom_fields.personalization_impressions = Math.abs(this.clonedItem.editable_object.custom_fields.personalization_impressions);

      if (this.clonedItem.editable_object.custom_fields.alert_clicks) this.clonedItem.editable_object.custom_fields.alert_clicks = Math.abs(this.clonedItem.editable_object.custom_fields.alert_clicks);

      if (this.clonedItem.editable_object.custom_fields.alert_impressions) this.clonedItem.editable_object.custom_fields.alert_impressions = Math.abs(this.clonedItem.editable_object.custom_fields.alert_impressions);

      payload["personalizationClicks"] = (this.clonedItem.editable_object.custom_fields.personalization_clicks || this.clonedItem.editable_object.custom_fields.personalization_clicks === 0) ? this.clonedItem.editable_object.custom_fields.personalization_clicks : null;

      payload["personalizationImpressions"] = (this.clonedItem.editable_object.custom_fields.personalization_impressions || this.clonedItem.editable_object.custom_fields.personalization_impressions === 0) ? this.clonedItem.editable_object.custom_fields.personalization_impressions : null;

      payload["alertClicks"] = (this.clonedItem.editable_object.custom_fields.alert_clicks || this.clonedItem.editable_object.custom_fields.alert_clicks === 0) ? this.clonedItem.editable_object.custom_fields.alert_clicks : null;

      payload["alertImpressions"] = (this.clonedItem.editable_object.custom_fields.alert_impressions || this.clonedItem.editable_object.custom_fields.alert_impressions === 0) ? this.clonedItem.editable_object.custom_fields.alert_impressions : null;
    }

    this._subscriptions.add(
      this.reportService.updateCorrectObject(this.type, this.id, this.key, payload).subscribe(response => {

        if (this.key === "promo") {
          this.getPrintAdCircNumbersAndClosePopup(this.clonedItem.object_id);
        } else {
          this.dialogRef.close({keepChanges: true, newItem: this.clonedItem});
        }
      })
    );
  }

  /**
   * If should disable the "Change" button
   * Basic validation that requires the presence of certain fields
   * @return boolean
   */
  disableButton() {
    if (this.type === 'dateRange') {
      if (this.clonedItem.date && this.clonedItem.date.trim() !== '') {
        return false;
      }
      return true;
    }

    if (this.type === 'advertiserName') {
      if (this.clonedItem.advertiser_name && this.clonedItem.advertiser_name.trim() !== '') {
        return false;
      }
      return true;
    }

    if (this.type === 'overAllPerformanceVerbiage') {
      if (this.clonedItem.verbiage && this.clonedItem.verbiage.trim() !== '') {
        return false;
      }
      return true;
    }

    if (this.type === 'customProduct') {
      if (this.clonedItem.report_label && this.clonedItem.report_label.trim() !== '' && this.clonedItem.custom_fields.date && this.validDate) {

        return false;
      }
      return true;
    }

    if (this.type === 'mediaMention') {

      if (!this.validDate ||
        !this.clonedItem.report_label || 
        !this.clonedItem.custom_fields.url ||
        !this.clonedItem.custom_fields.author ||
        !this.clonedItem.custom_fields.brand ||
        !this.clonedItem.custom_fields.date) {
        return true;
      }

      return false;
    }

    if (this.type === 'custom_eblast' && this.key === 'leads') {
      return false;
    }

    if (this.key === 'ottClicksImpressions') {
      return false;
    }

    if (this.key === 'vtwClicksImpressions') {
      return false;
    }

    if (this.key === 'promo') {
      if (!this.validDate || !this.startDate || !this.clonedItem.report_label || !this.clonedItem.print_ad_type_id || !this.clonedItem.brand_id) {
        return true;
      }
      return false;
    }

    if (this.clonedItem.report_label && this.clonedItem.report_label.trim() !== '') {
      return false;
    }
    return true;
  }

  /**
   * Returns boolean if given Date is in daylight savings time or not
   * @param Date d
   * @return boolean
   */
  isDST(d) {
      let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
      let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
      return Math.max(jan, jul) != d.getTimezoneOffset(); 
  }

  /**
   * Gets and sets brands (first filtering by relevant brands)
   * @return void
   */
  getBrands() {
    this._subscriptions.add(
      this.reportService.getBrands().subscribe(response => {
        this.brands = response.data.filter(s => this.relevantBrands.indexOf(s.id) >= 0);
      })
    );
  }

  /**
   * Closes datepicker and shows error if 
   * selected date is outside of report date range
   * @param any event
   * @param any picker
   */
  closeDatePicker(value, picker?) {

    const startDateTime = this.convertToDate(this.activatedRoute.snapshot.queryParams.startDate).getTime();
    const endDateTime = this.convertToDate(this.activatedRoute.snapshot.queryParams.endDate).getTime();

    const newDateTime = value.getTime();

    const withinRange = (newDateTime >= startDateTime) && (newDateTime <= endDateTime);

    if (this.key === 'promo') this.startDate = value;

    if (withinRange) {
      this.placeholder = "Issue Date*";
      this.validDate = true;
    } else {
      this.placeholder = "Date must be within report date range";
      this.validDate = false;
    }

    if (this.key === 'promo') picker.close();
  }

  /**
   * Accepts date string and return Date object
   * @param string dateString
   * @return Date
   */
  convertToDate(dateString) {
    let dateArray = dateString.split("-");
    let year = parseInt(dateArray[0]);
    let month = parseInt(dateArray[1], 10) - 1;
    let date = parseInt(dateArray[2]);
    return new Date(year, month, date);
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
