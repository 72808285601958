import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { SalesRep } from 'src/app/models/sales-rep.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { Subscription } from "rxjs";
import { ValidationService } from "src/app/services/validation/validation.service";
import { ParentErrorStateMatcher } from "src/app/services/validation/parentErrorStateMatcher";

@Component({
  selector: 'app-salesrep-new-edit',
  templateUrl: './salesrep-new-edit.component.html',
  styleUrls: ['./salesrep-new-edit.component.scss']
})
export class SalesrepNewEditComponent implements OnInit, OnDestroy {

  isNewSalesRep: boolean = false;
  passwordMatchError: boolean = false;
  salesRep: SalesRep;
  loggedInUserId: string;
  private _subscriptions = new Subscription();

  // cross field validation of password-confirmPassword
  parentErrorStateMatcher = new ParentErrorStateMatcher();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private builder: FormBuilder, @Inject(UserService) private userService: UserService) { 
      this.isNewSalesRep = !this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.loggedInUserId = localStorage.getItem('userId');
    localStorage.setItem('fromSalesRepPage', 'true');
    this.isNewSalesRep ? this.createNew() : this.getSalesRep();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Goes to salesRep list page
   */
  goToUsers() {
    this.router.navigate(["/users"]);
  }

  /**
   * Creates new empty salesRep model
   */
  createNew() {
    const salesRep = new SalesRep(-1,'','','','');
    salesRep.form = this.buildSalesRepForm(salesRep);

    this.salesRep = salesRep;
  }

  /**
   * Gets salesRep
   */
  getSalesRep() {
    this._subscriptions.add(
      this.userService.getOneSalesRep(this.activatedRoute.snapshot.params.id).subscribe(response => {
        if (response.success && response.data) {

          const salesRep = response.data[0];
          salesRep.form = this.buildSalesRepForm(response.data[0]);
          this.salesRep = salesRep;
        }
      })
    );
  }

  /**
   * If should show the Save button
   */
  showSaveButton() {
    if (this.salesRep.form.status === 'INVALID') return false;

    const firstNameChanged = this.salesRep.firstName !== this.salesRep.form.get("firstName").value;
    const lastNameChanged = this.salesRep.lastName !== this.salesRep.form.get("lastName").value;
    const emailChanged = this.salesRep.email !== this.salesRep.form.get("email").value;
    const phoneChanged = this.salesRep.phone !== this.salesRep.form.get("phone").value;

    if (this.isNewSalesRep) {
      if (firstNameChanged && lastNameChanged && emailChanged && phoneChanged) {
        return true;
      } else {
        return false;
      }
    } else {
      if (firstNameChanged || lastNameChanged || emailChanged || phoneChanged) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Saves salesRep
   * @param salesRep
   */
  saveSalesRep(salesRep: any) {

    let payload = {
        id: salesRep.id,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      };

    if (salesRep.form.get("firstName").value) payload.firstName = salesRep.form.get("firstName").value;
    if (salesRep.form.get("lastName").value) payload.lastName = salesRep.form.get("lastName").value;
    if (salesRep.form.get("email").value) payload.email = salesRep.form.get("email").value;
    if (salesRep.form.get("phone").value) payload.phone = salesRep.form.get("phone").value;

    // New
    if (salesRep.id === -1) {
      this._subscriptions.add(
        this.userService.createSalesRep(payload).subscribe(response => {
          if (response.success) this.goToUsers();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.userService.updateSalesRep(salesRep.id, payload).subscribe(response => {
          if (response.success) this.goToUsers();
        })
      );
    }
  }

  /**
   * Builds form
   * @param salesRep
   * @return FormGroup
   */
  buildSalesRepForm(salesRep: any): FormGroup {

    const form = {
        email: [
          (salesRep.email),//.trim(),
          [
            Validators.required,
            Validators.pattern(
              ValidationService.getEmailRegex()
            )
          ]
        ],
        firstName: [
          (salesRep.firstName),//.trim(),
          [
            Validators.required,
            Validators.pattern(
              ValidationService.getRegexWithSpaceRestriction()
            )
          ]
        ],
        lastName: [
          (salesRep.lastName),//.trim(),
          [
            Validators.required,
            Validators.pattern(
              ValidationService.getRegexWithSpaceRestriction()
            )
          ]
        ],
       phone: [
         (salesRep.phone),//.trim(),
         [
           Validators.required,
           Validators.pattern(
             ValidationService.validatePhone()
           )
         ]
       ],
    };

    return this.builder.group(form);
  }

}
