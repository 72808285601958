import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EbookInstancePerformance } from 'src/app/models/ebook-instance-performance.model';
import { AppDateAdapter } from 'src/app/utility/app-date-adapter';
import { AppDateFormats} from 'src/app/utility/app-date-formats';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { BrandService } from 'src/app/services/brand/brand.service';
import { Subscription } from "rxjs";
import { EbookService } from 'src/app/services/ebook/ebook.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-ebook-stats-new-edit',
  templateUrl: './ebook-stats-new-edit.component.html',
  styleUrls: ['./ebook-stats-new-edit.component.scss'],
  providers: [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: AppDateFormats}
    ]
})
export class EbookStatsNewEditComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  isNewPerformance: boolean = false;
  loading: boolean = true;
  saving: boolean = false;
  startDate: any;
  endDate: any;
  ebookInstancePerformance: EbookInstancePerformance;

  @ViewChild('customInput', {static: false}) customInput: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private builder: FormBuilder, private ebookService: EbookService) { 
      this.isNewPerformance = !this.activatedRoute.snapshot.params.performanceId;
  }

  /**
   * Calls createNew() if this is the "new" page, else calls getEbookInstancePerformance() if editing
   */
  ngOnInit() {
    this.isNewPerformance ? this.createEbookInstancePerformance() : this.getEbookInstancePerformance();
  }

  /**
   * Gets main ebook instance
   */
  createEbookInstancePerformance() {
    this._subscriptions.add(
      this.ebookService.getMainEbookInstance(this.activatedRoute.snapshot.params.ebookInstanceId).subscribe(response => {
        const ebookInstancePerformance = new EbookInstancePerformance(-1, '', '', 0, 0, 0, 0, 0, 0, response.data, false);
        ebookInstancePerformance.form = this.buildEbookInstancePerformanceForm(ebookInstancePerformance);

        this.ebookInstancePerformance = ebookInstancePerformance;
        this.loading = false;
      })
    );
  }

  /**
   * Gets ebook instance performance
   */
  getEbookInstancePerformance() {
    this._subscriptions.add(
      this.ebookService.getEbookInstancePerformance(
        this.activatedRoute.snapshot.params.ebookInstanceId, 
        this.activatedRoute.snapshot.params.performanceId).subscribe(response => {

        if (response.success) {
          const ebookInstancePerformance = response.data;
          ebookInstancePerformance.form = this.buildEbookInstancePerformanceForm(ebookInstancePerformance);
          this.ebookInstancePerformance = ebookInstancePerformance;
          this.startDate = new Date(response.data.startDate);
          this.loading = false;
        }
      })
    );
  }

  /**
   * Closes datepicker
   */
  closeDatePicker(event, picker) {
    this.startDate = event;
    picker.close();
  }

  /**
   * Gets end date
   */
  getEndDate() {
    let endDate = new Date(this.startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setHours(-1);

    const year = (endDate.getFullYear()).toString();
    const month = (endDate.getMonth() + 1).toString();

    return (year + "-" + month + "-" + endDate.getDate().toString());
  }

  /**
   * Returns date string
   * @param date
   * @return string
   */
  getDateString(date) {
    const year = (date.getFullYear()).toString();
    const month = (date.getMonth() + 1).toString();
    const day = (date.getDate()).toString();
    return year + "-" + month + "-" + day;
  }

  /**
   * Boolean if should disable Save button
   */
  disableSave() {
    return (this.ebookInstancePerformance.form.status === 'INVALID' || !this.startDate) ? true : false;
  }

  /**
   * Main save function
   */
  save() {
    if (this.ebookInstancePerformance.form.status == 'INVALID') return;

    this.saving = true;

    let payload = {
      id: this.ebookInstancePerformance.id,
      startDate: this.getDateString(this.startDate),
      endDate: this.getEndDate(),
      issueVisits: this.ebookInstancePerformance.form.get("issueVisits").value,
      pageViews: this.ebookInstancePerformance.form.get("pageViews").value,
      clicks: this.ebookInstancePerformance.form.get("clicks").value,
      downloads: this.ebookInstancePerformance.form.get("downloads").value,
      secondsSpent: this.ebookInstancePerformance.form.get("secondsSpent").value,
      registrants: this.ebookInstancePerformance.form.get("registrants").value
    };

    // New
    if (this.ebookInstancePerformance.id === -1) {
      this._subscriptions.add(
        this.ebookService.createEbookInstancePerformance(this.activatedRoute.snapshot.params.ebookInstanceId, payload).subscribe(response => {
          
          if (response.success) this.goBack();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.ebookService.updateEbookInstancePerformance(
            this.activatedRoute.snapshot.params.ebookInstanceId,
            this.activatedRoute.snapshot.params.performanceId,
            payload
          ).subscribe(response => {
            if (response.success) this.goBack();
        })
      );
    }
  }

  /**
   * Builds form
   */
  buildEbookInstancePerformanceForm(ebookInstancePerformance) {

    const form = {
        issueVisits: [
          (ebookInstancePerformance.issueVisits),//.trim(),
          [
            Validators.required
          ]
        ],
        pageViews: [
          (ebookInstancePerformance.pageViews),//.trim(),
          [
            Validators.required
          ]
        ],
        clicks: [
          (ebookInstancePerformance.clicks),//.trim(),
          [
            Validators.required
          ]
        ],
        downloads: [
          (ebookInstancePerformance.downloads),//.trim(),
          [
            Validators.required
          ]
        ],
        secondsSpent: [
          (ebookInstancePerformance.secondsSpent),//.trim(),
          [
            Validators.required
          ]
        ],
        registrants: [
          (ebookInstancePerformance.registrants),//.trim(),
          [
            Validators.required
          ]
        ]
    };

    return this.builder.group(form);

  }

  /**
   * Back to previous page
   */
  goBack() {
    this.router.navigate(["/ebooks/" + this.activatedRoute.snapshot.params.ebookInstanceId + "/stats-list"]);
  }

  /**
   * Unsubscribes to subscription
   * Remove subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
