import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  public success = new EventEmitter<any>();

  constructor(private api: ApiService) {}

  public updateEmailInfo(payload): Observable<any> {
    return this.api.post<Response>("/api/esp-deployment/update", payload);
  }

  public updateBannerInfo(payload): Observable<any> {
    return this.api.post<Response>("/api/web-ad-banner/update", payload);
  }

  public getCorrectionLog(): Observable<any> {
    return this.api.get<Response>("/api/banner-correction-log/all");
  }
}
