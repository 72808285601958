import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { EbookService } from 'src/app/services/ebook/ebook.service';
import { Subscription } from "rxjs";
import { Ebook } from 'src/app/models/ebook.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-ebook-stats-upload-sheet',
  templateUrl: './ebook-stats-upload-sheet.component.html',
  styleUrls: ['./ebook-stats-upload-sheet.component.scss']
})
export class EbookStatsUploadSheetComponent implements OnInit {
  loading: boolean = false;
  key: string = "default";
  startDate: any;
  endDate: any;
  downloaded: boolean = false;
  template;
  fileSelected: boolean = false;
  showInfo: boolean = false;
  uploadResults: Object = {
    successes: [],
    failures: []
  };
  constructor(private ebookService: EbookService, private formBuilder: FormBuilder) { 
   
  }
  private _subscriptions = new Subscription();
  uploadForm = this.formBuilder.group({
    file: new FormControl(),
    submit: new FormControl()
  });
  @Input()
  ebooks: Ebook; 
  @ViewChild('sheetUploadMat') sheetUploadMat;
  @ViewChild('sheetUpload') sheetUploadInput;



  ngOnInit(): void {
    const endDate = new Date();
    const year = endDate.getFullYear();
    const month = endDate.getMonth();
    this.startDate = new Date(year, month - 1, 1);
    this.endDate = new Date(year, month , 0);
  }
  /**
   * Prepares and downloads blank template ebook spread sheet
   */
  downloadSheetTemplate() {
    this.loading = true;
    this.key = "attempting";
    let payload = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    this._subscriptions.add(
      this.ebookService.getTemplateSheet(payload).subscribe(response => {
        if (response.success && response.data) {
          this.key = "success";
          let today = (new Date()).toISOString().substring(0,7);
          let fileName = "standard-ebook-template-" + today + ".xlsx";
          let file = {
            link : response.data.ebookTemplate_url,
            fileName : fileName
          };
          this.downloadFile(file);
        }
        this.loading = false;
      })
    );
  }

  uploadStats(form) {
    if (this.fileSelected) {
      this.loading = true;
      let payload = new FormData();
      payload.append('file', this.uploadForm.value.file);
      payload.append('name', "sheetUpload.xlsx");
      payload.append('identifier', "123");
      this._subscriptions.add(
        this.ebookService.uploadEbookSheet(payload).subscribe(response => {
          if(response.data.uploaded) {
            this.uploadResults = {
              successes : response.data.uploaded,
              failures :  response.data.invalidRows
            };
          }
          this.loading = false;
        })
      );
    }
  }
  /**
   * 
   * @param event Listener function for when the upload file is changed
   */
  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.uploadForm.value.file = event.target.files[0];
      this.fileSelected = true;
     this.sheetUploadMat.color = "primary";

    } 
  }
  /**
   * Unsubscribes to subscription
   * Remove subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
  /**
   * Downloads file
   * @param Object data
   */
  downloadFile(data) {
    const anchor = document.createElement('a');
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('href', data.link);
    anchor.setAttribute('id', 'downloadAnchor')
    anchor.setAttribute('download', data.fileName);
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    this.downloaded = true;
  }
  /**
   * Toggles the information display menu
   */
  templateInfo() {
    this.showInfo = !this.showInfo;
  }
  
}
