import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from 'src/app/models/user.model';
import { SalesRep } from 'src/app/models/sales-rep.model';
import { Response } from 'src/app/models/response.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationService } from "src/app/services/validation/validation.service";
import { UserService } from "src/app/services/user/user.service";
import { ParentErrorStateMatcher } from "src/app/services/validation/parentErrorStateMatcher";
import { ErrorStateMatcher } from '@angular/material/core';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.scss']
})
export class UserMainComponent implements OnInit, OnDestroy {

	admin: User[] = [];
	salesReps: SalesRep[] = [];
	selectedVal: string = '';
	panelOpenState: any = {};
  loggedInUserId: string;
	newAdminInProgress: boolean = false;
	newSalesRepInProgress: boolean = false;
  fromSalesRepPage: boolean = false;
  displayedColumns: string[] = ['email', 'editAction', 'deleteAction'];

  // cross field validation of password-confirmPassword
  parentErrorStateMatcher = new ParentErrorStateMatcher();

  private _subscriptions = new Subscription();

	@ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

  constructor(private http: HttpClient, private builder: FormBuilder, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.checkLocalStorage();  	
  	this.fetchUsers();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  checkLocalStorage() {
    this.fromSalesRepPage = !!localStorage.getItem('fromSalesRepPage');
    localStorage.removeItem('fromSalesRepPage');
    this.selectedVal = this.fromSalesRepPage ? 'salesRep' : 'admin';
    this.loggedInUserId = localStorage.getItem('userId');
  }

  goHome() {
    this.router.navigate(["/home"]);
  }

  edit(type, id) {
    this.router.navigate(["/" + type.toLowerCase() + "/" + id + "/edit"]);
  }

  delete(type, id, identifier) {
    event.stopPropagation();

    if (type === 'admin' && (id == this.loggedInUserId)) return;

    const confirmDelete = confirm('Are you sure you want to delete ' + identifier + '?');

    if (confirmDelete) {

      if (type === 'admin') {
        this._subscriptions.add(
          this.userService.deleteAdmin(id).subscribe(response => {
            if (response.success) this.fetchUsers();
          })
        );
      }

      if (type === 'salesRep') {
        this._subscriptions.add(
          this.userService.deleteSalesRep(id).subscribe(response => {
            if (response.success) this.fetchUsers();
          })
        );
      }
      
    }
  }

  resetAdmin(i, admin) {
    this.panelOpenState[i] = false;
    admin.form.get("email").setValue(admin.email);
    admin.form.get("passwordGroup.password").setValue(null);
    admin.form.get("passwordGroup.confirmPassword").setValue(null);
  }

  resetSalesRep(i, salesRep) {
    this.panelOpenState[i] = false;
    salesRep.form.get("firstName").setValue(salesRep.firstName);
    salesRep.form.get("lastName").setValue(salesRep.lastName);
    salesRep.form.get("email").setValue(salesRep.email);
    salesRep.form.get("phone").setValue(salesRep.phone);
  }

  closeAllPanels() {
  	this.viewPanels.forEach(p => p.close());
  }

  saveAdmin(user: any) {

    const emailChanged = user.email !== user.form.get("email").value;
    const passwordChanged = user.password !== user.form.get("passwordGroup.password").value;

    if (!user.form.status) return;
    if (!emailChanged && !passwordChanged) return;
    if (user.id === 0 && (!emailChanged || !passwordChanged)) return;

    const editingSelf = this.loggedInUserId == user.id;
    if (editingSelf) {
       const confirmSave = confirm('Updating your own info will log you out. This is normal. Just log back in afterwards.');

       if (!confirmSave) return;
    }
    
    let payload = {
        id: user.id,
        email: '',
        password: ''
      };

    if (user.form.get("email").value) payload.email = user.form.get("email").value;
    if (user.form.get("passwordGroup.password").value) payload.password = user.form.get("passwordGroup.password").value;

    // New
    if (user.id === -1) {
      this._subscriptions.add(
        this.userService.createAdmin(payload).subscribe(response => {
          if (response.success) this.fetchUsers();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.userService.updateAdmin(user.id, payload).subscribe(response => {
          if (response.success) {
            this.handleEditOwnCredentials(user.id);
            this.fetchUsers();
          }
        })
      );
    }
  }

  saveSalesRep(salesRep: any) {
    const firstNameChanged = salesRep.firstName !== salesRep.form.get("firstName").value;
    const lastNameChanged = salesRep.lastName !== salesRep.form.get("lastName").value;
    const emailChanged = salesRep.email !== salesRep.form.get("email").value;
    const phoneChanged = salesRep.phone !== salesRep.form.get("phone").value;

    if (!salesRep.form.status) return;
    if (!firstNameChanged && !lastNameChanged && !emailChanged && !phoneChanged) return;
    if (salesRep.id === 0 && (!firstNameChanged || !lastNameChanged || !emailChanged || !phoneChanged)) return;

    let payload = {
        id: salesRep.id,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      };

    if (salesRep.form.get("firstName").value) payload.firstName = salesRep.form.get("firstName").value;
    if (salesRep.form.get("lastName").value) payload.lastName = salesRep.form.get("lastName").value;
    if (salesRep.form.get("email").value) payload.email = salesRep.form.get("email").value;
    if (salesRep.form.get("phone").value) payload.phone = salesRep.form.get("phone").value;

    // New
    if (salesRep.id === -1) {
      this._subscriptions.add(
        this.userService.createSalesRep(payload).subscribe(response => {
          if (response.success) this.fetchUsers();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.userService.updateSalesRep(salesRep.id, payload).subscribe(response => {
          if (response.success) this.fetchUsers();
        })
      );
    }
  }

  // If user just edited their own credentials, log them out
  // We do this because to update the token requires both the user email
  // and the password, and we allow updating just the email, or just the password
  handleEditOwnCredentials(userId) {
    if (this.loggedInUserId != userId) return;

      localStorage.clear();
      this.router.navigate(["/login"]);
   }

  addNew(type) {

    this.router.navigate(["/" + type.toLowerCase() + "/new"]);





  	// this.closeAllPanels();
  	// const that = this;

  	// if (this.selectedVal === 'admin') {
  	// 	this.newAdminInProgress = true;
   //    const newUser = new User(-1, '', '');
   //    newUser.form = this.buildAdminForm(newUser);
  	// 	this.admin.unshift(newUser);
  	// } else {
  	// 	this.newSalesRepInProgress = true;
   //    const newRep = new SalesRep(-1,'','','','');
   //    newRep.form = this.buildSalesRepForm(newRep);
  	// 	this.salesReps.unshift(newRep);
  	// }

  	// setTimeout(function(){
  	// 	that.viewPanels.first.open();
  	// }, 0); 

  }

  cancel(type) {
  	this.closeAllPanels();

  	if (type === 'admin') {
  		this.newAdminInProgress = false;
  		this.admin.shift();
  	} else {
  		this.newSalesRepInProgress = false;
  		this.salesReps.shift();
  	}
  }

  onValChange(val: string) {
    this.selectedVal = val;
    this.closeAllPanels();
    
    if (this.newAdminInProgress) {
    	this.newAdminInProgress = false;
    	this.admin.shift();
    }  

    if (this.newSalesRepInProgress) {
    	this.newSalesRepInProgress = false;
    	this.salesReps.shift();
    }
  }

  setupAdmin(admin: User[]) {
    for (let user of admin) {
      user.form = this.buildAdminForm(user);
    }

    return admin;
  }

  setupSalesRep(salesReps: SalesRep[]) {
    for (let rep of salesReps) {
      rep.form = this.buildSalesRepForm(rep);
    }

    return salesReps;
  }

  fetchUsers() {
    this.newAdminInProgress = false;
    this.newSalesRepInProgress = false;

    this._subscriptions.add(
      this.userService.getAdmin().subscribe(response => {
        this.admin = this.setupAdmin(response.data);
      })
    );

    this._subscriptions.add(
      this.userService.getSalesReps().subscribe(response => {
        this.salesReps = this.setupSalesRep(response.data);
      })
    );

  }

  buildAdminForm(user: any): FormGroup {
    const form = {
        email: [
          (user.email),//.trim(),
          [
            Validators.required,
            Validators.pattern(
              ValidationService.getRegexWithSpaceRestriction()
            )
          ]
        ],
      // make password group
      passwordGroup: this.builder.group(
        {
          password: [
            user.password,
            [
              Validators.pattern(ValidationService.getPasswordRegex())
            ]
          ],
          confirmPassword: [user.password]
        },
        {
          /**
           * !NOTE
           *  passing undefined, because we want to show the error message as soon as
           *  user starts changing in the password/confirmPassword input-fields
           */
          validators: ValidationService.matchPassword(undefined)
        }
      )

    };

    return this.builder.group(form);

  }

  buildSalesRepForm(salesRep: any): FormGroup {

    const form = new FormGroup({
      firstName: new FormControl(salesRep.firstName, {
        validators: [
          Validators.required,
          // trailing and leading space allowed, errors on space
          Validators.pattern(
            ValidationService.getRegexWithSpaceRestriction()
          )
        ]
      }),
      lastName: new FormControl(salesRep.lastName, {
        validators: [
          Validators.required,
          // trailing and leading space allowed, errors on space
          Validators.pattern(
            ValidationService.getRegexWithSpaceRestriction()
          )
        ]
      }),
      email: new FormControl(salesRep.email, {
        // validators set below
      }),
      phone: new FormControl(salesRep.phone, {
        // validators set below
      })
    });

    // validation requires reference to form group, set after group created
    form.get("email").setValidators(this._emailValidation(form));
    form.get("phone").setValidators(this._phoneValidation(form));


    return form;

  }

  private _emailValidation(form: FormGroup): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.compose([
        Validators.required,
        Validators.email
      ])(control);
    };
  }

  /**
   * Validates phone field
   *
   * Validation is contingent on selected contact method.
   *
   * @param form parent form group
   */
  private _phoneValidation(form: FormGroup): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.compose([
        Validators.required,
        Validators.pattern(/([^\d]*\d){10}/gm)
      ])(control);
    };
  }


}
