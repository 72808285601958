import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { EbookService } from 'src/app/services/ebook/ebook.service';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

@Component({
  selector: 'app-ebook-stats-list',
  templateUrl: './ebook-stats-list.component.html',
  styleUrls: ['./ebook-stats-list.component.scss']
})
export class EbookStatsListComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  ebookInstanceId: string;
  ebookInstanceName: string;
  allEbookInstancePerformance: any;
  displayedColumns: string[] = ['start_date', 'editAction'];
  loading: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private ebookService: EbookService) { 
    this.ebookInstanceId = this.activatedRoute.snapshot.params.ebookInstanceId;
  }

  ngOnInit() {
    this.getMainEbookInstance();
    this.getAllEbookInstancePerformance();
  }

  /**
   * Gets main ebook instance
   * @return void
   */
  getMainEbookInstance() {
    this._subscriptions.add(
      this.ebookService.getMainEbookInstance(this.ebookInstanceId).subscribe(response => {
        if (response.success) this.ebookInstanceName = response.data.name;
      })
    );
  }

  /**
   * Gets and sets all ebookInstancePerformance
   * @return void
   */
  getAllEbookInstancePerformance() {
    this._subscriptions.add(
      this.ebookService.getAllEbookInstancePerformance(this.ebookInstanceId).subscribe(response => {
        if (response.success) {
          this.allEbookInstancePerformance = response.data;
          this.loading = false;
        }
      })
    );
  }

  /**
   * Navigates to stats-list/new page
   * @return void
   */
  addMonth() {
    this.router.navigate(["/ebooks/" + this.ebookInstanceId + "/stats-list/new"]);
  }

  /**
   * Navigates to stats-list/edit page
   * @param string performanceId
   * @return void
   */
  edit(performanceId: any) {
    this.router.navigate(["/ebooks/" + this.ebookInstanceId + "/stats-list/" + performanceId + "/edit"]);
  }

  /**
   * Navigates back to main ebook instance list view
   * @return void
   */
  backToListView() {
    this.router.navigate(["/ebooks"]);
  }

  /**
   * Returns date string
   * @param date
   * @return string
   */
  getDateString(date) {
    date = new Date(date);
    const month = monthNames[date.getMonth()];
    const year = (date.getFullYear()).toString();

    return month + " " + year;
  }

  /**
   * Unsubscribes to subscription
   * Remove subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
