import { Component, Input } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent {

  @Input() printData: any;
  @Input() monthLabels: any;
  @Input() dateRange: any;
  @Input() makeImage: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    this.editItemService.editItem(item, type, key);
  }

}
