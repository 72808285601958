import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { AdvertiserDivision } from 'src/app/models/advertiser-division.model';
import { Report } from 'src/app/models/report.model';
import { ReportService } from 'src/app/services/report/report.service';
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-single-advertiser-division',
  templateUrl: './advertiser-division-reports.component.html',
  styleUrls: ['./advertiser-division-reports.component.scss']
})
export class AdvertiserDivisionReportsComponent implements OnInit, OnDestroy {

	// isNewAdDiv: boolean = false;
	advertiserDivision: AdvertiserDivision;
  panelOpenState: any = {};
  reports: Report[] = [];
  newReportInProgress: boolean = false;

  @ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

	private _subscriptions = new Subscription();

  constructor(
  	private activatedRoute: ActivatedRoute, 
  	private adDivService: AdvertiserDivisionService,
    private reportService: ReportService,
  	private router: Router,
  	private builder: FormBuilder) {}

  ngOnInit() {
    this.getAdvertiserDivision();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  editReport(adDivId, reportId) {
    // advertiser-divisions/:id/reports/:id/edit
    this.router.navigate(
      ["/advertiser-divisions/" + adDivId + "/reports/" + reportId + "/edit"]
    );
  }

  goToAdvertiserDivisions() {
  	this.router.navigate(["/advertiser-divisions"]);
  }

  prettifyString(string) {
    return this.getDateString(new Date(string));
  }

  getDateString(date) {
    const month = (date.getMonth() + 1).toString();
    const day = (date.getDate()).toString();
    const year = (date.getFullYear()).toString();
    return month + "-" + day + "-" + year;
  }

  previewReport(adDivId, reportId) {
    event.stopPropagation();
    this.router.navigate(
      ["/advertiser-divisions/" + adDivId + "/reports/" + reportId + "/preview"]
    );

  }

  markDeleted(report) {

    // event.stopPropagation();

    // if (panelOpen) return;

    const firstConfirm = confirm('Are you sure you want to delete this report: ' + this.prettifyString(report.startDate.date) + ' to ' + this.prettifyString(report.endDate.date) + '?');

    if (firstConfirm) {

      const secondConfirm = confirm('Are you SUPER DUPER SURE you want to delete this report: ' + this.prettifyString(report.startDate.date) + ' to ' + this.prettifyString(report.endDate.date) + '?');

      if (secondConfirm) {
        // this.panelOpenState = {};

        this._subscriptions.add(
          this.reportService.delete(report.id).subscribe(response => {
            if (response.success) this.getAdvertiserDivision();
          })
        );
      }
    }

  }

  save(report: any) {
    if (! report.form.get("startDate").value) return;
    if (! report.form.get("endDate").value) return;
    if (Date.parse(report.form.get("startDate").value) > Date.parse(report.form.get("endDate").value)){
       // Don't save if start date is after the end date
       return;
    }

    const startDate = this.getDateString(report.form.get("startDate").value);
    const endDate = this.getDateString(report.form.get("endDate").value);

    let payload = {
      advertiserDivision_id: this.advertiserDivision.id,
      startDate: startDate,
      endDate: endDate
    };

    // New Report
    if (report.id === -1) {
      this._subscriptions.add(
        this.reportService.create(payload).subscribe(response => {
          if (response.success) {
            this.getAdvertiserDivision();
          }
        })
      );
    // Editing existing report
    } else {
      this._subscriptions.add(
        this.reportService.update(report.id, payload).subscribe(response => {
          if (response.success) {
          	this.getAdvertiserDivision();
          }
        })
      );
    }
  }

  closeAllPanels() {
    this.viewPanels.forEach(p => p.close());
  }

  resetReport(i, report) {
    this.panelOpenState[i] = false;

    report.form.get("startDate").setValue(new Date(report.startDate.date));
    report.form.get("endDate").setValue(new Date(report.endDate.date));
  }

  cancel() {
    this.closeAllPanels();

    this.newReportInProgress = false;
    this.reports.shift();
  }

  createNewReport() {

    this.router.navigate(
      ["/advertiser-divisions/" + this.advertiserDivision.id + "/reports/new"]
    );


    // this.closeAllPanels();
    // const that = this;

    // this.newReportInProgress = true;
    // const report = new Report(-1, this.advertiserDivision.id, '', '', false);
    // report.form = this.buildReportForm(report);

    // this.reports.unshift(report);

    // setTimeout(function(){
    //   if (that.viewPanels.first) {
    //     that.viewPanels.first.open();
    //   }
    // }, 0); 
  }

  // Sorts reports by start date, showing report with most recent start date first
  sortReports(reports) {
    return reports.sort(function(a, b) {
      var dateA = new Date(a.startDate.date);
      var dateB = new Date(b.startDate.date);

      if (dateA > dateB ) {
        return -1;
      }
      if (dateA < dateB ) {
        return 1;
      }
      return 0;
    });
  }

  getAdvertiserDivision() {
    this.newReportInProgress = false;

  	const id = this.activatedRoute.snapshot.params.id;

  	this._subscriptions.add(
  	  this.adDivService.getOneWithReports(id).subscribe(response => {
  	    if (response.success) {

          console.log('response', response);

  	    	const advertiserDivision = response.data[0];

          for (let report of advertiserDivision.reports) {
            report.form = this.buildReportForm(report);
          }

          advertiserDivision.reports = this.sortReports(advertiserDivision.reports);

          this.reports = advertiserDivision.reports;
  	    	this.advertiserDivision = advertiserDivision;

          console.log('this.advertiserDivision... ', this.advertiserDivision);
  	    }
  	  })
  	);
  }

  buildReportForm(report: Report) {
    const form = {
        startDate: [
          (new Date(report.startDate.date)),//.trim(),
          [
            Validators.required
          ]
        ],
        endDate: [
          (new Date(report.endDate.date)),//.trim(),
          [
            Validators.required
          ]
        ]

    };

    return this.builder.group(form);

  }



}
