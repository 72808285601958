import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-final-popup',
  templateUrl: './final-popup.component.html',
  styleUrls: ['./final-popup.component.scss']
})
export class FinalPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FinalPopupComponent>) { 
  }

  ngOnInit() {}

  /**
   * Closes popup
   * @return void
   */
  closeDialog() {
  	this.dialogRef.close();
  }

}
