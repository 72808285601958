import { Component, OnInit } from '@angular/core';
import { TestService } from "src/app/services/test/test.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-test-main',
  templateUrl: './test-main.component.html',
  styleUrls: ['./test-main.component.scss']
})
export class TestMainComponent implements OnInit {

  constructor(private testService: TestService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.testService.getTest().subscribe(response => {
      
    });
  }

}
