import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { EbookService } from 'src/app/services/ebook/ebook.service';

@Component({
  selector: 'app-ebook-assign',
  templateUrl: './ebook-assign.component.html',
  styleUrls: ['./ebook-assign.component.scss']
})
export class EbookAssignComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  advertisers: any = [];
  ebooks: any = [];
  selectedEbookInstanceId: any;
  mainEbook: any;
  showSuccess: boolean = false;
  loading: boolean = true;
  ebookInstanceId: string;

  constructor(private activatedRoute: ActivatedRoute, private ebookService: EbookService, private router: Router) { 
    this.ebookInstanceId = this.activatedRoute.snapshot.params.ebookInstanceId;
  }

  ngOnInit() {
    this.getMainEbookInstance();
    this.getAdvertiserEbooksByEbookInstanceId();
    this.getAllEbookInstancesExceptOne();
  }

  /**
   * Sets checked attribute
   * @param any advertiser
   */
  check(advertiser: any) {
    this.showSuccess = false;
    advertiser.checked = advertiser.checked ? false : true;
  }

  /**
   * If should display active "Move" button
   */
  okToMove() {
    if (!this.selectedEbookInstanceId) return false;

    for(let advertiser of this.advertisers) {
       if(advertiser.checked) return true;
    }

    return false;

  }

  /**
   * Main move function
   */
  move() {

    if (!this.selectedEbookInstanceId) return;

    let advertiserEbookIds = [];

    for(let advertiser of this.advertisers) {
       if(advertiser.checked) advertiserEbookIds.push(advertiser.id);
    }

    if (!advertiserEbookIds.length) return;

    const payload = {
      oldEbookInstanceId: this.ebookInstanceId,
      newEbookInstanceId: this.selectedEbookInstanceId,
      advertiserEbookIds: advertiserEbookIds
    };

    this._subscriptions.add(
      this.ebookService.move(payload).subscribe(response => {
        if (response && response.success) {
          this.selectedEbookInstanceId = null;
          this.getAdvertiserEbooksByEbookInstanceId(true);
        }
      })
    );
  }

  /**
   * Gets main ebook instance
   */
  getMainEbookInstance() {
    this._subscriptions.add(
      this.ebookService.getMainEbookInstance(this.ebookInstanceId).subscribe(response => {
        this.mainEbook = response.data;
      })
    );
  }

  /**
   * Gets list of advertiserEbooks that belong to this ebook Instance
   */
  getAdvertiserEbooksByEbookInstanceId(justAssigned?: boolean) {
    this._subscriptions.add(
      this.ebookService.getAdvertiserEbooksByEbookInstanceId(this.ebookInstanceId).subscribe(response => {

        if (justAssigned && !response.data.length) {
          this.backToListView();
          return;
        }

        if (justAssigned) this.showSuccess = true;
        this.advertisers = response.data;
        this.loading = false;
      })
    );
  }

  /**
   * Gets all ebook Instances except the current one
   */
  getAllEbookInstancesExceptOne() {
    this._subscriptions.add(
      this.ebookService.getAllEbookInstancesExceptOne(this.ebookInstanceId).subscribe(response => {
        this.ebooks = response.data;
      })
    );
  }

  /**
     * Accepts date string and returns formatted date string
     * @param string date
     * @return string
     */
    toDateString(date: string) {
      const dateObj = new Date(date);
      const month = dateObj.getUTCMonth() + 1; //months from 1-12
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();

      return month + "-" + day + "-" + year;
    }

  /**
   * Goes back to list view
   */
  backToListView() {
    this.router.navigate(["/ebooks"]);
  }

  /**
   * Unsubscribes to subscription
   * Remove subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
