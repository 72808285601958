// src/app/services/advertiser-division/advertiser-division.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';
@Injectable()
export class EbookService {
  constructor(private api: ApiService) {}

  public getEbookInstances(payload): Observable<any> {
    return this.api.post<Response>("/api/ebook-instances", payload);
  }
  public createEbookInstancePerformance(id, payload): Observable<any> {
    return this.api.post<Response>("/api/ebook-instances/" + id + "/stats-list/new", payload);
  }

  public getMainEbookInstance(id): Observable<any> {
    return this.api.get<Response>("/api/ebook-instances/" + id);
  }

  public getAllEbookInstancePerformance(id): Observable<any> {
    return this.api.get<Response>("/api/ebook-instances/" + id + "/stats-list");
  }

  public getEbookInstancePerformance(ebookInstanceId, performanceId): Observable<any> {
    return this.api.get<Response>("/api/ebook-instances/" + ebookInstanceId + "/stats-list/" + performanceId);
  }

  public updateEbookInstancePerformance(ebookInstanceId, performanceId, payload): Observable<any> {
    return this.api.post<Response>("/api/ebook-instances/" + ebookInstanceId + "/stats-list/" + performanceId + "/edit", payload);
  }

  public getAdvertiserEbooksByEbookInstanceId(id): Observable<any> {
    return this.api.get<Response>("/api/ebook-instances/" + id + "/advertiser-ebooks");
  }

  public getAllEbookInstancesExceptOne(id): Observable<any> {
    return this.api.get<Response>("/api/ebook-instances/allEbookInstancesExceptOne/" + id);
  }

  public move(payload): Observable<any> {
    return this.api.post<Response>("/api/ebook-instances/move", payload);
  }
  
  public getTemplateSheet(payload): any {
    return this.api.post<Response>("/api/ebook-instances/get-template-sheet", payload);
  }

  public uploadEbookSheet(payload): Observable<any> {
    return this.api.post<Response>("/api/ebook-instances/upload-ebook-sheet", payload, true);
  }
  // public getFull(id): Observable<any> {
  //   return this.api.get<Response>("/api/advertiser-divisions/" + id + "/full");
  // }

  // public getOneWithReports(id): Observable<any> {
  //   return this.api.get<Response>("/api/advertiser-divisions/" + id + "/with-reports");
  // }

  // public getCrmCompanies(id): Observable<any> {
  //   return this.api.get<Response>("/api/advertiser-divisions/" + id + "/crm-companies");
  // }

  // public removeCrmCompany(adDivId, crmId): Observable<any> {
  //   return this.api.delete<Response>("/api/advertiser-divisions/" + adDivId + "/crm-companies/" + crmId);
  // }

  // public removeSalesRep(adDivId, repId): Observable<any> {
  //   return this.api.delete<Response>("/api/advertiser-divisions/" + adDivId + "/salesrep/" + repId);
  // }

  // public addCrmCompany(adDivId, payload): Observable<any> {
  //   return this.api.post<Response>("/api/advertiser-divisions/" + adDivId + "/crm-companies", payload);
  // }

  

  // public getAll(): Observable<any> {
  //   return this.api.get<Response>("/api/advertiser-divisions");
  // }

  // public create(payload): Observable<any> {
  //   return this.api.post<Response>("/api/advertiser-divisions", payload, true);
  // }

  // public update(id, payload, logoChanged?: boolean): Observable<any> {
  //   return this.api.post<Response>("/api/advertiser-divisions/" + id, payload, logoChanged);
  // }

  // public delete(id): Observable<any> {
  //   return this.api.delete<Response>("/api/advertiser-divisions/" + id);
  // }
  
}