import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Cross field Error macther - returns error when invalid control is dirty, touched, or submitted.
 */
export class ParentErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = !!(form && form.submitted);
    const controlTouched = !!(control && (control.dirty || control.touched));
    const controlInvalid = !!(control && control.invalid);
    const parentInvalid = !!(control && control.parent &&
                            control.parent.invalid &&
                            (control.parent.dirty || control.parent.touched));
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)

    if(isIEOrEdge && !control.value) {
      // just for IE when registration page loads
      return isSubmitted || (control.touched && (controlInvalid || parentInvalid));
    } else{
     return (controlTouched && (controlInvalid || parentInvalid));
    }

  }
}