/**
 * Model for Sales Rep
 */
export class SalesRep {

  constructor(
      id: number,
      email: string,
      phone: string,
      firstName: string,
      lastName: string,
      form?: any
  ) {
      this.id = id;
      this.email = email;
      this.phone = phone;
      this.firstName = firstName;
      this.lastName = lastName;
      if (form) this.form = form;
  }

  id: number = null;
  email: string = '';
  phone: string = '';
  firstName: string = '';
  lastName: string = '';
  form: any = '';
}