// src/app/services/advertiser/advertiser.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class AdvertiserService {
  constructor(public jwtHelper: JwtHelperService, private api: ApiService) {}

  public createAdvertiser(payload): Observable<any> {
    return this.api.post<Response>("/api/advertisers", payload, true);
  }

  public getAdvertisers(): Observable<any> {
    return this.api.get<Response>("/api/advertisers");
  }

}