import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-crm-company-main',
  templateUrl: './crm-company-main.component.html',
  styleUrls: ['./crm-company-main.component.scss']
})
export class CrmCompanyMainComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  crmCompanies: any[] = [];
  advertiserDivisionId: number = null;
  advertiserDivisionName: string = '';
  displayedColumns: string[] = ['company', 'mailCity', 'id', 'deleteAction'];

  constructor(private activatedRoute: ActivatedRoute, private advertiserDivisionService: AdvertiserDivisionService, private router: Router) { 
    this.advertiserDivisionId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.getAdvertiserDivision();
    this.getCrmCompanies();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Gets Advertiser Division
   * @return void
   */
  getAdvertiserDivision() {
    this._subscriptions.add(
      this.advertiserDivisionService.getOne(this.advertiserDivisionId).subscribe(response => {
        if (response.success && response.data && response.data[0]) {
          this.advertiserDivisionName = response.data[0].name;
        }
      })
    );
  }

  /**
   * Gets Crm Companies currently linked with this advertiser division
   * @return void
   */
  getCrmCompanies() {
    this._subscriptions.add(
      this.advertiserDivisionService.getCrmCompanies(this.advertiserDivisionId).subscribe(response => {
        this.crmCompanies = response.data;
      })
    );
  }

  /**
   * Removes crm company from advertiser division
   * @param number crmId
   * @param string name
   * @return void
   */
  removeCrmCompany(crmId: number, name: string) {

    const firstConfirm = confirm('Are you sure you want to remove CRM company "' + name + '" from Advertiser Division "' + this.advertiserDivisionName + '"?');

    if (firstConfirm) {

      const secondConfirm = confirm('Are you SUPER DUPER SURE you want to remove CRM company "' + name + '" from Advertiser Division "' + this.advertiserDivisionName + '"?');

      if (secondConfirm) {

        this._subscriptions.add(
          this.advertiserDivisionService.removeCrmCompany(this.advertiserDivisionId, crmId).subscribe(response => {
            if (response.success) this.getCrmCompanies();
          })
        );
      }
    }
  }

  /**
   * Navigates to add new crm company page
   * @return void
   */
  addCrmCompanies() {
    this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/crm-companies/new"]);
  }

  /**
   * Navigates to advertiser division list page
   * @return void
   */
  goBack() {
    this.router.navigate(["/advertiser-divisions"]);
  }

}
