import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { ReportService } from 'src/app/services/report/report.service';
import { Report } from 'src/app/models/report.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-advertiser-division-reports-new-edit',
  templateUrl: './advertiser-division-reports-new-edit.component.html',
  styleUrls: ['./advertiser-division-reports-new-edit.component.scss']
})
export class AdvertiserDivisionReportsNewEditComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  reportId: string = ''; 
  advertiserDivisionId: string = '';
  report: Report;


  constructor(private activatedRoute: ActivatedRoute, private router: Router, private reportService: ReportService, private builder: FormBuilder) { 
    // console.log('yoyoyo this.activatedRoute.snapshot.params... ', this.activatedRoute.snapshot.params);

    this._subscriptions.add(
      this.activatedRoute.url.subscribe(urlSegment => {
        if (urlSegment[1]) this.advertiserDivisionId = urlSegment[1].path;

        if (urlSegment[3]) {
          this.reportId = urlSegment[3].path === 'new' ? '' : urlSegment[3].path;
        }
      })
    );
  }

  ngOnInit() {
    // console.log('this.reportId... ', this.reportId);
    this.reportId ? this.getReport() : this.createNew();

  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Gets advertiser division
   */
  getReport() {
    this._subscriptions.add(

      this.reportService.getDetailedReport(this.reportId).subscribe(response => {
        if (response.success && response.data) {
          const report = response.data;
          report.form = this.buildReportForm(report);
          this.report = report;
          // const advertiserDivision = response.data[0];
          // advertiserDivision.form = this.buildAdvertiserForm(response.data[0]);
          // this.advertiserDivision = advertiserDivision;
        }
      })
    );
  }

  /**
   * 
   */
  createNew() {
    const newReport = new Report(-1, parseInt(this.advertiserDivisionId), '', '', false);
    newReport.form = this.buildReportForm(newReport);

    this.report = newReport;
  }

  prettifyString(string) {
    return this.getDateString(new Date(string));
  }

  getDateString(date) {
    const month = (date.getMonth() + 1).toString();
    const day = (date.getDate()).toString();
    const year = (date.getFullYear()).toString();
    return month + "-" + day + "-" + year;
  }

  backToReports() {
    this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/reports"]);
  }


  save(report: any) {

    const startDate = this.getDateString(report.form.get("startDate").value);
    const endDate = this.getDateString(report.form.get("endDate").value);

    let payload = {
      advertiserDivision_id: this.advertiserDivisionId,
      startDate: startDate,
      endDate: endDate
    };

    // New Report
    if (report.id === -1) {
      this._subscriptions.add(
        this.reportService.create(payload).subscribe(response => {
          if (response.success) {
            // this.getAdvertiserDivision();
            this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/reports"]);
          }
        })
      );
    // Editing existing report
    } else {
      this._subscriptions.add(
        this.reportService.update(report.id, payload).subscribe(response => {
          if (response.success) {
            // this.getAdvertiserDivision();
            this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/reports"]);
          }
        })
      );
    }
  }

  /**
   * If should show Save button
   */
  showSaveButton() {
    if (this.report.form.status === 'INVALID') return false;

    if (! this.report.form.get("startDate").value) return false;
    if (! this.report.form.get("endDate").value) return false;
    if (Date.parse(this.report.form.get("startDate").value) > Date.parse(this.report.form.get("endDate").value)){
       // Don't save if start date is after the end date
       return false;
    }

    // If EDITING
    if (this.reportId) {
      const newStart = this.report.form.get("startDate").value.getTime();
      const newEnd = this.report.form.get("endDate").value.getTime();
      // console.log('newStart... ', newStart);
      // const endDateChanged = this.report.endDate !== this.report.form.get("endDate").value;


      const originalStart = (new Date(this.report.startDate.date)).getTime();
      const originalEnd = (new Date(this.report.endDate.date)).getTime();
      // const originalStart = this.report.startDate;
      // console.log('originalStart... ', originalStart);

      // console.log('newStart === originalStart... ', newStart === originalStart);


      if ((originalStart !== newStart) || (originalEnd !== newEnd)) {
        return true;
      } else {
        return false;
      }
    // If NEW and get to this point in the code, they've already passed basic validation
    } else {
      return true;
    }

    

    // console.log('this.report.startDate... ', this.report.startDate);
    // console.log('this.report.form.get("startDate").value... ', this.report.form.get("startDate").value);

    // console.log('newStart... ', newStart);
    // console.log('endDateChanged... ', endDateChanged);

    // return true;

    // if (!this.reportId) {
    //   if (this.report.form.get('startDate').value && this.report.form.get('name').value {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {

    //   const nameChanged = this.report.name !== this.report.form.get("name").value;

    //   if (nameChanged && !this.report.form.get("name").value.trim()) return false;

    //   const logoChanged = typeof this.report.form.get("startDate").value === 'object';

    //   return (!nameChanged && !logoChanged) ? false : true;
    // }
  }

  buildReportForm(report: Report) {
    const form = {
        startDate: [
          (new Date(report.startDate.date)),//.trim(),
          [
            Validators.required
          ]
        ],
        endDate: [
          (new Date(report.endDate.date)),//.trim(),
          [
            Validators.required
          ]
        ]

    };

    return this.builder.group(form);

  }

}
