import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { LoginMainComponent } from './pages/login/login-main/login-main.component';
import { HomeMainComponent } from './pages/home/home-main/home-main.component';
import { UserMainComponent } from './pages/user/user-main/user-main.component';
import { AdminNewEditComponent } from './pages/user/admin-new-edit/admin-new-edit.component';
import { SalesrepNewEditComponent } from './pages/user/salesrep-new-edit/salesrep-new-edit.component';
import { CfeMainComponent } from './pages/cfe/cfe-main/cfe-main.component';
import { AdvertiserMainComponent } from './pages/advertiser/advertiser-main/advertiser-main.component';
import { EbookMainComponent } from './pages/ebook/ebook-main/ebook-main.component';
import { EbookAssignComponent } from './pages/ebook/ebook-assign/ebook-assign.component';
import { EbookStatsListComponent } from './pages/ebook/ebook-stats-list/ebook-stats-list.component';
import { EbookStatsNewEditComponent } from './pages/ebook/ebook-stats-new-edit/ebook-stats-new-edit.component';
import { AdvertiserDivisionReportsComponent } from './pages/advertiser-division/advertiser-division-reports/advertiser-division-reports.component';
import { AdvertiserDivisionNewEditComponent } from './pages/advertiser-division/advertiser-division-new-edit/advertiser-division-new-edit.component';
import { ReportPreviewComponent } from './pages/report/pdf/report-preview/report-preview.component';
import { BrandMainComponent } from './pages/brand/brand-main/brand-main.component';
import { TestMainComponent } from './pages/test/test-main/test-main.component';
import { BrandNewEditComponent } from './pages/brand/brand-new-edit/brand-new-edit.component';
import { AdvertiserDivisionMainComponent } from './pages/advertiser-division/advertiser-division-main/advertiser-division-main.component';
import { CrmCompanyMainComponent } from './pages/crm-company/crm-company-main/crm-company-main.component';
import { CrmCompanyNewComponent } from './pages/crm-company/crm-company-new/crm-company-new.component';
import { GenerateReportMainComponent } from './pages/report/generate-report-main/generate-report-main.component';
import { AdvertiserDivisionReportsNewEditComponent } from './pages/advertiser-division/advertiser-division-reports-new-edit/advertiser-division-reports-new-edit.component';
import { SalesRepsComponent } from './pages/advertiser-division/sales-reps/sales-reps.component';
import { SalesRepsNewComponent } from './pages/advertiser-division/sales-reps-new/sales-reps-new.component';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { BannerUpdateMainComponent } from './pages/banner-update/banner-update-main/banner-update-main.component';
import { DownloadComponent } from './pages/report/pdf/download/download.component';

const routes: Routes = [
	{ path: 'login', component: LoginMainComponent },
	{ path: 'download/:guid', component: DownloadComponent },

	{ path: '', component: HomeMainComponent, canActivate: [AuthGuard] },
	{ path: 'home', component: HomeMainComponent, canActivate: [AuthGuard] },
	{ path: 'users', component: UserMainComponent, canActivate: [AuthGuard] },
	{ path: 'admin/:id/edit', component: AdminNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'admin/new', component: AdminNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'salesrep/:id/edit', component: SalesrepNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'salesrep/new', component: SalesrepNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'brands', component: BrandMainComponent, canActivate: [AuthGuard] },
	{ path: 'brands/:id/edit', component: BrandNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'brands/new', component: BrandNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'cfe', component: CfeMainComponent, canActivate: [AuthGuard] },
	{ path: 'generate-report', component: GenerateReportMainComponent, canActivate: [AuthGuard] },
	{ path: 'advertisers', component: AdvertiserMainComponent, canActivate: [AuthGuard] },
	{ path: 'ebooks', component: EbookMainComponent, canActivate: [AuthGuard] },
	{ path: 'ebooks/:ebookInstanceId/assign', component: EbookAssignComponent, canActivate: [AuthGuard] },
	{ path: 'ebooks/:ebookInstanceId/stats-list', component: EbookStatsListComponent, canActivate: [AuthGuard] },
	{ path: 'ebooks/:ebookInstanceId/stats-list/new', component: EbookStatsNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'ebooks/:ebookInstanceId/stats-list/:performanceId/edit', component: EbookStatsNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'advertiser-divisions', component: AdvertiserDivisionMainComponent, canActivate: [AuthGuard] },
	{ path: 'advertiser-divisions/:id/edit', component: AdvertiserDivisionNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'advertiser-divisions/new', component: AdvertiserDivisionNewEditComponent, canActivate: [AuthGuard] },
	{ path: "advertiser-divisions/:id/salesrep", component: SalesRepsComponent, canActivate: [AuthGuard] },
	{ path: "advertiser-divisions/:id/salesrep/new", component: SalesRepsNewComponent, canActivate: [AuthGuard] },
	{ path: "advertiser-divisions/:id/reports", component: AdvertiserDivisionReportsComponent, canActivate: [AuthGuard] },
	// { path: "advertiser-divisions/:id/reports/:id/preview", component: ReportPreviewComponent, canActivate: [AuthGuard] },
	{ path: 'advertiser-divisions/:id/crm-companies', component: CrmCompanyMainComponent, canActivate: [AuthGuard] },
	{ path: 'advertiser-divisions/:id/crm-companies/new', component: CrmCompanyNewComponent, canActivate: [AuthGuard] },
	{ path: 'web-ad-banner/update', component: BannerUpdateMainComponent, canActivate: [AuthGuard] },
	{ path: 'generate-report/report-preview', component: ReportPreviewComponent, canActivate: [AuthGuard] },
	// { path: "advertiser-divisions/:id/reports/:id/edit", component: AdvertiserDivisionReportsNewEditComponent, canActivate: [AuthGuard] },
	// { path: "advertiser-divisions/:id/reports/new", component: AdvertiserDivisionReportsNewEditComponent, canActivate: [AuthGuard] },
	{ path: 'test', component: TestMainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
