import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

/**
 * Wrapper for http client
 *
 * Adds headers and error handlers to all requests
 */
@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Handles GET requests
   *
   * @param url request url
   * @return request observable
   */
  get<T>(url: string): Observable<T> {
    // url = this._decorateUrl(url);
    const httpOptions = this._getHttpOptions();
    const req = this.http.get<T>(url, httpOptions);
    return this._decoratedRequest(req);
  }

  /**
   * Handles POST requests
   *
   * @param url request url
   * @param object data to send with request
   * @param boolean if sending form data
   * @return request observable
   */
  post<T>(url: string, object: any, formData?: boolean): Observable<T> {
    // url = this._decorateUrl(url);
    const httpOptions = formData ? this._getHttpOptionsWithoutContentType() : this._getHttpOptions();
    const req = this.http.post<T>(url, object, httpOptions);
    return this._decoratedRequest(req);
  }

  /**
   * Handles PUT requests
   *
   * @param url request url
   * @param object data to send with request
   * @return request observable
   */
  put<T>(url: string, object: any, formData?: boolean): Observable<T> {
    // url = this._decorateUrl(url);
    const httpOptions = formData ? this._getHttpOptionsWithoutContentType() : this._getHttpOptions();
    const req = this.http.put<T>(url, object, httpOptions);
    return this._decoratedRequest(req);
  }

  /**
   * Handles DELETE requests
   *
   * @param url request url
   * @return request observable
   */
  delete<T>(url: string): Observable<T> {
    // url = this._decorateUrl(url);
    const httpOptions = this._getHttpOptions();
    const req = this.http.delete<T>(url, httpOptions);
    return this._decoratedRequest(req);
  }

  // *
  //  * Populates url parameters
  //  *
  //  * Urls are defined in routes constant.
  //  * All placeholders in urls are replaced with actual values to create full url.
  //  * Placeholders are replaced with values from the same index in values array.
  //  * Baseurl is prepended to url after placeholder replacement.
  //  *
  //  * example:
  //  *  url: "/manufacturer/{mid}/product/{pid}"
  //  *  values: [1444, 23141]
  //  *
  //  *  return "http://baseurl.com/manufacturer/1444/product/23141"
  //  *
  //  * @param url url with placeholders
  //  * @param values values to populate placeholders - optional param, will be an empty [] if nothing is specified
  //  * @param isCSX  tells the function whether or not to use baseURL for gsp or csx - optional param, will be false if nothing is specified
  //  * @return populated urls
   
  // generateUrlFromMap( url: string, values?: any[] ): URL {
  //   values = values || [];
  //   const keyArray = url.match( /{.*?}/g ) || [];

  //   if (keyArray.length !== values.length) {
  //     throw new RangeError("Invaid values list for url");
  //   }

  //   for (let index in keyArray) {
  //     url = url.replace(keyArray[index], values[index]);
  //   }
  //   return new URL(url, environment.baseUrl);
  // }

  /**
   * Gets authorization header for request
   *
   * If user is logged in the header will contain their access token, otherwise
   * an anonymous token will be used.
   *
   * @return authorization header
   */
  private _getAuthHeader(): string {
    // const token = this.tokenService.getToken();
    // return token ?
    //   // authenticated request
    //   token.token_type.substr(0,1).toUpperCase() + token.token_type.substr(1) + " " + token.access_token :
    //   // anonymous request
    //   environment.baseAuth;

      const token = localStorage.getItem('token');

      if (token) {
        return "Bearer " + token;

      } else {
        return environment.baseAuth;
      }

      // return token ? token : environment.baseAuth;

      // return environment.baseAuth;
  }

  /**
   * Adds operators to http request
   *
   * Global error handlers and any request modifcations should be applied here
   *
   * @param req http request
   * @return request observable with operators
   */
  private _decoratedRequest<T>(req: Observable<T>): Observable<T> {
    return req;
    // TODO implement global error handlers here
    // return req.pipe<T, T, T>(
    //   retry(1),
    //   catchError((err, caught) => throwError("crap"))
    // );
  }

  /**
   * Modifies url before sending request
   *
   * @param url url for request
   * @return modified url
   */
  private _decorateUrl(url: URL): URL {
    // Adds a cache buster to all requests
    url.searchParams.append("_", Date.now().toString());

    return url;
  }

  /**
   * Creates options for all http requests
   *
   * @see https://angular.io/api/common/http/HttpClient#description
   *
   * @return options for all http requests
   */
  private _getHttpOptions(): Object {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Authorization": this._getAuthHeader()
      })
    };
  }

  private _getHttpOptionsWithoutContentType(): Object {
    return {
      headers: new HttpHeaders({
        "Authorization": this._getAuthHeader()
      })
    };
  }
}
