import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild, Inject } from '@angular/core';
import { Subscription } from "rxjs";
import { EbookService } from 'src/app/services/ebook/ebook.service';
import { AdvertiserDivision } from 'src/app/models/advertiser-division.model';
import { Ebook } from 'src/app/models/ebook.model';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';

import {MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-ebook-main',
  templateUrl: './ebook-main.component.html',
  styleUrls: ['./ebook-main.component.scss']
})
export class EbookMainComponent implements OnInit {
    advertiserDivisions: AdvertiserDivision[] = [];
    ebooks: Ebook[] = [];
    loading: boolean = false;
    keywords: any = '';
    startDate: any;
    startDateString: any;
    endDate: any;
    endDateString: any;
    timer: any;
    defaultSearchDelay: number = 800;
    defaultLimitPerPage: number = 24;
    totalResults: number;
    currentOffset: number;
    newResultsPerPage: number;
    newOffset: number;
    currentResultsPerPage: number;
    pageNumbers: number[]; // Array of pages used in pagination dropdown (i.e. [1, 2, 3, 4])
    selectedPage: string; // The selected value in the pagination dropdown
    sortByEbookNameAsc: boolean = true;
    ebookInstanceIdClicked: string;
    advertiserEbooks: any = [];

    @ViewChild(MatAccordion, {static: false}) accordion: MatAccordion;

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    @ViewChild('searchInput', {static: false}) searchInput: any;

    private _subscriptions = new Subscription();
    uploadOpen: boolean = false;

    constructor(private router: Router, private ebookService: EbookService) { }

    ngOnInit() {
      this.getEbookInstances();
    }

    /**
     * Clears keywords and gets new search without keywords
     * @return void
     */
    clearSearch() {
      if (!this.keywords) return;

      const that = this;

      this.keywords = '';
      setTimeout(() => {
        that.getEbookInstances();
        that.searchInput.nativeElement.focus();
      }, 0);
    }

    /**
     * Returns date string
     * @param date
     * @return string
     */
    getDateString(date) {
      const year = (date.getFullYear()).toString();
      const month = (date.getMonth() + 1).toString();
      const day = (date.getDate()).toString();
      return year + "-" + month + "-" + day;
    }

    /**
     * Clears start or end date
     * @param string type ('startDate' || 'endDate') 
     * @return void
     */
    clearDate(type: string) {
      if (!this[type]) return;

      this[type] = null; 
      this.changeDate(type);
    }

    /**
     * Triggered when user changes date
     * Sets startDateString or endDateString and calls getEbookInstancesWithDelay()
     * @param string type ('startDate' || 'endDate') 
     * @return void
     */
    changeDate(type: string) {
      this[type + 'String'] = this[type] ? this.getDateString(this[type]) : '';

      this.getEbookInstancesWithDelay();
    }

    /**
     * Returns total number of page results
     * @returns number
     */
    getTotalPages() {
      let totalPages = Math.ceil(this.totalResults / this.currentResultsPerPage);
      return Math.ceil(this.totalResults / this.currentResultsPerPage);
    }

    /**
     * Returns a string of the "current page / total pages" used in the pagination dropdown
     * Ex. "5/10"
     * @returns string
     */
    getPageLabel() {
      return ((this.currentOffset / this.currentResultsPerPage) + 1).toString() + '/' + this.getTotalPages().toString();
    }

    /**
     * Calls getEbookInstances() with default search delay
     * @returns void
     */
    getEbookInstancesWithDelay() {
      this._clearTimeOut();

      const that = this;

      this.timer = setTimeout(function(){
        that.getEbookInstances();
      }, that.defaultSearchDelay);
    }

    /**
     * Gets and sets ebooks by payload
     * @returns void
     */
    getEbookInstances() {
      this.loading = true;

      let payload = {
        offset: this.newOffset || 0,
        limit: this.newResultsPerPage || this.defaultLimitPerPage,
        keywords: this.keywords || '',
        startDate: (this.startDateString || ''),
        endDate: (this.endDateString || ''),
        sortByEbookName: this.sortByEbookNameAsc ? 'ASC' : 'DESC'
      };

      this._subscriptions.add(
        this.ebookService.getEbookInstances(payload).subscribe(response => {

          if (response.success && response.data) {
            this.ebooks = response.data.ebooks;
            this.totalResults = response.data.total;
            this.currentOffset = response.data.offset;
            this.newOffset = null;
            this.currentResultsPerPage = response.data.limit;
            this.generatePageNumbers();
          }

          this.loading = false;
        })
      );
    }

    /**
     * Triggered when user changes eBook name filter
     * Flips sortByEbookNameAsc
     * Calls getEbookInstances()
     * @returns void
     */
    ebookNameSortClicked() {
      this.sortByEbookNameAsc = !this.sortByEbookNameAsc;
      this.getEbookInstances();
    }

    /**
     * Resets advertiserEbooks, sets which ebookInstanceId was clicked,
     * and calls getAdvertiserEbooks() 
     * @param string ebookInstanceId
     * @returns void
     */
    accordionClicked(ebookInstanceId: string) {
      if (this.ebookInstanceIdClicked == ebookInstanceId) return;
      this.advertiserEbooks = [];
      this.ebookInstanceIdClicked = ebookInstanceId;
      this.getAdvertiserEbooks(ebookInstanceId);
    }

    /**
     * Calls getAdvertiserEbooksByEbookInstanceId() and sets advertiserEbooks
     * @param string ebookInstanceId
     * @returns void
     */
    getAdvertiserEbooks(ebookInstanceId: string) {
      this._subscriptions.add(
        this.ebookService.getAdvertiserEbooksByEbookInstanceId(ebookInstanceId).subscribe(response => {
          if (response.success && response.data) {
            this.advertiserEbooks = response.data;
          }
        })
      );
    }

    /**
     * Called when user clicks on "24" | "48" items per page
     * Do nothing if clicking on current pageSize, otherwise paginate
     * @param event any
     */
    changeItemsPerPage(event: any) {
      if (event.pageSize === this.currentResultsPerPage) return;
      this.paginate(event);
    }

    /**
     * Runs new search with updated pagination preference
     *
     * @param event PageEvent
     */
    paginate(event: PageEvent ) {
      this.loading = true;

      // User has 2 pagination options: change # of results-per-page,
      // or change which page to view
      if (event.pageSize != this.currentResultsPerPage) {
        // Scroll to top of page -- this one needs a timeout
        setTimeout(function() {window.scrollTo(0, 0);}, 0);
        // If user changed # of results-per-page, update query with desired
        // results-per-page and go back to first page
        this.newResultsPerPage = event.pageSize;
        this.newOffset = 0;
      } else {
        // Scroll to top of page
        window.scrollTo(0, 0);
        // If user changed which page to view, update query with desired
        // page and keep results-per-page the same as before
        let newOffset = event.pageIndex * event.pageSize;
        
        this.newOffset = newOffset;
      }

      this.getEbookInstances();
    }

    /**
     * Allows user to go to a certain pagination number
     *
     * @param event: any
     */
    gotoPage(event: any) {
      this.selectedPage = ''; // We never actually show the pagination page number
      // selection, since we show a custom label instead

      const desiredPage = event.value - 1;
      const currentPage = this.currentOffset / this.currentResultsPerPage;

      if (desiredPage === currentPage) return;

      // Scroll to top of page -- this one needs a timeout
      setTimeout(function() {window.scrollTo(0, 0);}, 0);

      this.paginator.pageIndex = desiredPage,
        this.paginator.page.next({
          pageIndex: desiredPage,
          pageSize: this.paginator.pageSize,
          length: this.paginator.length
      });
    }

    /**
     * Creates an array based on total number of pages
     * Used in the pagination dropdown
     * Ex. If there are 5 total pages, pageNumbers array is [1, 2, 3, 4, 5]
     */
    generatePageNumbers() {
      this.pageNumbers = Array.from(Array(this.getTotalPages()),(x,i)=>i+1);
    }

    /**
       * Accepts date string and returns formatted date string
       * @param string date
       * @return string
       */
      toDateString(date: string) {
        const dateObj = new Date(date);
        const month = dateObj.getUTCMonth() + 1; //months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();

        return month + "-" + day + "-" + year;
      }

    /**
     * Navigates to home page
     * @returns void
     */
    goHome() {
      this.router.navigate(["/home"]);
    }
    /**
     * Opens ebook spreadsheet upload menu
     * @returns void
     */
    uploadMenuOpen() {
      this.uploadOpen = !this.uploadOpen ;
    }

    

    /**
     * Navigates to ebook assign page
     * @returns void
     */
    gotoAssign(id: any) {
      this.router.navigate(["/ebooks/" + id + "/assign"]);
    }

    /**
     * Navigates to ebook stats list page
     * @returns void
     */
    gotoStats(id: any) {
      this.router.navigate(["/ebooks/" + id + "/stats-list"]);
    }

    /**
     * Clears timeout if exists
     */
    private _clearTimeOut() {
      if (this.timer || this.timer === 0) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    }

    /**
     * Unsubscribes to subscriptions
     * @returns void
     */
    ngOnDestroy(): void {
      this._subscriptions.unsubscribe();
    }

}
