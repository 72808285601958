import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from "rxjs";
import { ReportService } from 'src/app/services/report/report.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatCheckboxModule} from '@angular/material/checkbox';

import { PageEvent, MatPaginator } from '@angular/material/paginator';

import {FormControl, FormArray, FormGroup, FormBuilder} from '@angular/forms';
import { AdvertiserDivision } from 'src/app/models/advertiser-division.model';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-generate-report-main',
  templateUrl: './generate-report-main.component.html',
  styleUrls: ['./generate-report-main.component.scss']
})
export class GenerateReportMainComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  reportId: string = ''; 
  advertiserDivisionId: string = '';
  error: string = '';
  loading: boolean = false;
  inputError: boolean = false;
  overMaxRangeError: boolean = false;
  overSuggestedRangeWarning: boolean = false;
  startDate: any;
  endDate: any;
  airPdfSelected: boolean = false;
  airSpreadsheetSelected: boolean = false;
  seoSpreadsheetSelected: boolean = false;
  benchmarkSpreadsheetSelected: boolean = false;
  allAdvertiserDivisions: any = [];
  seoReportNotReady: boolean = false;
  oldSeoReport: boolean = false;
  queryEmptyError: boolean = true;
  gotTopics: boolean = false;
  
  // variables related to advertiserDivision Autocomplete display
  divisionControl = new FormControl('');
  shownDivisions: Array<AdvertiserDivision>;
  divisionEntry: string;


  /*  Both of these ViewChilds are referencing the same <input> from the autocomplete.
      divisionTrigger give access to the mat-autocomplete component's openPanel() method to open the panel, 
      while divisionInput gives access to the <input> field to focus().
  */
  @ViewChild('divisionTrigger', { read: MatAutocompleteTrigger }) divisionTrigger: MatAutocompleteTrigger;
  @ViewChild('divisionInput') divisionInput: any;
  
  @ViewChild('selectProducts') selectProducts: any;
  @ViewChild('selectTopics') selectTopics: any;


  reportOptions: any[] = [
    {value: 'air-pdf', viewValue: 'AIR PDF'}, 
    {value: 'air-spreadsheet', viewValue: 'AIR Spreadsheet'},
    {value: 'seo-spreadsheet', viewValue: 'SEO Spreadsheet'},
    {value: 'benchmark-spreadsheet', viewValue: 'Average Performance Spreadsheet'}
  ];
  
  productOptions: any[] = [
    {id: 'advertiser_newsletter', name: 'Newsletter'},
    {id: 'advertiser_ebook', name: 'eBook'},
    {id: 'advertiser_newsletter_ad', name: 'Newsletter Ad'},
    {id: 'advertiser_own_the_topic', name: 'Own the Topic'},
    {id: 'advertiser_web_banner', name: 'Web Banner'},
    {id: 'advertiser_webcast', name: 'Webcast'}
  ];
  productKeys = []; 

  topicOptions = [];
  topicForm: FormGroup;
  formGroupBenchmark: FormGroup = this.fb.group([]);
   formBuilder: FormBuilder;

  topicKeys = [];
  allProductsSelected: boolean = true;
  allTopicsSelected: boolean = true;
  productCount: number = 0;
  topicCount: number = 0;
  constructor(private router: Router, private reportService: ReportService, private fb: FormBuilder) {}

  /**
   * Calls setUpChart()
   * Makes call for advertiserDivisions.
   * Then initializes listening for value changes to the Advertiser Division. 
   * If there is a value in the field (x), filter the list of 
   */
  ngOnInit() { 
    this.buildProductsField();
    this.getBenchmarkTopics();
    this.getAllAdvertiserDivisions();
    this.formGroupBenchmark.controls.productFilter.patchValue(this.productKeys);
    this.productCount = this.productOptions.length;


    this.divisionControl.valueChanges.subscribe(x => {
      if (x && typeof x != 'object') {
        this.shownDivisions = this.allAdvertiserDivisions.filter(
          division => division.name.toLowerCase().includes(x.toLowerCase())
        );
      } else {
        this.shownDivisions = this.allAdvertiserDivisions;
      }
    });
    
  }
  ngAfterViewInit() {

  }
  /**
   * Closes Month Start datepicker and sets startDate
   */
  closeDatePickerStart(event, picker) {
    this.startDate = event;
    picker.close();
  }

  /**
   * Closes Month End datepicker and sets endDate to 
   * last day of chosen month
   */
  closeDatePickerEnd(event, picker) {
    const endDate = new Date(event);
    const year = endDate.getFullYear();
    const month = endDate.getMonth();

    const finalEndDate = new Date(year, month + 1, 0);

    this.endDate = finalEndDate;
    picker.close();
  }

  /**
   * Gets and sets all advertiser divisions. Also creates copy in shownDivision, which is filtered down later.
   * @return void
   */
  getAllAdvertiserDivisions() {
    this._subscriptions.add(
      this.reportService.getAllAdvertiserDivisions().subscribe(response => {
        this.allAdvertiserDivisions = response.data;
        this.shownDivisions = response.data;
        
      })
    );
  }
  

  /** Called on click of the X clear button.
   * Stops propagation of blur/focus events when clicking clear, resets input's values, focuses input, opens Autocomplete panel.
   * this.divisionControl is the FormControl object and is used to reset the input's value.
   * Both divisionInput and divisionTrigger are references to the same <input> element.
   * this.divisionInput is an untyped reference to the <input>'s nativeElement property, giving us access to the focus() method.
   * this.divisionTrigger is a typed reference to the MatAutocomplete component, giving access to its openPanel() method.
   * @param event event object, used to call stopPropagation and prevent Angular Material's default focus/blur events.
   */
  clearAdvertiserDivision(event) {
    event.stopPropagation();
    this.advertiserDivisionId = '';
    this.divisionControl.reset(''); 
    this.divisionInput.nativeElement.focus();
    this.divisionTrigger.openPanel();
    this.queryEmptyError = true;
  } 
  /**
   * Displays the name of an advertiser in the autocomplete
   * @param division - an Advertiser Division object from the iteration in an ngFor
   * @returns string - Advertiser Division's name
   */
  divisionDisplay(division: AdvertiserDivision) {
    return division && division.name ? division.name : '';
  }

  
  /**
   * Clears start or end date
   * @param string type ('startDate' || 'endDate') 
   * @return void
   */
  clearDate(type: string) {
    if (this.loading || !this[type]) return;
    this.inputError = false;
    this.overMaxRangeError = false;
    this.overSuggestedRangeWarning = false;
    this.error = '';
    this[type] = null; 
  }

  /**
   * Accepts Date object and returns formatted date string
   * @param Date date
   * @return string
   */
  toDateString(date: Date) {
    return date.toISOString().split('T')[0];
  }

  // Comment out this function once review is done
  // processSeoSpreadsheet() {
  //   this.loading = true;

  //   this._subscriptions.add(
  //     this.reportService.createSeoReport().subscribe(response => {

  //       console.log('processed... ', response);

  //        if (response.success) {
  //          this.downloadFile(response.data);
  //        } else {
  //          this.error = "Error: " + response.error_code;
  //        }

  //        this.loading = false;
  //     })
  //   );
  // }

  /**
   * Fetches and downloads SEO report; if the report is older, displays an indicating message
   * If does not exist, displays message
   * @return void
   */
  downloadSeoSpreadsheet() {
    this.loading = true;
    this.seoReportNotReady = false;
    this.oldSeoReport = false;
    this._subscriptions.add(
      this.reportService.getSeoReport().subscribe(response => {

        if (response.data && response.data.dateCreated) {

          const today = new Date();
          const dateCreated = new Date(response.data.dateCreated);
          const dateCreatedLastDayOfMonth = new Date(dateCreated.getFullYear(), (dateCreated.getMonth() + 1), 0);

          this.oldSeoReport = today > dateCreatedLastDayOfMonth;

          this.downloadFile(response.data);
        } else {
          this.seoReportNotReady = true;
        }

        this.loading = false;
      })
    );
  }

  /**
   * Creates AIR spreadsheet report based on user input for dates
   * @return void
   */
  downloadAirSpreadsheet() {
    this.loading = true;

    const payload = {
      startDate: this.toDateString(this.startDate),
      endDate: this.toDateString(this.endDate)
    };

    this._subscriptions.add(
      this.reportService.createReportForAllAdvertisers(payload).subscribe(response => {

        if (response.success) {
          this.downloadFile(response.data);
        } else {
          this.error = "Error: " + response.error_code;
        }

        this.loading = false;
      })
    );
  }
  /**
   * Activates the download of the benchmark spreadsheet
   */
  downloadBenchmarkSpreadsheet() {
    
    let productData = this.formGroupBenchmark.value.productFilter;
    let topicData = this.formGroupBenchmark.value.topicFilter;
    const payload = {
      startDate: this.toDateString(this.startDate),
      endDate: this.toDateString(this.endDate),
      products: productData,
      topics: topicData,
      advertiserDivisionId: this.advertiserDivisionId
    };
    this._subscriptions.add( 
      this.reportService.createBenchmarkSpreadsheet(payload).subscribe(response => {
        if (response.success) {
          this.queryEmptyError = response.data.queryResultsValid;
          if (this.queryEmptyError == true) {
            this.downloadFile(response.data)
          };
          

        } else {
          // this.error = "Error: " + response.error_code;
        }
      })
    );

    this.formGroupBenchmark.controls.productFilter.enable();
    this.formGroupBenchmark.controls.topicFilter.enable();
  }
  /**
   * Sets queryParams, opens new window, goes to pdf report preview with 
   * queryParams in URL
   * @return void
   */
  goToPreview() {

    const queryParams = {
      advertiserDivisionId: this.advertiserDivisionId, 
      startDate: this.toDateString(this.startDate), 
      endDate: this.toDateString(this.endDate)
    };

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/app/generate-report/report-preview'], {
        queryParams: queryParams
      })
    );

    window.open(url, '_blank');
  } 

  /**
   * Downloads file
   * @param Object data
   */
  downloadFile(data) {
    const anchor = document.createElement('a');
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('href', data.link);
    anchor.setAttribute('download', data.fileName);
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
  }

  /**
   * If should show action button
   * @return boolean
   */
  showActionButton() {
    if (this.seoSpreadsheetSelected) {
      this.inputError = false;
      this.overMaxRangeError = false;
      this.overSuggestedRangeWarning = false;
      return true;
    } else {
      if (!this.gotTopics) return false;
      if (!this.startDate) return false;
      if (!this.endDate) return false;
      if (Date.parse(this.startDate) > Date.parse(this.endDate)){
        this.overMaxRangeError = false;
        this.overSuggestedRangeWarning = false;
        this.inputError = true;
         // Don't save if start date is after the end date
         return false;
      } else {

        this.inputError = false;

        let currentRange = (this.endDate.getTime() / 1000) - (this.startDate.getTime() / 1000);
        // If current range greater than twelve months, prohibit proceeding
        if (currentRange > 31536000 && this.benchmarkSpreadsheetSelected == false ) {
          this.overMaxRangeError = true;
          this.overSuggestedRangeWarning = false;
          return false;
        // Else if current range is greater than three months, show overSuggestedRangeWarning 
        // but allow to proceed
        } else if (currentRange > 7948800) {
          this.overSuggestedRangeWarning = this.airPdfSelected ? true : false;
          this.overMaxRangeError = false;
        } else {
          this.overMaxRangeError = false;
          this.overSuggestedRangeWarning = false;
        }
      }

      this.error = '';
      if (this.formGroupBenchmark.value.productFilter.length < 1 && this.benchmarkSpreadsheetSelected == true) {
        return false;
      }
      if (this.airPdfSelected) {
        return this.advertiserDivisionId ? true : false;
      } else {
        if (this.airSpreadsheetSelected || this.benchmarkSpreadsheetSelected) return true;
      }

      return false;

    }
  }
  /**
   * Handles what happens when the user clicks the action button
   */
  doSubmitAction(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.airPdfSelected) {
      this.goToPreview();
    } else if (this.airSpreadsheetSelected) {
      this.downloadAirSpreadsheet();
    } else if (this.seoSpreadsheetSelected) {
      this.downloadSeoSpreadsheet();
    } else if (this.benchmarkSpreadsheetSelected) {
      this.downloadBenchmarkSpreadsheet();
    }
    // airPdfSelected ? goToPreview() : (airSpreadsheetSelected ? downloadAirSpreadsheet() : downloadSeoSpreadsheet())
  }
  /**
   * Navigates home
   * @return void
   */
  goHome() {
    this.router.navigate(["/home"]);
  }

  /**
   * Updates selected report format
   * @param any event
   * @return void
   */
  updateReportFormat(event: any) {
    this.seoReportNotReady = false;
    this.oldSeoReport = false;

    if (event.value === 'air-pdf') {
      this.airPdfSelected = true;
      this.airSpreadsheetSelected = false;
      this.benchmarkSpreadsheetSelected = false;
      this.seoSpreadsheetSelected = false;
    } 

    if (event.value === 'air-spreadsheet') {
      this.airSpreadsheetSelected = true;
      this.airPdfSelected = false;
      this.benchmarkSpreadsheetSelected = false;
      this.seoSpreadsheetSelected = false;
    }

    if (event.value === 'seo-spreadsheet') {
      this.seoSpreadsheetSelected = true;
      this.airSpreadsheetSelected = false;
      this.airPdfSelected = false;
      this.benchmarkSpreadsheetSelected = false;

    }
    if (event.value === 'benchmark-spreadsheet') {
      this.seoSpreadsheetSelected = false;
      this.airSpreadsheetSelected = false;
      this.airPdfSelected = false;
      this.benchmarkSpreadsheetSelected = true;
    }
  }

  /**
   * Called by optionSelected event on mat-autocomplete component. Sets selected advertiser division id
   * @param any event
   * @return void
   */
  setAdDivId(event: any) {
    this.advertiserDivisionId = event.option.value.id;
  }

  /**
   * Unsubscribes to subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Calls the benchmark service
   */
  getBenchmarkTopics() {
    this._subscriptions.add(
      this.reportService.getBenchmarkTopics().subscribe(response => {
       
        if (response.data) {
          let topicQuery = response.data;
          this.topicOptions = [].concat(topicQuery).map(x => {
            x.id = Number.isInteger(x.id) ? "topic_id_" + x.id : x.id;
            return x;
          });
          this.topicCount = this.topicOptions.length;
          this.gotTopics = true;
          this.buildTopicsField();

        } else {
          console.log("Couldn't get topics");
        }
      })
    );
  }
  /** 
   * Assembles the formGroupBenchmark object's product control
   * Called on ngInit
   */
  buildProductsField() {

    this.productKeys = ['all_products'].concat(this.productOptions.map(x => {
      return x.id;
    }));
    this.formGroupBenchmark .addControl("productFilter", new FormControl({value: this.productKeys, disabled:false}));
    
  }
  /** 
   * Assembles the formGroupBenchmark object's product control.
   * Called in getBenchmarkTopics after successful topic retrieval.
   */
  buildTopicsField() {
    this.topicKeys = ['all_topics'].concat(this.topicOptions.map(x => {
      return x.id;
    }));
    this.formGroupBenchmark.addControl("topicFilter", new FormControl({value: this.topicKeys, disabled:false}) );
    this.formGroupBenchmark.controls.topicFilter.patchValue(this.topicKeys);
  }

  /**
   * Used to select/deselect all product options at once
   * @param event 
   */
  selectAllProducts(event) {
    let allSelected = this.formGroupBenchmark.value.productFilter.includes("all_products");
    this.formGroupBenchmark.controls.productFilter.patchValue(
      allSelected == true ? this.productKeys : []
    );
    this.allProductsSelected = allSelected;
    this
  }

  /**
   * Used to select/deselect all topic options at once
   * @param event 
   */
  selectAllTopics() {
    let allSelected = this.formGroupBenchmark.value.topicFilter.includes("all_topics");
    this.formGroupBenchmark.controls.topicFilter.patchValue(
      allSelected == true ? this.topicKeys : []
    );
    this.allTopicsSelected = allSelected;

  }
  /**
   * Handling the other clicks on the products field - if the All Products box is checked and the user deselects another option, also deselects "All Products"
   * On the inverse, if all products are selected but the All box is NOT selected, will select and display All accordingly.
   */
  multiselectProductClick() {
    if (this.formGroupBenchmark.value.productFilter.includes('all_products') ) {
      this.allProductsSelected = false;
      let allSpot = this.formGroupBenchmark.value.productFilter.indexOf('all_products');
      let tempvalue = [...this.formGroupBenchmark.value.productFilter];
      tempvalue.splice(allSpot,1);
      this.formGroupBenchmark.controls.productFilter.patchValue(tempvalue)
    }
    else if (this.formGroupBenchmark.value.productFilter.length == this.productCount) {
      let tempvalue = [...this.formGroupBenchmark.value.productFilter];
      tempvalue.push("all_products");
      this.formGroupBenchmark.controls.productFilter.patchValue(tempvalue)
      this.allProductsSelected = true;
      }
  }

  /**
   * Handling the other clicks on the products field - if the All Topics box is checked and the user deselects another option, also deselects "All Topics"
   * On the inverse, if all products are selected but the All box is NOT selected, will select and display All accordingly.
   */   
  multiselectTopicClick() {
    if (this.formGroupBenchmark.value.topicFilter.includes('all_topics') ) {
      this.allTopicsSelected = false;
      let allSpot = this.formGroupBenchmark.value.topicFilter.indexOf('all_topics');
      let tempvalue = [...this.formGroupBenchmark.value.topicFilter];
      tempvalue.splice(allSpot,1);
      this.formGroupBenchmark.controls.topicFilter.patchValue(tempvalue)
    }
    else if (this.formGroupBenchmark.value.topicFilter.length == this.topicCount) {
      
      let tempvalue = [...this.formGroupBenchmark.value.topicFilter];
      tempvalue.push("all_topics");
      this.formGroupBenchmark.controls.topicFilter.patchValue(tempvalue)
      this.allTopicsSelected = true;
    }
  }
  

  buildExampleBenchmarkTable() {
    this._subscriptions.add(
      this.reportService.buildExampleBenchmarkTable().subscribe(response => {
        if (response.success) {
          console.log(response);
        } else {
          console.log('error gettin data');
        }
        this.loading = false;
      })
    );
  }
}