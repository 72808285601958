import { Component, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportService } from 'src/app/services/report/report.service';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { Subscription } from "rxjs";
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { DeletePopupComponent } from 'src/app/pages/report/pdf/delete-popup/delete-popup.component';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomComponent implements OnDestroy {

  @Input() customData: any;
  @Input() overallData: any;
  @Input() dateRange: any;
  @Input() advertiserDivisionId: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;

  leadsBeforeEditing: number = null;
  impressionsBeforeEditing: number = null;

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private dialog: MatDialog, private reportService: ReportService, private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;

    this._subscriptions.add(editItemService.newCustomProductEventHook$.subscribe(
      (productInfo) => {
        this.handleNewCustomProduct(productInfo);
      })
    );

    this._subscriptions.add(editItemService.editCustomProductEventHook$.subscribe(
      (productInfo) => {
        this.handleEditCustomProduct(productInfo);
      })
    );
  }

  /**
   * Updates overallData total_impressions and total_leads when adding new custom product
   * @param any productInfo
   * @return void
   */
  handleNewCustomProduct(productInfo) {
    if (productInfo.impressions) {
      this.overallData.total_impressions += productInfo.impressions;
      delete this.reportPreviewService.hidePageBucket['summary-overall'];
    }
    if (productInfo.leads) {
      this.overallData.total_leads += productInfo.leads;
    }
  }

  /**
   * Updates overallData total_impressions and total_leads when editing custom product
   * @param any productInfo
   * @return void
   */
  handleEditCustomProduct(productInfo) {
    const leadDiff = productInfo.leads - this.leadsBeforeEditing;
    const impressionDiff = productInfo.impressions - this.impressionsBeforeEditing;

    this.overallData.total_impressions += impressionDiff;
    this.overallData.total_leads += leadDiff;

    if (this.overallData.total_impressions > 0) {
      delete this.reportPreviewService.hidePageBucket['summary-overall'];
    } else {
       this.reportPreviewService.hidePageBucket['summary-overall'] = true;
    }
  }

  /**
   * Move a Custom Product higher or lower on the page (in
   * the custom product array)
   * @param number fromIndex
   * @param number toIndex
   * @return void
   */
  moveCustomProduct(fromIndex: number, toIndex: number) {
    // Remove `fromIndex` item and store it
    const fromItem = this.customData.products.splice(fromIndex, 1)[0];
    // Insert stored item in new position
    this.customData.products.splice(toIndex, 0, fromItem);
  }

  /**
   * Opens delete popup; once closed updates summary totals and marks deleted in backend
   * (if user "Saves"... nothing happens if user just closes the popup)
   * @param any item
   * @param number index
   * @return void
   */
  openDeletePopup(item: any, index: number) {
    this.dialog.closeAll();

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      itemName: item.report_label
    };

    const dialogRef = this.dialog.open(DeletePopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.shouldDelete) {
        this.updateSummaryTotalsOnDelete(item);
        this.markDeleted(item.object_id, index);
      }
    });
  }

  /**
   * Updates overallData total_impressions and total_leads when deleting custom product
   * @param any item
   * @return void
   */
  updateSummaryTotalsOnDelete(item) {
    if (item.leads) this.overallData.total_leads -= item.leads;
    if (item.impressions) this.overallData.total_impressions -= item.impressions;
    if (this.overallData.total_impressions < 1) {
      this.reportPreviewService.hidePageBucket['summary-overall'] = true;
    }
  }

  /**
   * Calls report service markDeleted() and removes this
   * Custom Product from the custom product array
   * @param number productId
   * @param number index
   * @return void
   */
  markDeleted(productId, index) {
    this._subscriptions.add(
      this.reportService.markDeleted(productId).subscribe(response => {
        
        // Remove from frontend customData.products array
        const indexToDelete = this.customData.products.findIndex(x => x.object_id === productId);
        this.customData.products.splice(indexToDelete, 1);

        this.updateTotalPages();
      })
    );
  }

  /**
   * Calls EventEmitService updateTotalPages() 
   */
  updateTotalPages() {
    this.reportPreviewService.updateTotalPages();
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @param any[] itemArray
   * @return void
   */
  editItem(item, type, key, itemArray) {
    this.leadsBeforeEditing = item.leads || 0;
    this.impressionsBeforeEditing = item.impressions || 0;

    this.editItemService.editItem(item, type, key, itemArray);
  }

  /**
   * Unsubscribes to subscriptions
   */
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
