import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import {MatExpansionPanel} from '@angular/material/expansion';
import { Subscription } from "rxjs";
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationService } from "src/app/services/validation/validation.service";
import { CFEService } from "src/app/services/cfe/cfe.service";
import { Brand } from 'src/app/models/brand.model';

@Component({
  selector: 'app-cfe-main',
  templateUrl: './cfe-main.component.html',
  styleUrls: ['./cfe-main.component.scss']
})
export class CfeMainComponent implements OnInit, OnDestroy {

	selectedVal: string = '';
	newProductInProgress: boolean = false;
	newBrandInProgress: boolean = false;
	products: any[] = [];
	brands: Brand[] = [];
	panelOpenState: any = {};

	private _subscriptions = new Subscription();
	@ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

  constructor(private router: Router, private builder: FormBuilder, private cfeService: CFEService) { }

  ngOnInit() {
  	this.selectedVal ='product';
  	this.getLists();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  goHome() {
    this.router.navigate(["/home"]);
  }

  setupProducts(products: any[]) {
    for (let product of products) {
      product.form = this.buildProductForm(product);
    }

    return products;
  }

  setupBrands(brands: Brand[]) {
    for (let brand of brands) {
      brand.form = this.buildBrandForm(brand);
    }

    return brands;
  }

  getProducts() {
  	// TODO
  	this.products = [{name: 'fake1'}, {name: 'fake2'}]
  	// this._subscriptions.add(
  	//   this.userService.getProducts().subscribe(response => {
  	//     this.products = this.setupProducts(response.data);
  	//   })
  	// );
  }

  getBrands() {
  	this._subscriptions.add(
  	  this.cfeService.getBrands().subscribe(response => {
  	    this.brands = this.setupBrands(response.data);
  	  })
  	);
  }

  getLists() {
    this.newProductInProgress = false;
    this.newBrandInProgress = false;

    this.getProducts();
    this.getBrands();
  }

  saveBrand(brand: Brand) {

    const nameChanged = brand.name !== brand.form.get("name").value;
    const identifierChanged = brand.identifier !== brand.form.get("identifier").value;
    const logoChanged = typeof brand.form.get("logo").value === 'object';

    if (!brand.form.status) return;
    if (!nameChanged && !identifierChanged && !logoChanged) return;
    if (brand.id === 0 && (!nameChanged || !identifierChanged || !logoChanged)) return;

    let payload;

    // If the logo changed, we need to send the logo file as Form Data
    if (logoChanged) {
    	payload = new FormData();
    	payload.append('file', brand.form.get("logo").value);
    	payload.append('name', brand.form.get("name").value);
    	payload.append('identifier', brand.form.get("identifier").value);
    } else {
    	payload = {
    	  id: brand.id,
        name: brand.form.get("name").value,
        identifier: brand.form.get("identifier").value
      };
    }

    // New
    if (brand.id === 0) {
      this._subscriptions.add(
        this.cfeService.createBrand(payload).subscribe(response => {
          if (response.success) this.getBrands();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.cfeService.updateBrand(brand.id, payload, logoChanged).subscribe(response => {
          if (response.success) this.getBrands();
        })
      );
    }
  }

  cancel(type) {
  	this.closeAllPanels();

  	if (type === 'product') {
  		this.newProductInProgress = false;
  		this.products.shift();
  	} 
  	if (type === 'brand') {
  		this.newBrandInProgress = false;
  		this.brands.shift();
  	}
  }

  delete(type, panelOpen, id, identifier) {
    event.stopPropagation();

    if (panelOpen) return;

    const confirmDelete = confirm('Are you sure you want to delete ' + identifier + '?');

    if (confirmDelete) {
      this.panelOpenState = {};

      if (type === 'product') {
        // this._subscriptions.add(
        //   this.cfeService.deleteAdmin(id).subscribe(response => {
        //     if (response.success) this.fetchUsers();
        //   })
        // );
      }

      if (type === 'brand') {
        this._subscriptions.add(
          this.cfeService.deleteBrand(id).subscribe(response => {
            if (response.success) this.getBrands();
          })
        );
      }
      
    }
  }

  onFileChange(event, brand) {
  	if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => brand.tempImage = reader.result;

      reader.readAsDataURL(file);

      brand.form.patchValue({
      	logo: file
      });
    }

  }

  resetBrand(i, brand) {
    this.panelOpenState[i] = false;

    brand.tempImage = '';
    brand.form.get("name").setValue(brand.name);
    brand.form.get("identifier").setValue(brand.identifier);
    brand.form.get("logo").setValue(brand.logo);
    (<HTMLInputElement>document.getElementById("brandLogo")).value = '';
  }


  addNew() {
		this.closeAllPanels();
		const that = this;

		if (this.selectedVal === 'product') {
			this.newProductInProgress = true;
			// TODO
	  //   const newProduct = new Product(0, '', '');
	  //   newProduct.form = this.buildProductForm(newProduct);
			// this.products.unshift(newProduct);
		} 

		if (this.selectedVal === 'brand') {
			this.newBrandInProgress = true;
	    const newBrand = new Brand(0,'','',false);
	    newBrand.form = this.buildBrandForm(newBrand);
			this.brands.unshift(newBrand);
		}

		setTimeout(function(){
			that.viewPanels.first.open();
		}, 0); 

  }

  closeAllPanels() {
  	this.viewPanels.forEach(p => p.close());
  }

  onValChange(val: string) {
    this.selectedVal = val;
    this.closeAllPanels();
    
    if (this.newProductInProgress) {
    	this.newProductInProgress = false;
    	this.products.shift();
    }  

    if (this.newBrandInProgress) {
    	this.newBrandInProgress = false;
    	this.brands.shift();
    }
  }

  buildBrandForm(brand: Brand): FormGroup {
    const form = {
        name: [
          (brand.name),//.trim(),
          [
            Validators.required
          ]
        ],
        logo: [
          (brand.logo),//.trim(),
          [
            Validators.required
          ]
        ],
        identifier: [
          (brand.identifier),//.trim(),
          [
            Validators.required
          ]
        ]

    };

    return this.builder.group(form);

  }

  buildProductForm(user: any): FormGroup {
    const form = {
        email: [
          (user.email),//.trim(),
          [
            Validators.required,
            Validators.pattern(
              ValidationService.getRegexWithSpaceRestriction()
            )
          ]
        ],
      // make password group
      passwordGroup: this.builder.group(
        {
          password: [
            user.password,
            [
              Validators.pattern(ValidationService.getPasswordRegex())
            ]
          ],
          confirmPassword: [user.password]
        },
        {
          /**
           * !NOTE
           *  passing undefined, because we want to show the error message as soon as
           *  user starts changing in the password/confirmPassword input-fields
           */
          validators: ValidationService.matchPassword(undefined)
        }
      )

    };

    return this.builder.group(form);

  }




}
