import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginMainComponent } from './pages/login/login-main/login-main.component';
import { HomeMainComponent } from './pages/home/home-main/home-main.component';
import { HeaderComponent } from './components/header/header.component';
import { UserMainComponent } from './pages/user/user-main/user-main.component';
import { CfeMainComponent } from './pages/cfe/cfe-main/cfe-main.component';
import { AdvertiserMainComponent } from './pages/advertiser/advertiser-main/advertiser-main.component';
import { AdvertiserDivisionMainComponent } from './pages/advertiser-division/advertiser-division-main/advertiser-division-main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/app/utility/material/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { MatAutocompleteModule } from '@angular/material/autocomplete'; 

import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { UserService } from './services/user/user.service';
import { CFEService } from './services/cfe/cfe.service';
import { AdvertiserService } from './services/advertiser/advertiser.service';
import { BrandService } from './services/brand/brand.service';
import { TestService } from './services/test/test.service';
import { ReportService } from './services/report/report.service';
import { EditItemService } from './pages/report/pdf/edit-item/services/edit-item.service';
import { CrmCompanyService } from './services/crm-company/crm-company.service';
import { AdvertiserDivisionService } from './services/advertiser-division/advertiser-division.service';
import { EbookService } from './services/ebook/ebook.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AdvertiserDivisionReportsComponent } from './pages/advertiser-division/advertiser-division-reports/advertiser-division-reports.component';
import { BrandMainComponent } from './pages/brand/brand-main/brand-main.component';
import { AdvertiserDivisionNewEditComponent } from './pages/advertiser-division/advertiser-division-new-edit/advertiser-division-new-edit.component';
import { BrandNewEditComponent } from './pages/brand/brand-new-edit/brand-new-edit.component';
import { AdminNewEditComponent } from './pages/user/admin-new-edit/admin-new-edit.component';
import { SalesrepNewEditComponent } from './pages/user/salesrep-new-edit/salesrep-new-edit.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AdvertiserDivisionReportsNewEditComponent } from './pages/advertiser-division/advertiser-division-reports-new-edit/advertiser-division-reports-new-edit.component';
import { TestMainComponent } from './pages/test/test-main/test-main.component';
import { CrmCompanyMainComponent } from './pages/crm-company/crm-company-main/crm-company-main.component';
import { CrmCompanyNewComponent } from './pages/crm-company/crm-company-new/crm-company-new.component';
import { GenerateReportMainComponent } from './pages/report/generate-report-main/generate-report-main.component';
import { SalesRepsComponent } from './pages/advertiser-division/sales-reps/sales-reps.component';
import { SalesRepsNewComponent } from './pages/advertiser-division/sales-reps-new/sales-reps-new.component';
import { BannerUpdateMainComponent } from './pages/banner-update/banner-update-main/banner-update-main.component';
import { CorrectionLogComponent } from './pages/banner-update/components/correction-log/correction-log.component';
import { EbookMainComponent } from './pages/ebook/ebook-main/ebook-main.component';
import { EbookAssignComponent } from './pages/ebook/ebook-assign/ebook-assign.component';
import { EbookStatsListComponent } from './pages/ebook/ebook-stats-list/ebook-stats-list.component';
import { EbookStatsNewEditComponent } from './pages/ebook/ebook-stats-new-edit/ebook-stats-new-edit.component';
import { ReportPreviewComponent } from './pages/report/pdf/report-preview/report-preview.component';
import { ChartComponent } from './pages/report/pdf/chart/chart.component';
import { LegendComponent } from './pages/report/pdf/legend/legend.component';
import { EditItemComponent } from './pages/report/pdf//edit-item/edit-item.component';
import { SummaryComponent } from './pages/report/pdf/sections/summary/summary.component';
import { PrintComponent } from './pages/report/pdf/sections/print/print.component';
import { WebsiteComponent } from './pages/report/pdf/sections/website/website.component';
import { NewsletterComponent } from './pages/report/pdf/sections/newsletter/newsletter.component';
import { WebcastComponent } from './pages/report/pdf/sections/webcast/webcast.component';
import { EbookComponent } from './pages/report/pdf/sections/ebook/ebook.component';
import { CourseComponent } from './pages/report/pdf/sections/course/course.component';
import { ContactComponent } from './pages/report/pdf/sections/contact/contact.component';
import { CustomComponent } from './pages/report/pdf/sections/custom/custom.component';
import { FinalPopupComponent } from './pages/report/pdf/final-popup/final-popup.component';
import { NavComponent } from './pages/report/pdf/nav/nav.component';
import { FooterComponent } from './pages/report/pdf/footer/footer.component';
import { DeletePopupComponent } from './pages/report/pdf/delete-popup/delete-popup.component';
import { DownloadComponent } from './pages/report/pdf/download/download.component';
import { MediaMentionComponent } from './pages/report/pdf/sections/media-mention/media-mention.component';
import { VtwComponent } from './pages/report/pdf/sections/vtw/vtw.component';
import { CustomEblastComponent } from './pages/report/pdf/sections/custom-eblast/custom-eblast.component';
import { OttComponent } from './pages/report/pdf/sections/ott/ott.component';
import { EbookStatsUploadSheetComponent } from './pages/ebook/ebook-stats-upload-sheet/ebook-stats-upload-sheet.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginMainComponent,
    HomeMainComponent,
    HeaderComponent,
    UserMainComponent,
    CfeMainComponent,
    AdvertiserMainComponent,
    AdvertiserDivisionMainComponent,
    AdvertiserDivisionReportsComponent,
    BrandMainComponent,
    AdvertiserDivisionNewEditComponent,
    BrandNewEditComponent,
    AdminNewEditComponent,
    SalesrepNewEditComponent,
    ReportPreviewComponent,
    ChartComponent,
    AdvertiserDivisionReportsNewEditComponent,
    TestMainComponent,
    CrmCompanyMainComponent,
    CrmCompanyNewComponent,
    GenerateReportMainComponent,
    SalesRepsComponent,
    SalesRepsNewComponent,
    BannerUpdateMainComponent,
    CorrectionLogComponent,
    EbookMainComponent,
    EbookAssignComponent,
    EbookStatsListComponent,
    EbookStatsNewEditComponent,
    LegendComponent,
    EditItemComponent,
    SummaryComponent,
    PrintComponent,
    WebsiteComponent,
    NewsletterComponent,
    WebcastComponent,
    EbookComponent,
    CourseComponent,
    ContactComponent,
    CustomComponent,
    FinalPopupComponent,
    NavComponent,
    FooterComponent,
    DeletePopupComponent,
    DownloadComponent,
    MediaMentionComponent,
    VtwComponent,
    CustomEblastComponent,
    OttComponent,
    EbookStatsUploadSheetComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    AngularEditorModule, 
    ReactiveFormsModule,
    ChartsModule
  ],
  providers: [
    AuthService, 
    AuthGuardService, 
    JwtHelperService, 
    UserService,
    AdvertiserService,
    AdvertiserDivisionService,
    EbookService,
    CFEService,
    BrandService,
    TestService,
    ReportService,
    EditItemService,
    CrmCompanyService,
    ThemeService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
