// src/app/services/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class AuthService {

  public username = '';

  constructor(public jwtHelper: JwtHelperService, private api: ApiService) {}
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public updateToken(payload): Observable<any> {
  	return this.api.post<Response>("/api/login_check", payload);
  }

  public getUserId() {
    return this.api.get<Response>("/api/users/userId");
  }

  public getUser() {
  	return this.api.get<Response>("/api/users/user");
  }
}