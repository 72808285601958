import { FormGroup } from '@angular/forms';

/**
 * Model for admin user
 */
export class User {

  constructor(
      id: number,
      email: string,
      roles: any,
      password?: string,
      form?: FormGroup
  ) {
      this.id = id;
      this.email = email;
      this.roles = roles;
      if (password) this.password = password;
      if (form) this.form = form;
  }

  id: number = null;
  email: string = '';
  roles: any = [];
  password: string = '';
  form: FormGroup;
}