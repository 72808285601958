import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportService } from 'src/app/services/report/report.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FinalPopupComponent } from 'src/app/pages/report/pdf/final-popup/final-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import * as _ from "lodash";

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss']
})
export class ReportPreviewComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  loading: boolean = true;
  advertiserDivisionId: string|number;
  advertiserDivisionName: string;
  dateRange: string;
  makeImage: boolean = false;
  reportId: string;
  backendData: any;
  userId: string;
  userEmail: string;
  purchaseLog: object;

  webAdKeys: string[] = [
    "impressions", "clicks", "ctr"
  ]; // This determines the final order of the web ad pages
  allWebAds = [];

  monthsLong: string[] = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];

  monthsShort: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  monthLabels: string[];

  constructor(private reportService: ReportService, private activatedRoute: ActivatedRoute, private authService: AuthService, private dialog: MatDialog, private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 

    this.advertiserDivisionId = this.activatedRoute.snapshot.queryParams.advertiserDivisionId;

    this._subscriptions.add(
      this.authService.getUser().subscribe((response: any) => {
        this.userId = response.data.id;
        this.userEmail = response.data.email;
      })
    );

  }

  /**
   * Calls getPdfPreview()
   */
  ngOnInit() {
    this.getPdfPreview();
  }

  /**
   * Gets PDF preview
   * Grabs advertiserDivisionId, startDate & endDate from URL params
   * Calls reportService getPdfPreview()
   * Creates additional models and finally sets master backendData object
   * @return void
   */
  getPdfPreview() {

    let payload = {
      advertiserDivisionId: this.activatedRoute.snapshot.queryParams.advertiserDivisionId,
      startDate: this.activatedRoute.snapshot.queryParams.startDate,
      endDate: this.activatedRoute.snapshot.queryParams.endDate,
      fakeData: this.activatedRoute.snapshot.queryParams.fakeData
    };

    this._subscriptions.add(
      this.reportService.getPdfPreview(payload).subscribe(response => {
        this.tweakData(response.data);
        this.backendData = response.data;
        this.loading = false;
      })
    );
  }

  /**
   * Creates and returns dateString
   * @param any
   * @return string
   */
  getDateString(generalData) {
    const startDate = new Date(generalData.start_date);
    const startDateMonth = startDate.getUTCMonth();
    const startDateYear = startDate.getUTCFullYear();

    const endDate = new Date(generalData.end_date);
    const endDateMonth = endDate.getUTCMonth();
    const endDateYear = endDate.getUTCFullYear();

    const startAndEndMonthsSame = startDateYear === endDateYear && startDateMonth === endDateMonth;

    let dateString;

    if (startAndEndMonthsSame) {
      dateString = this.monthsLong[endDateMonth] + " " + endDateYear;
    } else {
      dateString = this.monthsLong[startDateMonth] +"-"+ this.monthsLong[endDateMonth] + " " + endDateYear;
    }
    
    return dateString;
  }

  /**
   * Opens final popup after user "downloads" pdf
   * @return void
   */
  openFinalPopup() {
    this.dialog.closeAll();

    const dialogRef = this.dialog.open(FinalPopupComponent);
      dialogRef.afterClosed().subscribe(result => {
        window.scrollTo(0, 0);
    });
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @param any[] itemArray
   * @return void
   */
  editItem(item, type, key, itemArray) {
    this.editItemService.editItem(item, type, key, itemArray);
  }

  /**
   * Creates new media mention object and calls editItem()
   * @param any[] itemArray
   * @return void
   */
  addMediaMention(itemArray: any[]) {

    let newMediaMention = {
      object_id: -1,
      object_type: "media_mention",
      report_label: "",
      color_value: "",
      custom_fields: {
          url: "",
          author: "",
          impressions: null,
          brand: null,
          crm_order_detail_id: null,
          date: "",
          advertiser_division_id: this.advertiserDivisionId
      }
    };

    this.editItem(newMediaMention, 'mediaMention', 'mediaMention', itemArray);
  }

  /**
   * Creates new Custom Product model and passes to editItem()
   * @param any[] itemArray
   * @return void
   */
  addNewCustomProduct(itemArray: any[]) {
    let newCustomProduct = {
      object_id: -1,
      object_type: "custom_product",
      report_label: "",
      color_value: "",
      custom_fields: {
        description: "",
        crm_order_detail_id: null,
        date: "",
        advertiser_division_id: this.advertiserDivisionId
      }
    };

    this.editItem(newCustomProduct, 'customProduct', 'customProduct', itemArray);
  }

  /**
   * Sets purchaseLog model.
   * Adds "custom_products" to purchaseLog.
   * Adds "date_range" to general data model.
   * Adds pseudo secondary common legend for Newsletter
   * opens, openRate and sent.
   * @param any data
   * @return void
   */
  tweakData(data) {

    this.purchaseLog = data.marketing;
    // Add custom products to purchaseLog
    this.purchaseLog["custom_products"] = {products: data.custom_products.products, purchased: data.custom_products.products.length};

    // Add media mentions to purchaseLog
    this.purchaseLog["media_mentions"] = {mentions: data.media_mentions.mentions, purchased: data.media_mentions.purchased};

    this.purchaseLog["cfe_services"] = {purchased: 
      data.custom_eblasts.purchased ||
      data.virtual_training_week.purchased ||
      data.own_the_topic.purchased
    };

    data["date_range"] = {date: this.getDateString(data.general)};

    this.monthLabels =  this.getDateLabels(data.general);

    if (data.web_ads.purchased) this.orderWebAds(data.web_ads.chart_data);
  }

  /**
   * Returns number of months between startDate and endDate
   * @param string dateFrom
   * @param string dateTo
   * @return number
   */
  monthDiff(dateFrom, dateTo) {
    dateFrom = new Date(dateFrom);
    dateTo = new Date(dateTo);

    return dateTo.getMonth() - dateFrom.getMonth() + 
     (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  }

  /**
   * Returns array of month label strings, like: ["APR", "MAY", "JUN"]
   * @param any general
   * @return string[]
   */
  getDateLabels(general) {
    // Remove hours/minutes, etc.
    general.start_date = general.start_date.split('T')[0];
    general.end_date = general.end_date.split('T')[0];

    let monthLabels = [];
    const startDate = new Date(general.start_date);
    const monthDifference = this.monthDiff(general.start_date, general.end_date);

    // Returns the index that represents a given month
    for (let i = 0, len = monthDifference; i < len; i++) {
      let index = (startDate.getMonth()+i+1) % 12;
      monthLabels.push(this.monthsShort[index]);
    }

    return monthLabels;
  }

  /**
   * Organizes web ads into proper order
   * @param any data
   * @return void
   */
  orderWebAds(data) {

    for(let webAdKey of this.webAdKeys){
      this.allWebAds.push({key: webAdKey + "_webAds", displayName: (webAdKey === 'ctr' ? 'AD CLICK-THRU-RATES' : ("AD " + webAdKey.toUpperCase())), chart_data: {data: data[webAdKey].data}, unique_legend: data[webAdKey].unique_legend});
    }
  }

  /**
   * Iterates through all <img> tags in html
   * As long as the <img> is not a chart.js <img>, 
   * sets the "src" of the <img> to the filename.
   * Initially: <img src="//d3mm496e6885mw.cloudfront.net/CFET-2021/ROI/Assets/section-title-top-bg.png">
   * End result: <img src="section-title-top-bg.png">
   * @param string clone
   * @return void
   */
  replaceImageSource(clone: any) {
    clone.find('img').each(function() {
      // If chart.js <img>, exit function
      if ($(this).attr('src').startsWith('data:image/png;base64')) {
        return;
      }

      let splitArray = $(this).attr('src').split('/');
      $(this).attr('src', splitArray[splitArray.length - 1]);
    });
  }

  /**
   * Opens final popup and creates final html to send to backend
   * @return void
   */
  createFinalHtml() {

    window.scrollTo(0, 0);
    this.openFinalPopup();
    
    const that = this;
    // When this flag gets set to true, the chart component generates
    // the image version of the chart, and removes the chart canvas
    this.makeImage = true;

    setTimeout(function(){
      const clone = $('#top-of-page').clone();
      that.replaceImageSource(clone);
      clone.find('.will-remove').addClass('visibility-hidden').removeClass('will-remove');
      // Remove buttons we do not want in the final pdf
      clone.find('.remove').remove();
      clone.find('.remove-button').remove();
      clone.find('#add-custom-product-div').remove();
      clone.find('#download-button').remove();

      if (!that.backendData.custom_products.products.length) {
        clone.find('#nav-custom-li').addClass('disable');
      }

      // This "unwrapping" is to remove the parent container elements.
      // Without this, we see an extra blank page between every normal
      // page in the pdf (courtesy of Erich Specht ;-)
      clone.find("#app-summary").contents().unwrap();
      clone.find("#app-print").contents().unwrap();
      clone.find("#app-website").contents().unwrap();
      clone.find("#app-newsletter").contents().unwrap();
      clone.find("#app-webcast").contents().unwrap();
      clone.find("#app-ebook").contents().unwrap();
      clone.find("#app-course").contents().unwrap();
      clone.find("#app-custom").contents().unwrap();
      clone.find("#app-contact").contents().unwrap();

      that.createPDF(clone.html());
    }, 0); 
 
  }

  /**
   * Creates the final dom and payload to send to backend
   * @param string newDom
   * @return void
   */
  createPDF(newDom: string) {
    this.makeImage = false;

    const finalDom = '<html><head><link rel="stylesheet" href="styles.css"></head><body>' + newDom + '</body></html>';

    const payload = {
      html: finalDom,
      userId: this.userId,
      userEmail: this.userEmail,
      advertiserDivisionId: this.advertiserDivisionId,
      advertiserDivisionName: this.backendData.general.advertiser_name,
      date: this.backendData.date_range.date
    };

    this._subscriptions.add(
      this.reportService.createPDF(payload).subscribe(response => {
        // console.log('createPDF response... ', response);
      })
    );
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
