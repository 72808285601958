import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Brand } from 'src/app/models/brand.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { BrandService } from 'src/app/services/brand/brand.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-brand-new-edit',
  templateUrl: './brand-new-edit.component.html',
  styleUrls: ['./brand-new-edit.component.scss']
})
export class BrandNewEditComponent implements OnInit, OnDestroy {

  isNewBrand: boolean = false;
  loading: boolean = false;
  removedLogo: boolean = false;
  brand: Brand;
  private _subscriptions = new Subscription();
  @ViewChild('customInput', {static: false}) customInput: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private builder: FormBuilder, @Inject(BrandService) private brandService: BrandService) { 
      this.isNewBrand = !this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.isNewBrand ? this.createNew() : this.getBrand();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Removes logo attribute from brand
   */
  removeLogo() {
    if (this.brand.tempImage) this.brand.tempImage = '';
    this.brand.form.patchValue({logo: null});
    this.customInput.nativeElement.value = "";
    delete this.brand.logo;
    this.removedLogo = true;
  }

  /**
   * Goes to brand list page
   */
  goToBrands() {
    this.router.navigate(["/brands"]);
  }

  /**
   * Creates new empty brand model
   */
  createNew() {
    const brand = new Brand(-1, '', '', false);
    brand.form = this.buildBrandForm(brand);

    this.brand = brand;
  }

  /**
   * Gets brand
   */
  getBrand() {
    this._subscriptions.add(
      this.brandService.getOne(this.activatedRoute.snapshot.params.id).subscribe(response => {
        if (response.success && response.data) {
          const brand = response.data[0];
          brand.form = this.buildBrandForm(response.data[0]);
          this.brand = brand;
        }
      })
    );
  }

  /**
   * If should show the Save button
   */
  showSaveButton() {
    if (this.brand.form.status === 'INVALID') return false;

    if (this.isNewBrand) {
      if (this.brand.form.get('name').value.trim() &&
          this.brand.form.get('identifier').value.trim()) {
        return true;
      } else {
        return false;
      }
    } else {

      if (this.removedLogo) return true;

      const nameChanged = this.brand.name !== this.brand.form.get("name").value;
      const identifierChanged = this.brand.identifier !== this.brand.form.get("identifier").value;

      if (nameChanged && !this.brand.form.get("name").value.trim()) return false;
      if (identifierChanged && !this.brand.form.get("identifier").value.trim()) return false;

      const logoChanged = (this.brand.form.value.logo && this.brand.form.value.logo.lastModified);

      return (!nameChanged && !identifierChanged && !logoChanged) ? false : true;
    }
  }

  /**
   * Saves, or updates, the brand
   */
  save() {
    this.loading = true;
    const nameChanged = this.brand.name !== this.brand.form.get("name").value;
    const identifierChanged = this.brand.identifier !== this.brand.form.get("identifier").value;
    const logoChanged = typeof this.brand.form.get("logo").value === 'object';

    if (!this.brand.form.status) return;
    if (!nameChanged && !identifierChanged && !logoChanged) return;
    if (this.brand.id === -1 && (!nameChanged || !identifierChanged)) return;

    let payload;

    // If the logo changed, we need to send the logo file as Form Data
    if (logoChanged && !this.removedLogo) {
      payload = new FormData();
      payload.append('file', this.brand.form.get("logo").value);
      payload.append('name', this.brand.form.get("name").value);
      payload.append('identifier', this.brand.form.get("identifier").value);
    } else {
      payload = {
        id: this.brand.id,
        name: this.brand.form.get("name").value,
        identifier: this.brand.form.get("identifier").value
      };
    }
    console.log("calling create");

    console.log(payload);
    if (this.removedLogo) payload.removedLogo = true;

    // New
    if (this.brand.id === -1) {
      this._subscriptions.add(
        this.brandService.create(payload).subscribe(response => {
          console.log("response:");
          console.log(response);
          if (response.success) this.goToBrands();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.brandService.update(this.brand.id, payload, logoChanged).subscribe(response => {
          console.log("response:");
          console.log(response);
          if (response.success) this.goToBrands();
        })
      );
    }
  }

  /**
   * Handles changing logo, sets temporary image that hasn't been persisted yet
   */
  onFileChange(event, brand) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => brand.tempImage = reader.result;

      reader.readAsDataURL(file);

      brand.form.patchValue({
        logo: file
      });

      this.removedLogo = false;
    }
  }

  /**
   * Builds form
   */
  buildBrandForm(brand) {
    const form = {
        name: [
          (brand.name),//.trim(),
          [
            Validators.required
          ]
        ],
        identifier: [
          (brand.identifier),//.trim(),
          [
            Validators.required
          ]
        ],
        logo: [
          (brand.logo),//.trim(),
          []
        ]
    };

    return this.builder.group(form);

  }

}
