import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {

	itemName: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, public dialogRef: MatDialogRef<DeletePopupComponent>) { 
  	dialogRef.disableClose = true;
  	this.itemName = data.itemName;
  }

  ngOnInit() {}

  closeDialog(shouldDelete: boolean) {
    this.dialogRef.close({shouldDelete: shouldDelete});
  }

}
