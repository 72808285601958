import { AdvertiserContact } from 'src/app/models/advertiser-contact.model';

/**
 * Model for Advertiser Division
 */
export class AdvertiserDivision {

  constructor(
      id: number,
      name: string,
      deleted: boolean,
      logo?: string,
      city?: string,
      tempImage?: string,
      advertiserId?: number,
      form?: any,
      reps?: any,
      contacts?: any,
      newContact?: AdvertiserContact
  ) {
      this.id = id;
      this.name = name;
      this.deleted = deleted;
      if (logo) this.logo = logo;
      if (city) this.city = city;
      if (tempImage) this.tempImage = tempImage;
      if (advertiserId) this.advertiserId = advertiserId;
      if (form) this.form = form;
      if (reps) this.reps = reps;
      if (contacts) this.contacts = contacts;
      if (newContact) this.newContact = newContact;
  }

  id: number = null;
  name: string = '';
  deleted: boolean;
  logo: string = '';
  city: string = '';
  tempImage: string = '';
  advertiserId: number;
  form: any = '';
  reps: any = [];
  contacts: any = [];
  newContact: any;
}