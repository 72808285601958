import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { SalesRep } from 'src/app/models/sales-rep.model';

@Component({
  selector: 'app-sales-reps-new',
  templateUrl: './sales-reps-new.component.html',
  styleUrls: ['./sales-reps-new.component.scss']
})
export class SalesRepsNewComponent implements OnInit, OnDestroy {

  advertiserDivisionId: number = null;
  advertiserDivisionName: string = '';
  private _subscriptions = new Subscription();

  currentSalesReps: SalesRep[];
  availableSalesReps: SalesRep[];
  allSalesReps: SalesRep[];
  displayedColumns: string[] = ['name', 'email', 'selectAction'];

  constructor(private activatedRoute: ActivatedRoute, private advertiserDivisionService: AdvertiserDivisionService, private router: Router, private userService: UserService) { 
    this.advertiserDivisionId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.getAdvertiserDivision();
    this.getSalesReps();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Sets availableSalesReps (i.e. all sales reps in system not 
   * already assigned to this advertiser division)
   * @return void
   */
  filterSalesReps() {
    if (!this.allSalesReps || !this.currentSalesReps) return;

    let unAvailableIds = [];

    for (let currentSalesRep of this.currentSalesReps) {
      unAvailableIds.push(currentSalesRep.id);
    }

    this.availableSalesReps = this.allSalesReps.filter(function(salesRep) {
      return unAvailableIds.indexOf(salesRep.id) === -1;
    });
   
  }

  /**
   * Gets all sales reps; calls filterSalesReps()
   * @return void
   */
  getSalesReps() {
    this._subscriptions.add(
      this.userService.getSalesReps().subscribe(response => {
        this.allSalesReps = response.data;
        this.filterSalesReps();
      })
    );
  }

  /**
   * Gets Advertiser Division; calls filterSalesReps()
   * @return void
   */
  getAdvertiserDivision() {
    this._subscriptions.add(
      this.advertiserDivisionService.getFull(this.advertiserDivisionId).subscribe(response => {
        if (response.success && response.data) {
          this.currentSalesReps = response.data.salesReps;
          this.advertiserDivisionName = response.data.name;
          this.filterSalesReps();
        }
      })
    );
  }

  /**
   * Adds sales rep to this advertiser division
   * @param number sales rep id
   * @return void
   */
  addSalesRep(repId: number) {

    const payload = {
      repId: repId
    };

    this._subscriptions.add(
      this.advertiserDivisionService.addSalesRep(this.advertiserDivisionId, payload).subscribe(response => {
        if (response.success) this.goToExistingSalesReps();
      })
    );
  }

  /**
   * Navigates to existing sales reps page
   * @return void
   */
  goToExistingSalesReps() {
    this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/salesrep"]);
  }

}
