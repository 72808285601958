import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { catchError, flatMap, map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Response } from 'src/app/models/response.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.scss']
})
export class LoginMainComponent implements OnInit, OnDestroy {

   /**
   * Form group for popup
   */
  loginForm: FormGroup;

  /**
   * Flag indicating form submitted
   */
  submitted = false;

  /**
   * Flag indicating login failure
   */
  loginFailure = false;

  private _subscriptions = new Subscription();

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    localStorage.removeItem('token');
    this.loginForm = this._buildLoginForm();
  }

  /**
   * Unsubscribes to subscription
   * Remove subscriptions
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Gets reference to email form control
   *
   * Convience method to extract form control from group
   */
  get email(): FormControl {
    return this.loginForm.get("email") as FormControl;
  }

  /**
   * Gets reference to password form control
   *
   * Convience method to extract form control from group
   */
  get password(): FormControl {
    return this.loginForm.get("password") as FormControl;
  }

  public submitLogin() {
    if(this.loginForm.invalid) return; 

    this.loginFailure = false;
    this.submitted = true;

    const user = {
      username: this.loginForm.get('email').value,
      password: this.loginForm.get('password').value
    };

    this.authService.username = this.loginForm.get('email').value;

    this._subscriptions.add(
      this.authService.updateToken(JSON.stringify(user)).subscribe(
        data => this._handleLoginSuccess(data),
        error => this._handleLoginFailure()
      )
    );

  }

  private _setTokenAndId(token) {
    localStorage.setItem('token', token);

    this._subscriptions.add(
      this.authService.getUserId().subscribe(response => {
        localStorage.setItem('userId', response.data.toString());
        this.router.navigate(['home']);
      })
    );
  }

  /**
   * Handles failure logging user in
   */
  private _handleLoginSuccess(response: Response): void {
    response.token ? this._setTokenAndId(response.token) : this._handleLoginFailure();
  }

  /**
   * Handles failure logging user in
   */
  private _handleLoginFailure(): void {
    this.submitted = false;
    this.loginFailure = true;
  }

  private _buildLoginForm() {
    const form =  new FormGroup({
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.email
        ]
      }),
      password: new FormControl('', {
        validators: [
          Validators.required
        ]
      })
    });

    return form;
  }

  // TODO
  showForgotPassword() {

  }


}
