import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';




const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

/**
 * Custom dateAdapter in order to display "Month Year" ("January 2020") in the mat-calendar input
 */
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${monthNames[date.getMonth()]} ${year}`;
    }

  return date.toDateString();
}};