import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";
import { skip } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ReportPreviewService {

  hidePageBucket: any = {};
  hideBubbleBucket: any = {};
  hideRowBucket: any = {};
  //hideMarketingBucket: any = {};
  hideMarketingBucket: any = {
    'prod-ebooks':1,
    'prod-newsletter_ads':1,
    'prod-webcasts':1,
    'prod-print_ads':1,
    'prod-web_ads':1,
    'prod-custom_eblasts':1,
    'prod-own_the_topic':1,
    'prod-virtual_training_week':1,
  };
  alreadyProcessed: any = {};
  leadsView: string = 'leads';
  advertiserDivisionId: any;

  constructor(private activatedRoute: ActivatedRoute) {
    this.advertiserDivisionId = this.activatedRoute.snapshot.queryParams.advertiserDivisionId;
  }

  public totalPagesEvent = new Subject<void>();
  // Observable stream (ignore dummy first value for BehaviorSubjects)
  totalPagesEventHook$ = this.totalPagesEvent;

  private leadsViewSource = new BehaviorSubject<string>("");

  // Observable streams (ignore dummy first value for BehaviorSubjects)
  leadsViewHook$ = this.leadsViewSource.pipe(skip(1));

  /**
   * Calls updates totalPagesEventHook$() 
   */
  updateTotalPages() {
    this.totalPagesEvent.next();
  }

  /**
   * Updates leadsView listener
   * @param string view
   */
  updateLeadsView(view: string): void {
    this.leadsViewSource.next(view);
  }

  /**
   * Flips marketing bucket to alternate "We haven't connected?" message
   * @param string section 
   * @return void
   */
  updateHideMarketingBucket( section, isPurchased ) {
    /*if (this.hideMarketingBucket[section]) {
      delete this.hideMarketingBucket[section];
    } else {
      this.hideMarketingBucket[section] = true;
    }*/
    if ( 'prod-virtual_training_week' == section ) {
      if ( isPurchased ) {
        if ( 1 == this.hideMarketingBucket[section] ) {
          this.hideMarketingBucket[section] = 3;
        } else {
          this.hideMarketingBucket[section] = 1;
        }
      } else {
        this.hideMarketingBucket[section] = 3;
      }
    } else {
      if ( isPurchased ) {
        if ( 1 == this.hideMarketingBucket[section] ) {
          this.hideMarketingBucket[section] = 2;
        } else if ( 2 == this.hideMarketingBucket[section] ) {
          this.hideMarketingBucket[section] = 3;
        } else {
          this.hideMarketingBucket[section] = 1;
        }
      } else {
        if ( 1 == this.hideMarketingBucket[section] || 2 == this.hideMarketingBucket[section] ) {
          this.hideMarketingBucket[section] = 3;
        } else if ( 3 == this.hideMarketingBucket[section] ) {
          this.hideMarketingBucket[section] = 2;
        }
      }
    }
  }

  /**
   * 1st click: turn Leads bubble into CTR bubble.
   * 2nd click: gray-out CTR bubble.
   * 3rd click: back to Leads bubble and remove gray-out
   * Also communicates back to Summary component what the 
   * new view should be. Using this service to semi-persist the
   * user's bubble selection, in case they pseudo "refresh" the page
   * after adding/deleting print promo item
   * @param any elem 
   * @return void
   */
  changeLeadsView(elem: any) {
    if (this.leadsView === 'leads') {
      this.leadsView = 'ctr';
      this.updateLeadsView('ctr');
      return;
    }
    if (this.leadsView === 'ctr') {
      this.leadsView = 'hide';
      this.updateLeadsView('hide');
      this.changeBubbleViewStatus(elem);
      return;
    }
    if (this.leadsView === 'hide') {
      this.leadsView = 'leads';
      this.updateLeadsView('leads');
      this.changeBubbleViewStatus(elem);
      return;
    }
  }

  /**
   * Hides or shows individual "bubbles" 
   * @param any elem 
   * @return void
   */
  public changeBubbleViewStatus(elem: any) {
     if ($(elem).closest('.bubble').hasClass('will-remove')) {
       delete this.hideBubbleBucket[$(elem).closest('.bubble').attr('id')];
     } else {
       this.hideBubbleBucket[$(elem).closest('.bubble').attr('id')] = true;
     }
  } 

  public changeRowViewStatus(elem: any) {
    if ($(elem).closest('.topic-row').hasClass('will-remove')) {
      delete this.hideRowBucket[$(elem).closest('.topic-row').attr('id')];
    } else {
      this.hideRowBucket[$(elem).closest('.topic-row').attr('id')] = true;
    }
  }

  /**
   * Responsible for "hiding" and "showing" pages in the 
   * pdf report preview
   * @param any elem 
   * @return void
   */
  public changePageViewStatus(elem: any) {
    if ($(elem).closest('.single-page').hasClass('remove')) {
      delete this.hidePageBucket[$(elem).closest('.single-page').attr('id')];
    } else {

      this.hidePageBucket[$(elem).closest('.single-page').attr('id')] = true;
    }

    this.updateTotalPages();
  }

  /**
   * Hides all pages that start with a given prefix (i.e. edu-) 
   * @param string prefix 
   * @return void
   */
  public getIdsAndHidePages(prefix: string) {
    const that = this;

    setTimeout(function(){
      const pages = $('article[id^="' + prefix + '"]');

      for (const page of pages) {
        that.hidePageBucket[page.id] = true;
      }

      that.updateTotalPages();
    }, 0); 
  }

  /**
   * Unhides all pages and bubbles that start with this prefix 
   * @param array pages 
   * @return void
   */
  public unHidePagesAndBubbles(prefix: string) {

    for (let [key, value] of Object.entries(this.hideBubbleBucket)) {
      if (key.startsWith(prefix)) {
        delete this.hideBubbleBucket[key];
        delete this.alreadyProcessed[key];
      }
    }

    for (let [key, value] of Object.entries(this.hidePageBucket)) {
      if (key.startsWith(prefix)) {
        delete this.hidePageBucket[key];
        delete this.alreadyProcessed[key];
      }
    }
  }

  /**
   * Used in the html files in an *ngIf on whether to 
   * "show" or "gray-out" pages or bubbles.
   * Always returns true, but if the field param is 0 and this id has not already
   * been processed, adds this id to the alreadyProcessed object, and also
   * either the hidePageBucket or hideBubbleBucket.
   * @param string type 
   * @param id string 
   * @param field Number 
   * @return true
   */
  public showItem(type: string, id: string, field: Number) {
    if (field != 0) return true;
    if (this.alreadyProcessed[id]) return true;

    this.alreadyProcessed[id] = true;

    type === 'page' ? this.hidePageBucket[id] = true : this.hideBubbleBucket[id] = true;

    if (type === 'page') this.updateTotalPages();

    return true;
  }

  /**
   * Creates new promo object with advertiserDivisionId
   * @return object
   */
  createNewPromoObject(type, objectId) {
    let promoItem = {
      object_id: -1,
      advertiser_division_id: this.advertiserDivisionId,
      custom_fields: {}
    };

    if (type === 'webcast') promoItem["advertiser_webcast_id"] = objectId;
    if (type === 'ebook') promoItem["advertiser_ebook_id"] = objectId;
    if (type === 'course' || type === 'vtw') promoItem["advertiser_course_id"] = objectId;

    return promoItem;
  }

  /**
   * Hides and unhides pages
   * @param string section
   * @param number summaryTotalImpressions
   * @param number sectionTotalImpressions
   * @return void
   */
  handleHideUnhide(section, summaryTotalImpressions, sectionTotalImpressions) {
    // After user changes impressions, if total impressions is now positive, unhide everything and let the html decide what needs to be hidden at that point
    if (sectionTotalImpressions > 0) {
      this.unHidePagesAndBubbles(section);
    } else {
      this.getIdsAndHidePages(section);
    }

    // Unhide report summary overall page if there are now impressions
    if (summaryTotalImpressions > 0) {
      delete this.hidePageBucket['summary-overall'];
    // Else hide it
    } else {
      this.hidePageBucket['summary-overall'] = true;
    }
  }

}
