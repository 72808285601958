import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales-reps',
  templateUrl: './sales-reps.component.html',
  styleUrls: ['./sales-reps.component.scss']
})
export class SalesRepsComponent implements OnInit, OnDestroy {

  private _subscriptions = new Subscription();
  advertiserDivisionId: number = null;
  advertiserDivisionName: string = '';
  salesReps: any = [];
  displayedColumns: string[] = ['name', 'email', 'deleteAction'];

  constructor(private activatedRoute: ActivatedRoute, private advertiserDivisionService: AdvertiserDivisionService, private router: Router) { 
    this.advertiserDivisionId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.getAdvertiserDivision();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Gets Advertiser Division
   * @return void
   */
  getAdvertiserDivision() {
    this._subscriptions.add(
      this.advertiserDivisionService.getFull(this.advertiserDivisionId).subscribe(response => {
        if (response.success && response.data) {
          this.advertiserDivisionName = response.data.name;

          this.salesReps = this.filterSalesReps(response.data.salesReps);
        }
      })
    );
  }

  /**
   * Removes sales reps who are marked as deleted
   * @param any[]
   * @return any[]
   */
  filterSalesReps(salesReps) {
    return salesReps.filter(
      rep => !rep.deleted
    );
  }

  /**
   * Removes sales rep from advertiser division
   * @param number repId
   * @param string name
   * @return void
   */
  removeSalesRep(repId: number, name: string) {

    const firstConfirm = confirm("Are you sure you want to remove " + name + " from " + this.advertiserDivisionName + "?");

    if (firstConfirm) {

      this._subscriptions.add(
        this.advertiserDivisionService.removeSalesRep(this.advertiserDivisionId, repId).subscribe(response => {
          if (response.success) this.getAdvertiserDivision();
        })
      );
    }
  }

  /**
   * Navigates to add new sales reps page
   * @return void
   */
  addSalesReps() {
    this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/salesrep/new"]);
  }

  /**
   * Navigates to advertiser division list page
   * @return void
   */
  goBack() {
    this.router.navigate(["/advertiser-divisions"]);
  }

}
