import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { of, Subscription } from "rxjs";
// import { zipCodes } from "../../constants/zipCodes";
// import { ProductUserInfoService } from "../product-user-info/product-user-info.service";
import { ApiService } from 'src/app/utility/api.service';

@Injectable({
  providedIn: "root"
})

/**
 * Custom validator handles form validation
 * this can be used in the components by importing
 *
 * Contains confirm password validator
 */
export class ValidationService {
  constructor() {}

  /**
   * Gets password regex
   * @returns password regex
   */
  static getPasswordRegex(): RegExp {
    // {8,}                 - at least 8 characters long
    // (?=.*\d)             - string has at least one number
    // (?=.*[A-Z])          - string contains atleast one uppercase
    return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  }

  /**
   * Gets email regex
   * @returns email regex
   */
  static getEmailRegex = () => {
    // tslint:disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  /**
   * Gets regex with space restriction
   * Disallows only space
   * Allows trailing and leading space with multiple words
   * @returns regex with space restriction
   */
  static getRegexWithSpaceRestriction(): RegExp {
   return /\S+([\s])*.\S*$|\S+[\s]*$/;

  }
  /**
   * validates password and confirm password values and compares for a match
   * @param isRegistration : if registration page
   * @returns null if match, else {mismatch: true}
   */
  static matchPassword(isRegistration: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === null || control.value.length === 0) {
        return null;
      }
      const compareControlWithPassword = control.root.get("passwordGroup.password");
      // registration
      if (isRegistration && isRegistration !== undefined) {
        // get value of password
        const password = control.get("password").value;
        // get value of confirm password
        const confirmPassword = control.get("confirmPassword").value;
        if (password !== confirmPassword) {
          return { mismatch: true };
        } else {

          return null;
        }
      } else if (compareControlWithPassword && !isRegistration) {
        // account page on change of input field
        const subscription: Subscription = compareControlWithPassword.valueChanges.subscribe(() => {
          control.get('confirmPassword').markAsDirty();
          control.updateValueAndValidity();

          subscription.unsubscribe();
        });
      }
      return control.value.password !== control.value.confirmPassword ? { mismatch: true } : null;

    }
  }

  /**
   * Validates phone number
   * @return RegExp
   */
  static validatePhone(): RegExp {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  }

  /**
   * Checks that a zip code is valid for the specified country
   *
   * @param control confirmPassword control
   * @return boolean|null if valid return null, else { inValidZip: true }
   */
  // static validateZipCode(control: AbstractControl) {
  //   if (
  //     control.root.get("zipPostalCode") !== null ||
  //     control.root.get("country") !== null
  //   ) {
  //     // get value of password
  //     let zip = control.root.get("zipPostalCode").value;
  //     // get value of confirm password
  //     let country = control.root.get("country").value;

  //     // country code must be in list of possible countries
  //     if (!zipCodes.hasOwnProperty(country)) {
  //       return null;

  //       // Some countries don't have zip codes
  //     } else if (!zipCodes[country] && zip) {
  //       return null;
  //     }

  //     const regex = new RegExp(zipCodes[country]);
  //     const valid = regex.test(zip);

  //     if ( valid || zip === '' ) {
  //       return null;
  //     } else {
  //       // invalid
  //       return { inValidZip: true };
  //     }
  //   }
  // }

  /**
   * Asyn validator that determines whether duplicate email
   * @param productUserInfoService
   * @param existingUsername
   * @param emailControl
   * @returns   boolean true if match or null on no match
   */
  // static isDuplicateEmail(
  //   productUserInfoService: ProductUserInfoService,
  //   existingUsername: string,
  //   emailControl: FormControl
  // ) {
  //   // if user is editing username, keep it's original username
  //   if (emailControl.value === existingUsername) {
  //     return of(null);
  //   }
  //   // whenever user profile is loaded first time
  //   // for some reason the async validator shows PENDING, and thus the form remained invalid
  //   // this tells that saved data is valid just return null
  //   if (!emailControl.valueChanges || emailControl.pristine) {
  //     return of(null);
  //   }
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       productUserInfoService
  //         .checkDuplicateEmail(emailControl.value)
  //         .subscribe((res: any) => {
  //           return res === true
  //             ? resolve({ isDuplicateEmail: true })
  //             : resolve(null);
  //         });
  //     }, 1000);
  //   });
  // }

  /**
   * Determines whether terms checked to true
   * @param terms
   * @returns  null | if checked false { isNotChecked: true }
   */
  static hasTermsChecked(terms: FormControl) {
    if (terms.value === "false") {
      return { isNotChecked: true };
    } else {
      return null;
    }

  }
}
