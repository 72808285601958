import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/services/banner/banner.service';
import { Subscription } from "rxjs";
import { BannerCorrectionLog } from 'src/app/models/banner-correction-log.model';

@Component({
  selector: 'app-correction-log',
  templateUrl: './correction-log.component.html',
  styleUrls: ['./correction-log.component.scss']
})
export class CorrectionLogComponent implements OnInit {

  loading: boolean = false;
  correctionLog: BannerCorrectionLog[] = [];
  private _subscriptions = new Subscription();
  displayedColumns: string[] = ['timestamp', 'oldBannerName', 'newBannerName', 'user', 'processed'];

  constructor( private bannerService: BannerService ) { 
    this.bannerService.success.subscribe( () => {
      this.getCorrectionLog();
    });
  }

  ngOnInit() {
    this.getCorrectionLog();   
  }

  getCorrectionLog() {
    this.loading = true;

    this._subscriptions.add(
      this.bannerService.getCorrectionLog().subscribe(response => {
        this.correctionLog = response.data;
        this.loading = false;
      })
    );
  }

}
