import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable, of } from "rxjs";
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, OnDestroy {

private _subscriptions = new Subscription();
pdfStatusKnown: boolean = false;
pdfExists: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private reportService: ReportService) {}

  /**
   * Calls getPdfStatus()
   * @return void
   */
  ngOnInit() {
  	this.getPdfStatus();
  }

  /**
   * Checks if pdf still exists. If so, calls downloadPdf(), else calls
   * setPdfExpired() 
   * @return void
   */
  getPdfStatus() {
  	this._subscriptions.add(
  	  this.reportService.getPdfStatus(this.activatedRoute.snapshot.params.guid).subscribe(response => {

  	  	this.pdfStatusKnown = true;

        if (response && response.data && response.data.pdf_exists) {
          this.downloadPdf(response.data.filename);
        } else {
          this.setPdfExpired();
        }
  	  })
  	);
  }

  /**
   * Downloads pdf
   * @param string filename
   * @return void
   */
  downloadPdf(filename) {
  	this.pdfExists = true;

  	const link = '/reports/' + this.activatedRoute.snapshot.params.guid + '/' + filename;

  	const anchor = document.createElement('a');
  	anchor.setAttribute('target', '_blank');
  	anchor.setAttribute('href', link);
  	anchor.setAttribute('download', filename);

  	document.body.appendChild(anchor);
  	anchor.click();
  	anchor.remove();
  }

  /**
   * Sets pdfExists to false, to update verbiage in html
   * @return void
   */
  setPdfExpired() {
  	this.pdfExists = false;
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
