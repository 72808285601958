import { Injectable } from '@angular/core';
import { EditItemComponent } from 'src/app/pages/report/pdf/edit-item/edit-item.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Observable, BehaviorSubject } from "rxjs";
import { skip } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class EditItemService {
  constructor(private dialog: MatDialog, private reportPreviewService: ReportPreviewService) {}

  public newMediaMentionEvent = new BehaviorSubject<any>('');
  public editMediaMentionEvent = new BehaviorSubject<any>('');
  public ebookPromoItemEvent = new BehaviorSubject<any>('');
  public webcastPromoItemEvent = new BehaviorSubject<any>('');
  public coursePromoItemEvent = new BehaviorSubject<any>('');
  public vtwPromoItemEvent = new BehaviorSubject<any>('');
  public newCustomProductEvent = new BehaviorSubject<any>('');
  public editCustomProductEvent = new BehaviorSubject<any>('');
  public editCustomEblastLeadsEvent = new BehaviorSubject<any>('');
  public editOttLeadsImpressionsEvent = new BehaviorSubject<any>('');
  public editVtwLeadsImpressionsEvent = new BehaviorSubject<any>('');

  // Observable streams (ignore dummy first value for BehaviorSubjects)
  newMediaMentionEventHook$ = this.newMediaMentionEvent.pipe(skip(1));
  editMediaMentionEventHook$ = this.editMediaMentionEvent.pipe(skip(1));
  ebookPromoItemEventHook$ = this.ebookPromoItemEvent.pipe(skip(1));
  webcastPromoItemEventHook$ = this.webcastPromoItemEvent.pipe(skip(1));
  coursePromoItemEventHook$ = this.coursePromoItemEvent.pipe(skip(1));
  vtwPromoItemEventHook$ = this.vtwPromoItemEvent.pipe(skip(1));
  newCustomProductEventHook$ = this.newCustomProductEvent.pipe(skip(1));
  editCustomProductEventHook$ = this.editCustomProductEvent.pipe(skip(1));
  editCustomEblastLeadsEventHook$ = this.editCustomEblastLeadsEvent.pipe(skip(1));
  editOttLeadsImpressionsEventHook$ = this.editOttLeadsImpressionsEvent.pipe(skip(1));
  editVtwLeadsImpressionsEventHook$ = this.editVtwLeadsImpressionsEvent.pipe(skip(1));

  /**
   * @param params any
   */
  handleNewMediaMention(params: any): void {
    this.newMediaMentionEvent.next(params);
  }

  /**
   * @param params any
   */
  handleEditMediaMention(params: any): void {
    this.editMediaMentionEvent.next(params);
  }

  /**
   * @param params any
   */
  handleEbookPromoImpressionNumbers(params: any): void {
    this.ebookPromoItemEvent.next(params);
  }

  /**
   * @param params any
   */
  handleWebcastPromoImpressionNumbers(params: any): void {
    this.webcastPromoItemEvent.next(params);
  }

  /**
   * @param params any
   */
  handleCoursePromoImpressionNumbers(params: any): void {
    this.coursePromoItemEvent.next(params);
  }

  /**
   * @param params any
   */
  handleVtwPromoImpressionNumbers(params: any): void {
    this.vtwPromoItemEvent.next(params);
  }

  /**
   * @param params any
   */
  handleEditOttLeadsImpressions(params: any): void {
    this.editOttLeadsImpressionsEvent.next(params);
  }

  /**
   * @param params any
   */
  handleEditVtwLeadsImpressions(params: any): void {
    this.editVtwLeadsImpressionsEvent.next(params);
  }

  /**
   * @param params any
   */
  handleNewCustomProduct(params: any): void {
    this.newCustomProductEvent.next(params);
  }

  /**
   * @param params any
   */
  handleEditCustomProduct(params: any): void {
    this.editCustomProductEvent.next(params);
  }

  /**
   * @param params any
   */
  handleEditCustomEblastLeads(params: any): void {
    this.editCustomEblastLeadsEvent.next(params);
  }

  /**
   * Opens the Edit Item component popup, passing in relevant data.
   * When the popup is closed, if "keepChanges" is true,
   * this means the user clicked the "Change" button.
   * If this service was initiated from a "legend" or from a 
   * Custom Product or Media Mention, we are dealing with an array of items, so we
   * overwrite the appropriate index in the itemArray 
   * with the edited item.
   * If not a Custom Product, Media Mention, and not from the legend, we overwrite 
   * the original item with the edited item.
   * 
   * @param any item
   * @param string type
   * @param string key
   * @param any[] itemArray
   * @param boolean fromLegend
   * @param number index
   * @return void
   */
  editItem(item: any, type: string, key: string, itemArray?: any[], fromLegend?: boolean, index?: number) {

    this.dialog.closeAll();

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      item: item,
      type: type,
      key: key
    };

    const dialogRef = this.dialog.open(EditItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result.keepChanges) {
        if (fromLegend || (type === 'customProduct') || (type === 'mediaMention') || key === 'promo') {

          // If new item, add to itemArray, updatePage count
          if (item.object_id == -1) {
            itemArray.unshift(item);
            this.updateTotalPages();
          }

          const indexToOverWrite = itemArray.findIndex(x => x.object_id === item.object_id);

          itemArray[indexToOverWrite] = result.newItem;


          if (type === 'customProduct') {

            let productInfo = {
              leads: result.newItem.leads || 0,
              impressions: result.newItem.impressions || 0
            };

            if (item.object_id == -1) {
              this.handleNewCustomProduct(productInfo);
              this.goTo('custom-product-0');
            } else {
              this.handleEditCustomProduct(productInfo);
            }
          }

          if (type === 'mediaMention') {

            let mediaMention = {
              impressions: result.newItem.custom_fields.impressions || 0,
              id: result.newItem.object_id || 0
            };
            
            // If new media mention
            if (item.object_id == -1) {
              this.handleNewMediaMention(mediaMention);
            } else {
              this.handleEditMediaMention(mediaMention);
            }
          }

          if (key === 'promo') {
            const promoInfo = {
              preEditCircNumbers: result.newItem.preEditCircNumbers || 0,
              afterEditCircNumbers: result.newItem.afterEditCircNumbers || 0,
              index: index
            };

            if (type === 'ebook') this.handleEbookPromoImpressionNumbers(promoInfo);
            if (type === 'webcast') this.handleWebcastPromoImpressionNumbers(promoInfo);
            if (type === 'course') this.handleCoursePromoImpressionNumbers(promoInfo);
            if (type === 'vtw') this.handleVtwPromoImpressionNumbers(promoInfo);
          }

        } else {

          // Set item key for everything except OTT Clicks & Impressions
          if (key !== 'ottClicksImpressions' && key !== 'vtwClicksImpressions') {
            item[key] = result.newItem[key];
          }

          if (type === 'custom_eblast' && key === 'leads') {
            this.handleEditCustomEblastLeads(result.newItem.leads);
          }

          if (key === 'ottClicksImpressions') {

            const info = {
              personalization_impressions: result.newItem.editable_object.custom_fields.personalization_impressions || 0,
              personalization_clicks: result.newItem.editable_object.custom_fields.personalization_clicks || 0
            };

            item.editable_object.custom_fields.personalization_impressions = result.newItem.editable_object.custom_fields.personalization_impressions;

            item.editable_object.custom_fields.personalization_clicks = result.newItem.editable_object.custom_fields.personalization_clicks;

            this.handleEditOttLeadsImpressions(info);
          }

          if (key === 'vtwClicksImpressions') {

            const info = {
              personalization_impressions: result.newItem.editable_object.custom_fields.personalization_impressions || 0,
              personalization_clicks: result.newItem.editable_object.custom_fields.personalization_clicks || 0,
              alert_impressions: result.newItem.editable_object.custom_fields.alert_impressions || 0,
              alert_clicks: result.newItem.editable_object.custom_fields.alert_clicks || 0
            };

            item.editable_object.custom_fields.personalization_impressions = result.newItem.editable_object.custom_fields.personalization_impressions;

            item.editable_object.custom_fields.personalization_clicks = result.newItem.editable_object.custom_fields.personalization_clicks;

            item.editable_object.custom_fields.alert_impressions = result.newItem.editable_object.custom_fields.alert_impressions;

            item.editable_object.custom_fields.alert_clicks = result.newItem.editable_object.custom_fields.alert_clicks;

            this.handleEditVtwLeadsImpressions(info);
          }

        } 
      }
    });
  }

  /**
   * Scrolls to given html element
   */
  /* istanbul ignore next */ 
  goTo(id: string) {
    setTimeout(function(){
      const element = document.getElementById(id);
      if (!element) return;
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 100); 
  }

  /**
   * Calls EventEmitService updateTotalPages() 
   */
  updateTotalPages() {
    this.reportPreviewService.updateTotalPages();
  }
}