import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit, OnDestroy {

  @Input() courseData: any;
  @Input() dateRange: any;
  @Input() purchaseLog: any;
  @Input() overallData: any;

  hidePageBucket: any;
  hideBubbleBucket: any;

  overAllPerformanceVerbiage: any = {verbiage: ''};

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private reportPreviewService: ReportPreviewService, private editItemService: EditItemService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;

    this._subscriptions.add(editItemService.coursePromoItemEventHook$.subscribe(
      (promoInfo) => {
        this.handlePromoItemImpressionsDiff(promoInfo);
      })
    );
  }

  ngOnInit() {
    if (this.courseData.total_leads == 0) {
      this.reportPreviewService.getIdsAndHidePages('edu-');
    }

    this.overAllPerformanceVerbiage.verbiage = "OVERALL PERFORMANCE" + (this.courseData.courses.length > 1 ? (" IN " + this.courseData.courses.length + " COURSES") : "");
  }

  /**
   * Handles impression diff incrementation
   * @param promoInfo
   * @return void
   */
  handlePromoItemImpressionsDiff(promoInfo) {

    const impressionsDiff = promoInfo.afterEditCircNumbers - promoInfo.preEditCircNumbers;

    this.overallData.total_impressions += impressionsDiff;
    this.purchaseLog.courses.total_impressions += impressionsDiff;
    this.courseData.total_print_impressions += impressionsDiff;
    this.courseData.total_impressions += impressionsDiff;
    this.courseData.courses[promoInfo.index].print_impressions += impressionsDiff;
    this.courseData.courses[promoInfo.index].impressions += impressionsDiff;

    this.reportPreviewService.handleHideUnhide("edu-", this.overallData.total_impressions, this.courseData.total_impressions);
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event: MouseEvent) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    this.editItemService.editItem(item, type, key);
  }

  /**
   * Creates new promo item object and calls Edit Item service editItem()
   * @param any[] itemArray
   * @param string type
   * @param number objectId
   * @param number index
   * @return void
   */
  addPromoItem(itemArray: any[], type: string, objectId: number, index: number) {
    let promoItem = this.reportPreviewService.createNewPromoObject(type, objectId);

    this.editItemService.editItem(promoItem, type, 'promo', itemArray, false, index);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
