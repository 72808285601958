// src/app/services/advertiser-division/advertiser-division.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class AdvertiserDivisionService {
  constructor(private api: ApiService) {}

  public getOne(id): Observable<any> {
    return this.api.get<Response>("/api/advertiser-divisions/" + id);
  }

  public getFull(id): Observable<any> {
    return this.api.get<Response>("/api/advertiser-divisions/" + id + "/full");
  }

  public getOneWithReports(id): Observable<any> {
    return this.api.get<Response>("/api/advertiser-divisions/" + id + "/with-reports");
  }

  public getCrmCompanies(id): Observable<any> {
    return this.api.get<Response>("/api/advertiser-divisions/" + id + "/crm-companies");
  }

  public removeCrmCompany(adDivId, crmId): Observable<any> {
    return this.api.delete<Response>("/api/advertiser-divisions/" + adDivId + "/crm-companies/" + crmId);
  }

  public removeSalesRep(adDivId, repId): Observable<any> {
    return this.api.delete<Response>("/api/advertiser-divisions/" + adDivId + "/salesrep/" + repId);
  }

  public addCrmCompany(adDivId, payload): Observable<any> {
    return this.api.post<Response>("/api/advertiser-divisions/" + adDivId + "/crm-companies", payload);
  }

  public addSalesRep(adDivId, payload): Observable<any> {
    return this.api.post<Response>("/api/advertiser-divisions/" + adDivId + "/salesrep", payload);
  }

  public getAll(): Observable<any> {
    return this.api.get<Response>("/api/advertiser-divisions");
  }

  public create(payload): Observable<any> {
    return this.api.post<Response>("/api/advertiser-divisions", payload, true);
  }

  public update(id, payload, logoChanged?: boolean): Observable<any> {
    return this.api.post<Response>("/api/advertiser-divisions/" + id, payload, logoChanged);
  }

  public delete(id): Observable<any> {
    return this.api.delete<Response>("/api/advertiser-divisions/" + id);
  }

  


}