// src/app/services/brand/brand.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class BrandService {
  constructor(private api: ApiService) {}

  public getOne(id): Observable<any> {
    return this.api.get<Response>("/api/brands/" + id);
  }

  public getAll(): Observable<any> {
    return this.api.get<Response>("/api/brands");
  }

  public delete(id): Observable<any> {
    return this.api.delete<Response>("/api/brands/" + id);
  }

  public create(payload): Observable<any> {
    console.log("Posting the following in brand.service.ts:");
    console.log(payload);

    return this.api.post<Response>("/api/brands", payload, true);
  }

  public update(id, payload, logoChanged): Observable<any> {
    return this.api.post<Response>("/api/brands/" + id, payload, logoChanged);
  }



}