/**
 * Model for Brand
 */
export class Brand {

  constructor(
      id: number,
      name: string,
      identifier: string,
      deleted: boolean,
      logo?: string,
      tempImage?: string,
      form?: any
  ) {
      this.id = id;
      this.name = name;
      this.identifier = identifier;
      this.deleted = deleted;
      if (logo) this.logo = logo;
      if (tempImage) this.tempImage = tempImage;
      if (form) this.form = form;
  }

  id: number = null;
  name: string = '';
  identifier: string = '';
  deleted: boolean;
  logo: string = '';
  tempImage: string = '';
  form: any = '';
}