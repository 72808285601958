import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { BrandService } from "src/app/services/brand/brand.service";
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationService } from "src/app/services/validation/validation.service";
import { Brand } from 'src/app/models/brand.model';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-brand-main',
  templateUrl: './brand-main.component.html',
  styleUrls: ['./brand-main.component.scss']
})
export class BrandMainComponent implements OnInit, OnDestroy {

  brands: Brand[] = [];
  panelOpenState: any = {};
  newBrandInProgress: boolean = false;
  displayedColumns: string[] = ['name', 'editAction', 'deleteAction'];

  private _subscriptions = new Subscription();

  @ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

  constructor(private brandService: BrandService, private router: Router, private builder: FormBuilder) { }

  ngOnInit() {
    this.getBrands();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  addNewBrand() {
    this.router.navigate(["/brands/new"]);
  }

  editBrand(id) {
    this.router.navigate(["/brands/" + id + "/edit"]);
  }

  goHome() {
    this.router.navigate(["/home"]);
  }

  getBrands() {
    this.newBrandInProgress = false;

    this._subscriptions.add(
      this.brandService.getAll().subscribe(response => {
        if (response.success) {
          this.brands = this.setupForms(response.data);
        }
      })
    );
  }

  addNew() {
    this.closeAllPanels();
    const that = this;

    this.newBrandInProgress = true;
    const newBrand = new Brand(-1,'','',false);
    newBrand.form = this.buildBrandForm(newBrand);
    this.brands.unshift(newBrand);

    setTimeout(function(){
      that.viewPanels.first.open();
    }, 0); 

  }

  delete(id, name) {
    event.stopPropagation();

    const firstConfirm = confirm('Are you sure you want to delete brand ' + name + '?');

    if (firstConfirm) {

      const secondConfirm = confirm('Are you SUPER DUPER SURE you want to delete brand ' + name + '?');

      if (secondConfirm) {

        this._subscriptions.add(
          this.brandService.delete(id).subscribe(response => {
            if (response.success) this.getBrands();
          })
        );
      }
    }
  }

  closeAllPanels() {
    this.viewPanels.forEach(p => p.close());
  }

  cancel() {
    this.closeAllPanels();

    this.newBrandInProgress = false;
    this.brands.shift();
  }

  onFileChange(event, brand) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => brand.tempImage = reader.result;

      reader.readAsDataURL(file);

      brand.form.patchValue({
        logo: file
      });
    }

  }

  resetBrand(i, brand) {
    this.panelOpenState[i] = false;

    brand.tempImage = '';
    brand.form.get("name").setValue(brand.name);
    brand.form.get("logo").setValue(brand.logo);
    brand.form.get("identifier").setValue(brand.identifier);
    (<HTMLInputElement>document.getElementById("brandLogo")).value = '';
  }

  save(brand: Brand) {


    const nameChanged = brand.name !== brand.form.get("name").value;
    const identifierChanged = brand.identifier !== brand.form.get("identifier").value;
    const logoChanged = typeof brand.form.get("logo").value === 'object';

    if (!brand.form.status) return;
    if (!nameChanged && !identifierChanged && !logoChanged) return;
    if (brand.id === 0 && (!nameChanged || !identifierChanged || !logoChanged)) return;

    let payload;

    // If the logo changed, we need to send the logo file as Form Data
    if (logoChanged) {
      payload = new FormData();
      payload.append('file', brand.form.get("logo").value);
      payload.append('name', brand.form.get("name").value);
      payload.append('identifier', brand.form.get("identifier").value);
    } else {
      payload = {
        id: brand.id,
        name: brand.form.get("name").value,
        identifier: brand.form.get("identifier").value
      };
    }

    // New
    if (brand.id === -1) {
      this._subscriptions.add(
        this.brandService.create(payload).subscribe(response => {
          if (response.success) this.getBrands();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.brandService.update(brand.id, payload, logoChanged).subscribe(response => {
          if (response.success) this.getBrands();
        })
      );
    }
  }

  setupForms(brands: Brand[]) {
    for (let brand of brands) {
      brand.form = this.buildBrandForm(brand);
    }

    return brands;
  }

  buildBrandForm(brand: Brand) {
    const brandForm = {
        name: [
          (brand.name),//.trim(),
          [
            Validators.required
          ]
        ],
        logo: [
          (brand.logo),//.trim(),
          [
            Validators.required
          ]
        ],
        identifier: [
          (brand.identifier),//.trim(),
          [
            Validators.required
          ]
        ]

    };

    return this.builder.group(brandForm);

  }

}
