import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable({
  providedIn: "root"
})
export class ReportService {
  constructor(private api: ApiService) {}

  public create(payload): Observable<any> {
    return this.api.post<Response>("/api/reports", payload);
  }

  public createReportForAllAdvertisers(payload): Observable<any> {
    return this.api.post<Response>("/api/reports/all-advertisers", payload);
  }

  public createSeoReport(): Observable<any> {
    return this.api.get<Response>("/api/reports/create-seo-report");
  }

  public getSeoReport(): Observable<any> {
    return this.api.get<Response>("/api/reports/get-seo-report");
  }
  public update(id, payload): Observable<any> {
  	return this.api.put<Response>("/api/reports/advertiser-division/" + id, payload);
  }

  public delete(id): Observable<any> {
    return this.api.delete<Response>("/api/reports/advertiser-division/" + id);
  }

  public getDetailedReport(id): Observable<any> {
    return this.api.get<Response>("/api/reports/advertiser-division/" + id);
  }

  public getPdfStatus(id): Observable<any> {
    return this.api.get<Response>("/api/pdfstatus/" + id);
  }

  public getAllAdvertiserDivisions(): Observable<any> {
    return this.api.get<Response>("/api/advertiser-divisions");
  }

  public getBrands(): Observable<any> {
    return this.api.get<Response>("/api/brands");
  }

  public getPrintAdTypes(): Observable<any> {
    return this.api.get<Response>("/api/printadtype");
  }

  public createNewPromo(payload): Observable<any> {
    return this.api.post<Response>("/api/advertiserprintad", payload);
  }

  public deletePromo(id): Observable<any> {
    return this.api.delete<Response>("/api/advertiserprintad/" + id);
  }

  public createPDF(payload): Observable<any> {
    return this.api.post<Response>("/api/reports/createpdf", payload);
  }

  public updateAdvertiserPrintAd(id, payload): Observable<any> {
    return this.api.put<Response>("/api/advertiserprintad/" + id, payload);
  }

  public updateCorrectObject(type, id, key, payload): Observable<any> {

    if (key === 'vtwClicksImpressions') {
      return this.api.put<Response>("/api/advertisercourse/vtw/" + id, payload);
    }

    if (type === 'advertiser_own_the_topic') {
      return this.api.put<Response>("/api/advertiser_own_the_topic/" + id, payload);
    }
    
    if (type === 'advertiser_print_ad') {
      return this.api.put<Response>("/api/advertiserprintad/" + id, payload);
    }

    if (key === 'promo') {
      return this.api.put<Response>("/api/advertiserprintad/promo/" + id, payload);
    }

    if (type === 'advertiser_newsletter_ad') {
      return this.api.put<Response>("/api/advertisernewsletterad/" + id, payload);
    }

    if (type === 'advertiser_newsletter' || type === 'custom_eblast') {
      return this.api.put<Response>("/api/advertisernewsletter/" + id, payload);
    }

    if (type === 'advertiser_webcast') {
      return this.api.put<Response>("/api/advertiserwebcast/" + id, payload);
    }

    if (type === 'advertiser_ebook') {
      return this.api.put<Response>("/api/advertiserebook/" + id, payload);
    }

    if (type === 'advertiser_course') {
      return this.api.put<Response>("/api/advertisercourse/" + id, payload);
    }

    if (type === 'customProduct') {
      return this.api.put<Response>("/api/advertisercustomproduct/" + id, payload);
    }

    if (type === 'mediaMention') {
      return this.api.put<Response>("/api/mediamention/" + id, payload);
    }
  }

  public getCorrectObject(type, id, key?): Observable<any> {

    if (key === 'vtwClicksImpressions') {
      return this.api.get<Response>("/api/advertisercourse/vtw/" + id);
    }

    if (type === 'advertiser_own_the_topic') {
      return this.api.get<Response>("/api/advertiser_own_the_topic/" + id);
    }

    if (type === 'advertiser_print_ad') {
      return this.api.get<Response>("/api/advertiserprintad/" + id);
    }

    if (key === 'promo') {
      return this.api.get<Response>("/api/advertiserprintad/promo/" + id);
    }

    if (type === 'advertiser_newsletter_ad') {
      return this.api.get<Response>("/api/advertisernewsletterad/" + id);
    }

    if (type === 'advertiser_newsletter' || type === 'custom_eblast') {
      return this.api.get<Response>("/api/advertisernewsletter/" + id);
    }

    if (type === 'advertiser_webcast') {
      return this.api.get<Response>("/api/advertiserwebcast/" + id);
    }

    if (type === 'advertiser_ebook') {
      return this.api.get<Response>("/api/advertiserebook/" + id);
    }

    if (type === 'advertiser_course') {
      return this.api.get<Response>("/api/advertisercourse/" + id);
    }

    if (type === 'customProduct') {
      return this.api.get<Response>("/api/advertisercustomproduct/" + id);
    }

    if (type === 'mediaMention') {
      return this.api.get<Response>("/api/mediamention/" + id);
    }
  }

  public getPrintAdCircNumbers(id) {
    return this.api.get<Response>("/api/advertiserprintad/circulation/" + id);
  }

  public getPdfPreview(payload): Observable<any> {
    return this.api.post<Response>("/api/reports/pdfpreview", payload);
  }

  public createCustomProduct(payload): Observable<any> {
    return this.api.post<Response>("/api/advertisercustomproduct", payload);
  }

  public createMediaMention(payload): Observable<any> {
    return this.api.post<Response>("/api/mediamention", payload);
  }

  public markDeleted(id): Observable<any> {
    return this.api.delete<Response>("/api/advertisercustomproduct/" + id);
  }

  public deleteMediaMention(id): Observable<any> {
    return this.api.delete<Response>("/api/mediamention/" + id);
  }

  /* Benchmarking related functions */
  public createBenchmarkSpreadsheet(payload): Observable<any> {
    return this.api.post<Response>("/api/reports/get-benchmark", payload);
  }
  public getBenchmarkTopics(): Observable<any> {
    return this.api.get<Response>("/api/reports/get-benchmark-topics");
  }
  public buildExampleBenchmarkTable() {
    return this.api.get<Response>("/api/reports/build-example-data");
  }

}