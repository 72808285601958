import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AdvertiserDivision } from 'src/app/models/advertiser-division.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-advertiser-division-new-edit',
  templateUrl: './advertiser-division-new-edit.component.html',
  styleUrls: ['./advertiser-division-new-edit.component.scss']
})
export class AdvertiserDivisionNewEditComponent implements OnInit, OnDestroy {

  isNewAdvertiserDivision: boolean = false;
  advertiserDivision: AdvertiserDivision;
  removedLogo: boolean = false;
  loading: boolean = false;
  private _subscriptions = new Subscription();
  @ViewChild('customInput', {static: false}) customInput: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private builder: FormBuilder, @Inject(AdvertiserDivisionService) private adDivService: AdvertiserDivisionService) { 
      this.isNewAdvertiserDivision = !this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.isNewAdvertiserDivision ? this.createNew() : this.getAdvertiserDivision();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Goes to advertiser division list page
   */
  goToAdvertiserDivisions() {
    this.router.navigate(["/advertiser-divisions"]);
  }

  /**
   * Creates new empty advertiser division model
   */
  createNew() {
    const newAdvertiserDivision = new AdvertiserDivision(-1, '', false);
    newAdvertiserDivision.form = this.buildAdvertiserForm(newAdvertiserDivision);

    this.advertiserDivision = newAdvertiserDivision;
  }

  /**
   * Gets advertiser division
   */
  getAdvertiserDivision() {
    this._subscriptions.add(
      this.adDivService.getOne(this.activatedRoute.snapshot.params.id).subscribe(response => {
        if (response.success && response.data) {
          const advertiserDivision = response.data[0];
          advertiserDivision.form = this.buildAdvertiserForm(response.data[0]);
          this.advertiserDivision = advertiserDivision;
        }
      })
    );
  }

  /**
   * Removes logo attribute from advertiserDivision
   */
  removeLogo() {
    if (this.advertiserDivision.tempImage) this.advertiserDivision.tempImage = '';
    this.advertiserDivision.form.patchValue({logo: null});
    this.customInput.nativeElement.value = "";
    delete this.advertiserDivision.logo;
    this.removedLogo = true;
  }

  /**
   * If should show Save button
   */
  showSaveButton() {
    if (this.advertiserDivision.form.status === 'INVALID') return false;

    if (this.isNewAdvertiserDivision) {
      if (this.advertiserDivision.form.get('name').value.trim()) {
        return true;
      } else {
        return false;
      }
    } else {

      if (this.removedLogo) return true;

      let nameChanged;

      if (this.advertiserDivision.name) {
        nameChanged = this.advertiserDivision.name !== this.advertiserDivision.form.get("name").value
      } else {
        nameChanged = this.advertiserDivision.form.get("name").value.trim();
      }

      let cityChanged;

      if (this.advertiserDivision.city) {
        cityChanged = this.advertiserDivision.city !== this.advertiserDivision.form.get("city").value;
      } else {
        cityChanged = this.advertiserDivision.form.get("city").value && this.advertiserDivision.form.get("city").value.trim();
      }

      let logoChanged;

      if (this.advertiserDivision.logo) {
        logoChanged = typeof this.advertiserDivision.form.get("logo").value === 'object';
      } else {
        logoChanged = this.advertiserDivision.form.get("logo").value;
      }

      return (!nameChanged && !cityChanged && !logoChanged) ? false : true;
    }
  }

  /**
   * Saves, or updates, advertiser division
   */
  save() {
    this.loading = true;
    const logoChanged = typeof this.advertiserDivision.form.get("logo").value === 'object';

    let payload;

    // If the logo changed, we need to send the logo file as Form Data
    if (logoChanged && !this.removedLogo) {
      payload = new FormData();
      payload.append('file', this.advertiserDivision.form.get("logo").value);
      payload.append('name', this.advertiserDivision.form.get("name").value);
      payload.append('city', this.advertiserDivision.form.get("city").value ? this.advertiserDivision.form.get("city").value : '');
    } else {
      payload = {
        id: this.advertiserDivision.id,
        name: this.advertiserDivision.form.get("name").value,
        city: this.advertiserDivision.form.get("city").value
      };
    }

    if (this.removedLogo) payload.removedLogo = true;

    // New
    if (this.advertiserDivision.id === -1) {
      this._subscriptions.add(
        this.adDivService.create(payload).subscribe(response => {
          if (response.success) this.goToAdvertiserDivisions();
        })
      );
    // Existing
    } else {
      this._subscriptions.add(
        this.adDivService.update(this.advertiserDivision.id, payload, logoChanged).subscribe(response => {
          if (response.success) this.goToAdvertiserDivisions();
        })
      );
    }
  }

  /**
   * Handles changing logo, sets temporary image that hasn't been persisted yet
   */
  onFileChange(event, advertiserDivision) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => advertiserDivision.tempImage = reader.result;

      reader.readAsDataURL(file);

      advertiserDivision.form.patchValue({
        logo: file
      });

      this.removedLogo = false;
    }
  }

  /**
   * Builds form
   */
  buildAdvertiserForm(advertiserDivision) {
    const form = {
        name: [
          (advertiserDivision.name),//.trim(),
          [
            Validators.required
          ]
        ],
        city: [
          (advertiserDivision.city),//.trim(),
          []
        ],
        logo: [
          (advertiserDivision.logo),//.trim(),
          []
        ]
    };

    return this.builder.group(form);
  }

}
