import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-custom-eblast',
  templateUrl: './custom-eblast.component.html',
  styleUrls: ['./custom-eblast.component.scss']
})
export class CustomEblastComponent implements OnInit, OnDestroy {

  @Input() eblastData: any;
  @Input() eblastBenchmarking: any;
  @Input() dateRange: any;
  @Input() overallData: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;
  hideRowBucket: any;

  leadsBeforeEditing: number = null;
  overAllPerformanceVerbiage: any = {verbiage: ''};

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;
    this.hideRowBucket = this.reportPreviewService.hideRowBucket;

    this._subscriptions.add(editItemService.editCustomEblastLeadsEventHook$.subscribe(
      (leads) => {
        this.handleEditCustomEblastLeads(leads);
      })
    );
  }

  ngOnInit() {
    this.checkHideAllPages();

    this.overAllPerformanceVerbiage.verbiage = "OVERALL PERFORMANCE" + (this.eblastData.eblasts.length > 1 ? (" IN " + this.eblastData.eblasts.length + " EMAILS") : "");
  }

  round( number ) {
    return Math.round( number );
  }

  /**
   * Increments total lead counts
   * @return void
   */
  handleEditCustomEblastLeads(leads) {
    const diff = leads - this.leadsBeforeEditing;

    this.eblastData.total_leads += diff;
    this.overallData.total_leads += diff;
    this.purchaseLog.custom_eblasts.total_leads += diff; 

    // Unhides all custom eblast pages, and then let's the html decide which 
    // pages or bubbles should be hidden at that point
    this.reportPreviewService.unHidePagesAndBubbles('custom-eblast-');
  }

  /**
   * If should gray-out all custom eblast pages on page load
   * @return void
   */
  checkHideAllPages() {
    if (this.eblastData.total_sent == 0) {
      this.reportPreviewService.getIdsAndHidePages('custom-eblast-');
    }
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  changeRowViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeRowViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @return void
   */
  editItem(item, type, key) {
    if (type === 'custom_eblast' && key === 'leads') {
      this.leadsBeforeEditing = (item.leads || item.leads === 0) ? item.leads : null;
    }

    this.editItemService.editItem(item, type, key);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

}
