import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { Subscription } from "rxjs";
import { ValidationService } from "src/app/services/validation/validation.service";
import { ParentErrorStateMatcher } from "src/app/services/validation/parentErrorStateMatcher";

@Component({
  selector: 'app-admin-new-edit',
  templateUrl: './admin-new-edit.component.html',
  styleUrls: ['./admin-new-edit.component.scss']
})
export class AdminNewEditComponent implements OnInit, OnDestroy {

    isNewAdmin: boolean = false;
    passwordMatchError: boolean = false;
    admin: User;
    loggedInUserId: string;
    private _subscriptions = new Subscription();

    // cross field validation of password-confirmPassword
    parentErrorStateMatcher = new ParentErrorStateMatcher();

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private builder: FormBuilder, @Inject(UserService) private userService: UserService) { 
        this.isNewAdmin = !this.activatedRoute.snapshot.params.id;
    }

    ngOnInit() {
      this.loggedInUserId = localStorage.getItem('userId');
      this.isNewAdmin ? this.createNew() : this.getUser();
    }

    /**
     * Unsubscribes to subscription
     */
    ngOnDestroy(): void {
      this._subscriptions.unsubscribe();
    }

    /**
     * Goes to admin list page
     */
    goToUsers() {
      this.router.navigate(["/users"]);
    }

    /**
     * Creates new empty admin model
     */
    createNew() {
      const admin = new User(-1, '', '');
      admin.form = this.buildAdminForm(admin);

      this.admin = admin;
    }

    /**
     * Gets admin
     */
    getUser() {
      this._subscriptions.add(
        this.userService.getOneAdmin(this.activatedRoute.snapshot.params.id).subscribe(response => {
          if (response.success && response.data) {

            const admin = response.data[0];
            admin.form = this.buildAdminForm(response.data[0]);
            this.admin = admin;
          }
        })
      );
    }

    /**
     * If should show the Save button
     */
    showSaveButton() {

      // console.log('this.admin.form.get("passwordGroup.password").value... ', this.admin.form.get("passwordGroup.password"));

      // console.log('this.admin.form.get("passwordGroup.confirmPassword").value... ', this.admin.form.get("passwordGroup.confirmPassword"));


      if (this.admin.form.status === 'INVALID') return false;

      if (this.isNewAdmin) {
        if (this.admin.form.get("email").value &&
           this.admin.form.get("passwordGroup.password").value) {
          return true;
        } else {
          return false;
        }
      } else {
        const emailChanged = this.admin.email !== this.admin.form.get("email").value;
        const passwordChanged = this.admin.form.get("passwordGroup.password").value;

        if (emailChanged || passwordChanged) {
          return true;
        } else {
          return false;
        }
      }
    }

    saveAdmin(admin: any) {

      const emailChanged = admin.email !== admin.form.get("email").value;
      const passwordChanged = admin.password !== admin.form.get("passwordGroup.password").value;

      if (!admin.form.status) return;
      if (!emailChanged && !passwordChanged) return;
      if (admin.id === 0 && (!emailChanged || !passwordChanged)) return;

      const editingSelf = this.loggedInUserId == admin.id;
      if (editingSelf) {
         const confirmSave = confirm('Updating your own info will log you out. This is normal. Just log back in afterwards.');

         if (!confirmSave) return;
      }
      
      let payload = {
          id: admin.id,
          email: '',
          password: ''
        };

      if (admin.form.get("email").value) payload.email = admin.form.get("email").value;
      if (admin.form.get("passwordGroup.password").value) payload.password = admin.form.get("passwordGroup.password").value;

      // New
      if (admin.id === -1) {
        this._subscriptions.add(
          this.userService.createAdmin(payload).subscribe(response => {
            if (response.success) this.goToUsers();
          })
        );
      // Existing
      } else {
        this._subscriptions.add(
          this.userService.updateAdmin(admin.id, payload).subscribe(response => {
            if (response.success) {
              this.handleEditOwnCredentials(admin.id);
              this.goToUsers();
            }
          })
        );
      }
    }

    // TODO come back to password validation
    letsSee(thingy1, thingy2) {
      if (thingy1 !== thingy2) {
        // this.passwordMatchError = true;
        return true;
      } else {
        // this.passwordMatchError = false;
        return false;
      }

      // return thingy1 !== thingy2;
    }

    // If user just edited their own credentials, log them out
    // We do this because to update the token requires both the user email
    // and the password, and we allow updating just the email, or just the password
    handleEditOwnCredentials(userId) {
      if (this.loggedInUserId != userId) return;

        localStorage.clear();
        this.router.navigate(["/login"]);
     }

    buildAdminForm(admin: any): FormGroup {
      const form = {
          email: [
            (admin.email),//.trim(),
            [
              Validators.required,
              Validators.pattern(
                ValidationService.getEmailRegex()
              )
            ]
          ],
        // make password group
        passwordGroup: this.builder.group(
          {
            password: [
              admin.password,
              [
                Validators.pattern(ValidationService.getPasswordRegex())
              ]
            ],
            confirmPassword: [admin.password]
          },
          {
            /**
             * !NOTE
             *  passing undefined, because we want to show the error message as soon as
             *  admin starts changing in the password/confirmPassword input-fields
             */
            validators: ValidationService.matchPassword(undefined)
          }
        )

      };

      return this.builder.group(form);

    }

}
