import { Injectable } from "@angular/core";

/**
 * Returns actual reference to global window object
 *
 * Separate function needed to appease AOT compiler.
 */
function getWindow(): Window {
  return window;
}

/**
 * Returns actual reference to global document object
 *
 * Separate function needed to appease AOT compiler.
 */
function getDocument(): Document {
  return document;
}

/**
 * Returns actual reference to global navigator object
 *
 * Separate function needed to appease AOT compiler.
 */
function getNavigator(): Navigator {
  return navigator;
}

/**
 * Provides reference to global window object
 *
 * Used to facilitate mocking window during unit tests
 */
@Injectable({
  providedIn: "root"
})
export class WindowProviderService {

  /**
   * Gets reference to global window object
   */
  nativeWindow(): Window {
    return getWindow();
  }

  /**
   * Gets reference to global document object
   */
  nativeDocument(): Document {
    return getDocument();
  }
  
  /**
   * Gets reference to global navigator object
   */
  nativeNavigator(): Navigator {
    return getNavigator();
  }
  
  /**
   * Detects what browser the user is using
   * 
   * @return string  The name of the browser and version number the user is using
   */
  detectBrowser(): string {
  	let ua = this.nativeNavigator().userAgent;
    let tem, M = ua.match( /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i ) || []; 

		if( /trident/i.test( M[ 1 ] ) ){
		  tem = /\brv[ :]+(\d+)/g.exec( ua ) || []; 
		        
		  return "IE ver" + tem[ 1 ];
		}   
    
    if( M[ 1 ] === 'Chrome' ){
		  tem = ua.match( /\bOPR|Edge\/(\d+)/ );
		  if( tem != null ) {
		    return "Opera ver" + ua.split( /\bOPR|Edge\/(\d+)/ )[ 2 ].replace( '/', '' ).split( '.' )[0];
		  }
	  }       
		    
		return M[ 1 ] + " ver" + M[ 2 ];
  }
}
