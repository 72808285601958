import { Component, OnDestroy, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportService } from 'src/app/services/report/report.service';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { Subscription } from "rxjs";
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { DeletePopupComponent } from 'src/app/pages/report/pdf/delete-popup/delete-popup.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-media-mention',
  templateUrl: './media-mention.component.html',
  styleUrls: ['./media-mention.component.scss']
})
export class MediaMentionComponent implements OnInit {

  @Input() mentionData: any;
  @Input() overallData: any;
  @Input() dateRange: any;
  @Input() advertiserDivisionId: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;
  mediaMention: any = {
    pages: ['media-mention-0']
  };

  dateHeaders: any = {};

  ulMaxHeight: number = 430; // Arbitrary max ul height, will probably change once Nicole styles

  impressionsBeforeEditing: number = null;

  brands: any = {
    42: "Consulting-Specifying Engineer",
    43: "Control Engineering",
    44: "Plant Engineering",
    45: "Oil & Gas Engineering"
  };

  monthsLong: string[] = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private dialog: MatDialog, private reportService: ReportService, private editItemService: EditItemService, private reportPreviewService: ReportPreviewService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;

    this._subscriptions.add(editItemService.newMediaMentionEventHook$.subscribe(
      (mentionInfo) => {
        this.handleNewMediaMention(mentionInfo);
      })
    );

    this._subscriptions.add(editItemService.editMediaMentionEventHook$.subscribe(
      (mentionInfo) => {
        this.handleEditMediaMention(mentionInfo);
      })
    );
  }

  /**
   * On init, if media mentions have already been "purchased", set up the pages
   * @return void
   */
  ngOnInit(): void {
    // This escape clause for unit testing purposes
    if (!this.advertiserDivisionId) return;

    if (this.mentionData.purchased) this.setUpPages();
  }

  /**
   * Fast scrolls to the media-cover, increments the Summary page's total impressions,
   * unhides the Summary page if it was hidden (since we now have positive impressions),
   * and calls resetPages()
   * @param any mentionInfo
   * @return void
   */
  handleNewMediaMention(mentionInfo: any) {
    // This escape clause for unit testing purposes
    if (!this.advertiserDivisionId) return;

    this.fastScroll('media-cover');

    if (mentionInfo.impressions) {
      this.overallData.total_impressions += mentionInfo.impressions;
      delete this.reportPreviewService.hidePageBucket['summary-overall'];
    }
    this.resetPages(mentionInfo.id);
  }

  /**
   * Fast scrolls to the media-cover, gets the diff between this media mention's 
   * impressions before opening the popup, and the number of impressions after closing
   * the popup, and increments the Summary page's total impressions.
   * If Summary page's total impressions are greater than 0, unhides Summary page 
   * if it was hidden, otherwise hides the Summary page if total impressions are now 0.
   * Calls resetPages()
   * @param any mentionInfo
   * @return void
   */
  handleEditMediaMention(mentionInfo: any) {
    // This escape clause for unit testing purposes
    if (!this.advertiserDivisionId) return;

    this.fastScroll('media-cover');

    const diff = mentionInfo.impressions - this.impressionsBeforeEditing;

    this.overallData.total_impressions += diff;

    if (this.overallData.total_impressions > 0) {
      delete this.reportPreviewService.hidePageBucket['summary-overall'];
    } else {
       this.reportPreviewService.hidePageBucket['summary-overall'] = true;
    }

    this.resetPages(mentionInfo.id);
  }

  /**
   * Resets the dateHeaders and mediaMention objects.
   * If we still have media mentions, sets purchased flags to true,
   * otherwise to false.
   * Calls setUpPages() if there are media mentions
   * @param number idToRemember
   * @return void
   */
  resetPages(idToRemember?: number) {
    this.dateHeaders = {};

    this.mediaMention = {
      pages: ['media-mention-0']
    };

    if (this.mentionData.mentions.length) {
      this.mentionData.purchased = true;
      this.purchaseLog.media_mentions.purchased = true;
      this.setUpPages(idToRemember);
    } else {
      this.mentionData.purchased = false;
      this.purchaseLog.media_mentions.purchased = false;
    }
  }

  /**
   * Sorts media mentions by date, oldest to newest
   * @return void
   */
  sortMediaMentions() {
    this.mentionData.mentions.sort((obj1, obj2) => {
        let first = new Date(obj1.custom_fields.date);
        let second = new Date(obj2.custom_fields.date);


        if (first > second) {
            return 1;
        }

        if (first < second) {
            return -1;
        }

        return 0;
    });
  }

  /**
   * Gives each media mention a dateHeader property of null.
   * If there is not already an entry in the dateHeaders object for this
   * mention's month-year, adds an entry to dateHeaders, and sets the mention's
   * dateHeader property to the UI preferred date string.
   * Example: we only want one "February 2021" dateHeader for potentially multiple 
   * February 2021 media mentions
   * @param any mention
   * @return void
   */
  setDateHeader(mention: any) {
    mention.custom_fields.dateHeader = null;

    const fullDate = new Date(mention.custom_fields.date);
    const month = fullDate.getUTCMonth();
    const year = fullDate.getUTCFullYear();

    if (!this.dateHeaders[month + "-" + year]) {
      this.dateHeaders[month + "-" + year] = true;
      mention.custom_fields.dateHeader = this.monthsLong[month].toUpperCase() + " " + year;
    }
  }

  /**
   * Main page creation/layout function for media mentions.
   * Edge case: will not work if a single media mention is longer than the ulMaxHeight.
   * @param number idToRemember
   * @return void
   */
  setUpPages(idToRemember?: any) {

    const that = this;

    let pageIndex = 0; // Start at the first page

    this.mediaMention[this.mediaMention.pages[pageIndex]] = [];

    this.sortMediaMentions();

    setTimeout(function() { 

      // Iterate over all media mentions plus one additional iteration, since we
      // can't measure the media-mention-container height until after the mention's been 
      // put on the dom
      for (let i = 0, len = that.mentionData.mentions.length + 1; i < len; i++) {

        let pageToRemember = '';

        setTimeout(function() { 

          if (that.ulOverMaxHeight(pageIndex)) {

            // If we've exceeded the max height threshold with the last added entry
            // from the previous iteration, remove this entry from that page's array
            let lastMention = (that.mediaMention[that.mediaMention.pages[pageIndex]].splice(-1, 1))[0];

            // Increment page number
            pageIndex++;
            // Add a new page string (example: media-mention-1)  to mediaMention['pages']
            that.mediaMention['pages'].push('media-mention-' + pageIndex);
            // Create and place that last overflow entry in this new page's mention array
            that.mediaMention[that.mediaMention.pages[pageIndex]] = [lastMention];

            // If this last overflow mention correpsonds to the one that was
            // just created or edited, keep track of this page so we can scroll to it.
            // This would overwrite the former pageToRemember as it's now on a new page
            if (lastMention.object_id == idToRemember) {
              pageToRemember = 'media-mention-' + pageIndex;
            }
          } 

          if (that.mentionData.mentions[i]) {
            // Set conditional date header on this mention
            that.setDateHeader(that.mentionData.mentions[i]);

            // Push current mention to this page's mention array
            that.mediaMention[that.mediaMention.pages[pageIndex]].push(that.mentionData.mentions[i]);

            // If this iteration's media mention corresponds to the one that was
            // just created or edited, keep track of this page so we can scroll to it
            if (that.mentionData.mentions[i].object_id == idToRemember) {
              pageToRemember = 'media-mention-' + pageIndex;
            }
          }

          if (pageToRemember) that.slowScroll(pageToRemember);
          
        }, 100);

      }

    }, 100);
  }

  /**
   * There can be multiple media-mention-containers on multiple pages.
   * Find the height of this particular media-mention-container and
   * return a boolean value if over the max height
   * @param Number pageIndex
   * @return boolean
   */
  ulOverMaxHeight(pageIndex) {
    let height = $("#media-mention-container-" + pageIndex).height();

    return height > this.ulMaxHeight;
  }

  /**
   * Creates and returns dateString
   * @param any
   * @return string
   */
  getDateString(initialDate) {
    const startDate = new Date(initialDate);
    const day = startDate.getUTCDate();
    const month = startDate.getUTCMonth();
    const year = startDate.getUTCFullYear();
    
    return this.monthsLong[month] + " " + day + ", " + year;
  }

  /**
   * Prepends protocol if url does not have one
   * @param string url
   * @return string url
   */
  addProtocol(url) {
    if (!url.startsWith('http')) {
      return 'https://' + url;
    }

    return url;
  }

  /**
   * Opens delete popup, passing in the media mention title (report_label).
   * After popup closes, if should delete, scroll to top-most media mention page,
   * and call markDeleted()
   * @param any item
   * @param number index
   * @return void
   */
  openDeletePopup(item: any, index: number) {
    this.dialog.closeAll();

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      itemName: item.report_label
    };

    const dialogRef = this.dialog.open(DeletePopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.shouldDelete) {
        // The mention to be deleted has not actually been deleted yet.
        // If length === 1, that means there will no longer be any media mentions.
        // If there are still media mentions left, scroll to top-most media mention page
        if (this.mentionData.mentions.length !== 1) {
          this.fastScroll('media-cover');
          this.slowScroll('media-mention-0');
        }
        this.markDeleted(item.object_id);
      }
    });
  }

  /**
   * Calls report service deleteMediaMention() (deletes in backend) and removes this
   * media mention from the frontend media mention array
   * @param number id
   * @return void
   */
  markDeleted(id) {

    this._subscriptions.add(
      this.reportService.deleteMediaMention(id).subscribe(response => {

        this.mentionData.mentions.forEach((mention, index) => {
          if (mention.object_id === id) {
            if (mention.custom_fields.impressions) {
              // Subtract this mention's impressions from Summary page total impressions
              this.overallData.total_impressions -= mention.custom_fields.impressions;
              // Hide Summary page if total impressions are now 0
              if (this.overallData.total_impressions <= 0) {
                this.reportPreviewService.hidePageBucket['summary-overall'] = true;
              }
            }
            // Remove from frontend mentionData.mentions array
            this.mentionData.mentions.splice(index, 1);
          }
        });

        this.resetPages();
      })
    );
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @param any[] itemArray
   * @return void
   */
  editItem(item, type, key, itemArray) {
    // If editing (i.e. not adding new media mention)
    if (item.object_id != -1) {
      this.impressionsBeforeEditing = item.custom_fields.impressions || 0;
    }
    this.editItemService.editItem(item, type, key, itemArray);
  }

  /**
  * Scrolls to given html element slowly
  */
  /* istanbul ignore next */ 
  slowScroll(id: string) {
    setTimeout(function(){
      const element = document.getElementById(id);
      if (!element) return;
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 0); 
  }

  /**
  * Scrolls to given html element quickly
  */
  /* istanbul ignore next */ 
  fastScroll(id: string) {
      const element = document.getElementById(id);
      if (!element) return;
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({top: y});
  }

  /**
   * Unsubscribes to subscriptions
   */
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
