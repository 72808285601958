import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import { Subscription } from "rxjs";
import { ApiService } from 'src/app/utility/api.service';
import { AdvertiserService } from 'src/app/services/advertiser/advertiser.service';
import { Advertiser } from 'src/app/models/advertiser.model';
import { FormGroup, Validators, FormControl, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-advertiser-main',
  templateUrl: './advertiser-main.component.html',
  styleUrls: ['./advertiser-main.component.scss']
})
export class AdvertiserMainComponent implements OnInit, OnDestroy {

	advertisers: Advertiser[] = [];
	panelOpenState: any = {};
	newAdvertiserInProgress: boolean = false;
	private _subscriptions = new Subscription();

	@ViewChildren(MatExpansionPanel) viewPanels: QueryList<MatExpansionPanel>;

  constructor(private api: ApiService, private adService: AdvertiserService, private builder: FormBuilder) { }

  ngOnInit() {
  	this.fetchAdvertisers();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  setupAdvertiser(advertisers: Advertiser[]) {
    for (let advertiser of advertisers) {
      advertiser.form = this.buildAdvertiserForm(advertiser);
    }

    return advertisers;
  }

  fetchAdvertisers() {

  	this._subscriptions.add(
  	  this.adService.getAdvertisers().subscribe(response => {
  	    this.advertisers = this.setupAdvertiser(response.data);
  	    console.log('this.advertisers... ', this.advertisers);
  	  })
  	);

  }

  closeAllPanels() {
  	this.viewPanels.forEach(p => p.close());
  }

  save(advertiser: any) {
    if (!advertiser.form.status) return;

    const formData: FormData = new FormData();
    formData.append('file', advertiser.form.get("logo").value, advertiser.form.get("name").value);

    // New
    if (advertiser.id === 0) {
      this._subscriptions.add(
        this.adService.createAdvertiser(formData).subscribe(response => {
          if (response.success) this.fetchAdvertisers();
        })
      );
    // Existing
    } else {
      // this._subscriptions.add(
      //   this.adService.updateAdmin(payload).subscribe(response => {
      //     if (response.success) {
      //       this.handleEditOwnCredentials(advertiser.id);
      //       this.fetchUsers();
      //     }
      //   })
      // );
    }
  }



  addNew() {
  	this.closeAllPanels();
  	const that = this;

		this.newAdvertiserInProgress = true;
    const newAdvertiser = new Advertiser(0, '', '', false, []);
    newAdvertiser.form = this.buildAdvertiserForm(newAdvertiser);
		this.advertisers.unshift(newAdvertiser);

  	setTimeout(function(){
  		that.viewPanels.first.open();
  	}, 0); 

  }

  onFileChange(event, advertiser) {
  	if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = e => advertiser.tempImage = reader.result;

        reader.readAsDataURL(file);

        advertiser.form.patchValue({
        	logo: file
        });
    }

  }


  buildAdvertiserForm(advertiser: Advertiser) {
    const form = {
        name: [
          (advertiser.name),//.trim(),
          [
            Validators.required
          ]
        ],
        logo: [
          (advertiser.logo),//.trim(),
          [
            Validators.required
          ]
        ]

    };

    return this.builder.group(form);

  }

}
