import { EbookInstance } from 'src/app/models/ebook-instance.model';

/**
 * Model for EbookInstancePerformance
 */
export class EbookInstancePerformance {

  constructor(
      id: number,
      startDate: string,
      endDate: string,
      issueVisits: number,
      pageViews: number,
      clicks: number,
      downloads: number,
      secondsSpent: number,
      registrants: number,
      ebookInstance: EbookInstance,
      deleted: boolean,
      form?: any
  ) {
      this.id = id;
      this.startDate = startDate;
      this.endDate = endDate;
      this.issueVisits = issueVisits;
      this.pageViews = pageViews;
      this.clicks = clicks;
      this.downloads = downloads;
      this.secondsSpent = secondsSpent;
      this.registrants = registrants;
      this.ebookInstance = ebookInstance;
      this.deleted = deleted;
      if (form) this.form = form;
  }

  id: number = null;
  startDate: string = '';
  endDate: string = '';
  issueVisits: number = null;
  pageViews: number = null;
  clicks: number = null;
  downloads: number = null;
  secondsSpent: number = null;
  registrants: number = null;
  ebookInstance: EbookInstance;
  deleted: boolean;
  form: any = '';
}