import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EditItemService } from 'src/app/pages/report/pdf/edit-item/services/edit-item.service';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-vtw',
  templateUrl: './vtw.component.html',
  styleUrls: ['./vtw.component.scss']
})
export class VtwComponent implements OnInit, OnDestroy {

  @Input() vtwData: any;
  @Input() overallData: any;
  @Input() dateRange: any;
  @Input() purchaseLog: any;

  hidePageBucket: any;
  hideBubbleBucket: any;

  personalizationImpressionsPreEdit: number = 0;
  personalizationClicksPreEdit: number = 0;
  alertImpressionsPreEdit: number = 0;
  alertClicksPreEdit: number = 0;

  lastIndex: number;
  lastKey: string;

  overAllPerformanceVerbiage: any = {verbiage: ''};

  /**
   * Subscription object
   */
  private _subscriptions = new Subscription();

  constructor(private reportPreviewService: ReportPreviewService, private editItemService: EditItemService) { 
    this.hidePageBucket = this.reportPreviewService.hidePageBucket;
    this.hideBubbleBucket = this.reportPreviewService.hideBubbleBucket;

    this._subscriptions.add(editItemService.editVtwLeadsImpressionsEventHook$.subscribe(
      (info) => {
        this.handleEditVtwLeadsImpressions(info);
      })
    );

    this._subscriptions.add(editItemService.vtwPromoItemEventHook$.subscribe(
      (promoInfo) => {
        this.handlePromoItemImpressionsDiff(promoInfo);
      })
    );
  }

  ngOnInit() {
    if (this.vtwData.total_leads == 0) {
      this.reportPreviewService.getIdsAndHidePages('vtw-');
    }

    this.overAllPerformanceVerbiage.verbiage = "OVERALL PERFORMANCE" + (this.vtwData.courses.length > 1 ? (" IN " + this.vtwData.courses.length + " WEBCASTS") : "");
  }

  /**
   * Handles Promo impression diff incrementation
   * @param promoInfo
   * @return void
   */
  handlePromoItemImpressionsDiff(promoInfo) {

    const impressionsDiff = promoInfo.afterEditCircNumbers - promoInfo.preEditCircNumbers;

    this.overallData.total_impressions += impressionsDiff;
    this.purchaseLog.virtual_training_week.total_impressions += impressionsDiff;
    this.vtwData.total_print_impressions += impressionsDiff;
    this.vtwData.total_impressions += impressionsDiff;
    this.vtwData.courses[promoInfo.index].print_impressions += impressionsDiff;
    this.vtwData.courses[promoInfo.index].impressions += impressionsDiff;

    this.reportPreviewService.handleHideUnhide("vtw-", this.overallData.total_impressions, this.vtwData.total_impressions);
  }

  /**
   * Increments total click and total impression counts. 
   * Hides or unhides pages as a result of impression count change
   * @param any info
   * @return void
   */
  handleEditVtwLeadsImpressions(info) {

    const personalizationImpressionsDiff = info.personalization_impressions - this.personalizationImpressionsPreEdit;

    const personalizationClicksDiff = info.personalization_clicks - this.personalizationClicksPreEdit;

    const alertImpressionsDiff = info.alert_impressions - this.alertImpressionsPreEdit;

    const alertClicksDiff = info.alert_clicks - this.alertClicksPreEdit;

    this.vtwData.total_impressions += (personalizationImpressionsDiff + alertImpressionsDiff);

    this.vtwData.total_clicks += (personalizationClicksDiff + alertClicksDiff);

    this.overallData.total_impressions += (personalizationImpressionsDiff + alertImpressionsDiff);

    this.overallData.total_clicks += (personalizationClicksDiff + alertClicksDiff);

    this.purchaseLog.virtual_training_week.total_impressions += (personalizationImpressionsDiff + alertImpressionsDiff);

    this.vtwData.courses[this.lastIndex].impressions += (personalizationImpressionsDiff + alertImpressionsDiff);

    this.reportPreviewService.handleHideUnhide("vtw-", this.overallData.total_impressions, this.vtwData.total_impressions);
  }

  /**
   * If should show or "gray-out" page or bubble on page load
   * @param string type
   * @param string id
   * @param Number field
   * @return boolean
   */
  showItem(type: string, id: string, field: Number) {
    return this.reportPreviewService.showItem(type, id, field);
  }

  /**
   * "Hides" or "Shows" the bubble, by calling report service's changeBubbleViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changeBubbleViewStatus(event: MouseEvent) {
    this.reportPreviewService.changeBubbleViewStatus(event.srcElement);
  }

  /**
   * "Hides" or "Shows" the page, by calling report service's changePageViewStatus()
   * @param MouseEvent event
   * @return void
   */
  changePageViewStatus(event: MouseEvent) {
    this.reportPreviewService.changePageViewStatus(event.srcElement);
  }

  /**
   * Calls Edit Item service editItem()
   * @param any item
   * @param string type
   * @param string key
   * @param index Number
   * @return void
   */
  editItem(item, type, key, index?) {

    this.lastIndex = null;
    this.lastKey = null;

    if (key === 'vtwClicksImpressions') {

      // Keep track of personalization impressions and clicks pre-editing
      this.personalizationImpressionsPreEdit = item.editable_object.custom_fields.personalization_impressions || 0;
      this.personalizationClicksPreEdit = item.editable_object.custom_fields.personalization_clicks || 0;

      this.alertImpressionsPreEdit = item.editable_object.custom_fields.alert_impressions || 0;
      this.alertClicksPreEdit = item.editable_object.custom_fields.alert_clicks || 0;

      // Keep track of which course was last edited
      this.lastIndex = index;
      this.lastKey = key;
    }

    this.editItemService.editItem(item, type, key);
  }

  /**
   * Creates new promo item object and calls Edit Item service editItem()
   * @param any[] itemArray
   * @param string type
   * @param number objectId
   * @param number index
   * @return void
   */
  addPromoItem(itemArray: any[], type: string, objectId: number, index: number) {
    let promoItem = this.reportPreviewService.createNewPromoObject(type, objectId);

    this.editItemService.editItem(promoItem, type, 'promo', itemArray, false, index);
  }

  /**
   * Adds two decimal spots to the number and removes trailing zeros
   * @param number value
   * @return number value
   */
  formatPercent(value) {
    if (+value == 0) return 0;

    return +(value.toFixed(2));
  }

  /**
   * Unsubscribes to subscriptions
   */
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
