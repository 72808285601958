import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { ReportPreviewService } from "src/app/services/report/report-preview.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() articleId: string;
  @Input() totalPages: any;
  private _subscriptions = new Subscription();
  pageNumber: any;

  constructor(private reportPreviewService: ReportPreviewService) { 
    // Listens for the triggering of the totalPagesEventHook$.
    // Any action which adds or removes (or shows or hides) pages
    // trigger this hook. Once triggered, call getPageNumber() to update
    // current page number
  	this._subscriptions.add(this.reportPreviewService.totalPagesEventHook$.subscribe(() => 
  			this.getPageNumber()
  	));

  }

  /**
   * Calls getPageNumber()
   */
  ngOnInit() {
  	this.getPageNumber();
  }

  /**
   * Generates dynamic page number
   * @return void
   */
  getPageNumber() {
  	const that = this;

    // Every "page" in the report preview section is defined by
    // <article> tags. Each article tag has a unique id.
    // If we look for the index position of a particular <article> tag
    // among all <article> tags that don't have class "remove",
    // we get the page number
  	setTimeout(function(){
  	  that.pageNumber = $("#" + that.articleId).index('article:not(.remove)') + 1;
  	}, 0); 
  }

  /**
   * Remove subscriptions
   */
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
