import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrmCompany } from 'src/app/models/crm-company.model';
import { Subscription } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { CrmCompanyService } from 'src/app/services/crm-company/crm-company.service';
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crm-company-new',
  templateUrl: './crm-company-new.component.html',
  styleUrls: ['./crm-company-new.component.scss']
})
export class CrmCompanyNewComponent implements OnInit, OnDestroy {

  crmCompany: CrmCompany;
  advertiserDivisionId: number = null;
  advertiserDivisionName: string = '';
  form: any;
  crmCompanies: any;
  filteredCrmCompanies: any;
  displayedColumns: string[] = ['company', 'mailCity', 'id', 'selectAction'];
  private _subscriptions = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private crmCompanyService: CrmCompanyService, private advertiserDivisionService: AdvertiserDivisionService, private router: Router) { 
    this.advertiserDivisionId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    this.getAdvertiserDivision();
    this.getAvailableCrmCompanies();
  }

  /**
   * Unsubscribes to subscription
   */
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Gets available crm companies that have not been assigned
   * to an advertiser division
   * @return void
   */
  getAvailableCrmCompanies() {
    this._subscriptions.add(
      this.crmCompanyService.getAvailableCrmCompanies().subscribe(response => {
        if (response.success) this.crmCompanies = response.data;
      })
    );
  }

  /**
   * Gets advertiser division
   * @return void
   */
  getAdvertiserDivision() {
    this._subscriptions.add(
      this.advertiserDivisionService.getOne(this.advertiserDivisionId).subscribe(response => {
        if (response.success && response.data && response.data[0]) {
          this.advertiserDivisionName = response.data[0].name;
        }
      })
    );
  }

  /**
   * Event handler. Looks to see if user hit "enter" key
   * after having entered two characters. If so, calls filterList()
   * @param number keyCode pressed
   * @param string value of keypress
   * @return void
   */
  eventHandler(keyCode: number, value: string) {
    if (!value) return;

    if (keyCode == 13 && value.length === 2) {
      this.filterList(value, true);
    }
  }  

  /**
   * Filters available CRM companies based on user search term.
   * Normally does nothing until user has entered in at least
   * 3 characters (unless they've entered in 2 characters and hit "enter" key)
   * @param string user searchString
   * @param boolean if user pressed the "Enter" key
   * @return void
   */
  filterList(searchString: string, enterKeyPressed?: boolean) {
    if (!searchString.trim() ||
       (searchString.length < 3 && !enterKeyPressed)) {
      this.filteredCrmCompanies = [];
      return;
    }

    this.filteredCrmCompanies = this.crmCompanies.filter(
        crmCompany => crmCompany.company.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
  }

  /**
   * Adds crm company to this advertiser division
   * @param number crm company id
   * @return void
   */
  addCrmCompany(crmId: number) {

    const payload = {
      crmId: crmId
    };

    this._subscriptions.add(
      this.advertiserDivisionService.addCrmCompany(this.advertiserDivisionId, payload).subscribe(response => {
        if (response.success) this.goToCrmCompanies();
      })
    );
  }

  /**
   * Navigates to CRM Companies page for given advertiserDivision
   */
  goToCrmCompanies() {
    this.router.navigate(["/advertiser-divisions/" + this.advertiserDivisionId + "/crm-companies"]);
  }

}
