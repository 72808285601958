import { Component, OnInit, OnDestroy, QueryList, ViewChildren, Inject } from '@angular/core';
import { Subscription } from "rxjs";
import { AdvertiserDivisionService } from 'src/app/services/advertiser-division/advertiser-division.service';
import { AdvertiserDivision } from 'src/app/models/advertiser-division.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advertiser-division-main',
  templateUrl: './advertiser-division-main.component.html',
  styleUrls: ['./advertiser-division-main.component.scss']
})

export class AdvertiserDivisionMainComponent implements OnInit, OnDestroy {

  	advertiserDivisions: AdvertiserDivision[] = [];
    loading: boolean = false;
    search: any = '';
    displayedColumns: string[] = ['name', 'city', 'editAction', 'deleteAction', 'crmAction', 'salesRepAction'];
    private _subscriptions = new Subscription();

    constructor(@Inject(AdvertiserDivisionService) private adDivService: AdvertiserDivisionService, private router: Router) { }

    ngOnInit() {
    	this.getAdvertiserDivisions(false);
    }

    goHome() {
    	this.router.navigate(["/home"]);
    }

    ngOnDestroy(): void {
      this._subscriptions.unsubscribe();
    }

    goToCrmCompanies(id) {
      this.router.navigate(["/advertiser-divisions/" + id + "/crm-companies"]);
    }

    goToSalesReps(id) {
      this.router.navigate(["/advertiser-divisions/" + id + "/salesrep"]);
    }

    goToNewAdvertiserDivision() {
      this.router.navigate(["/advertiser-divisions/new"]);
    }

    delete(id, name) {
      const firstConfirm = confirm('Are you sure you want to delete the Advertiser Division ' + name + '?');

      if (firstConfirm) {

        const secondConfirm = confirm('Are you SUPER DUPER SURE you want to delete the Advertiser Division ' + name + '?');

        if (secondConfirm) {
          this.advertiserDivisions = [];

          this._subscriptions.add(
            this.adDivService.delete(id).subscribe(response => {
              if (response.success) this.getAdvertiserDivisions(true);
            })
          );
        }
      }
    }

    getAdvertiserDivisions(clearSearch) {
      this.loading = true;

      this._subscriptions.add(
        this.adDivService.getAll().subscribe(response => {
          this.advertiserDivisions = response.data;
          this.loading = false;
        })
      );
    }

    addNewAdvertiserDivision() {
      this.router.navigate(["/advertiser-divisions/new"]);
    }

    editAdvertiserDivision(id) {
      this.router.navigate(["/advertiser-divisions/" + id + "/edit"]);
    }

}
