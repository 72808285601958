import { Component, AfterViewInit, ElementRef } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

	showHeader: boolean = true;

	constructor(
	  private router: Router,
	  private elementRef: ElementRef
	) {
	  // We don't want header on login page 
	  this.router.events.subscribe((event: Event) => {
	  	if (event instanceof NavigationEnd) {
	  		this.showHeader = event.url === '/login' || event.url.includes('/download/') ? false : true;
        }
	  })

	}

	ngAfterViewInit(){
     this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#cdd4c9';
  }

  title = 'AIR Interface';
}
