/**
 * Model for Report
 */
export class Report {

  constructor(
      id: number,
      advertiserDivision_id: number,
      startDate: any,
      endDate: any,
      deleted: boolean,
      form?: any
  ) {
      this.id = id;
      this.advertiserDivision_id = advertiserDivision_id;
      this.startDate = startDate;
      this.endDate = endDate;
      this.deleted = deleted;
      if (form) this.form = form;
  }

  id: number = null;
  advertiserDivision_id: number;
  startDate: any;
  endDate: any;
  deleted: boolean;
  form: any = '';
}