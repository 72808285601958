// src/app/services/user/user.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/utility/api.service';
import { Response } from 'src/app/models/response.model';

@Injectable()
export class UserService {
  constructor(private api: ApiService) {}

  public createAdmin(payload): Observable<any> {
    return this.api.post<Response>("/api/users", payload);
  }

  public createSalesRep(payload): Observable<any> {
    return this.api.post<Response>("/api/salesreps", payload);
  }

  public deleteAdmin(id): Observable<any> {
    return this.api.delete<Response>("/api/users/" + id);
  }
  
  public deleteSalesRep(id): Observable<any> {
    return this.api.delete<Response>("/api/salesreps/" + id);
  }
  
  public updateAdmin(id, payload): Observable<any> {
    return this.api.put<Response>("/api/users/" + id, payload);
  }

  public updateSalesRep(id, payload): Observable<any> {
  	return this.api.put<Response>("/api/salesreps/" + id, payload);
  }

  public getAdmin(): Observable<any> {
    return this.api.get<Response>("/api/users");
  }

  public getSalesReps(): Observable<any> {
    return this.api.get<Response>("/api/salesreps");
  }

  public getOneAdmin(id): Observable<any> {
    return this.api.get<Response>("/api/users/" + id);
  }

  public getOneSalesRep(id): Observable<any> {
    return this.api.get<Response>("/api/salesreps/" + id);
  }

}